import { VersionHistoryComponent } from './../version-history/version-history.component'
import { SettingsComponent } from './../../modals/settings/settings.component'
import { TermsOfUseComponent } from './../terms-of-use/terms-of-use.component'
import { PrivacyPolicyComponent } from './../privacy-policy/privacy-policy.component'
import { faClock } from '@fortawesome/free-regular-svg-icons'
import { IUser } from 'tolaria-cloud-functions/src/_interfaces'
import { takeUntil } from 'rxjs/operators'
import { IPresence, PresenceService } from 'src/app/services/presence.service'
import { INotification, MessagingService } from 'src/app/services/messaging.service'
import { Router } from '@angular/router'
import { AuthService, EventService } from 'src/app/services'
import { Component, OnInit, ViewChild, ElementRef, Renderer2, OnDestroy, Input } from '@angular/core'
import {
  faCog, faSignOutAlt, faQuestionCircle, faQuestion, faInfo, faInfoCircle, faBell,
  faTable, faTh, faSearch, faTimesCircle, faComment, faCommentSlash, faUserPlus, faReceipt,
  faUsers, faUserSlash, faTrophy, faFilter, faHandSparkles, faGlobe, faFileSignature, faIdBadge, faHistory,
  faBars, faTimes, faCompress, faExpand,
} from '@fortawesome/free-solid-svg-icons'
import { merge, Observable, Subject } from 'rxjs'
import { NgbModal, NgbModalOptions, NgbOffcanvas } from '@ng-bootstrap/ng-bootstrap'
import { PurchasesComponent } from 'src/app/payment/purchases/purchases.component'

@Component({
  selector: 'app-top-bar',
  templateUrl: './top-bar.component.html',
  styleUrls: ['./top-bar.component.css']
})
export class TopBarComponent implements OnInit, OnDestroy {
  @ViewChild('searchInput') private searchInput: ElementRef
  @ViewChild('notificationList') public notificationList: ElementRef
  @ViewChild('notificationButton') public notificationButton: ElementRef

  @Input() user: IUser

  icons = {
    faUsers,
    faUserSlash,
    faCog,
    faSignOutAlt,
    faBell,
    faQuestion,
    faQuestionCircle,
    faTable,
    faTh,
    faSearch,
    faTimesCircle,
    faComment,
    faCommentSlash,
    faInfo,
    faInfoCircle,
    faUserPlus,
    faTrophy,
    faFilter,
    faHandSparkles,
    faGlobe,
    faClock,
    faFileSignature,
    faIdBadge,
    faHistory,
    faReceipt,
    faBars,
    faTimes,
    faExpand,
    faCompress,
  }

  isSearching = false
  searchString = ''

  public showFullscreen = false
  public showUserMenu = false
  public showDownToPlaySettings = false
  public messages: Array<INotification> = []
  public showMessageList = false
  public presence$: Observable<IPresence>
  private timer: any
  private componentWasDestroyed$ = new Subject<boolean>()

  constructor(
    private auth: AuthService,
    public router: Router,
    public es: EventService, // should be removed laters
    public messaging: MessagingService,
    private renderer: Renderer2,
    private presence: PresenceService,
    private modalService: NgbModal,
    private offcanvasService: NgbOffcanvas,
  ) {
  }

  ngOnInit(): void {
    if ('Notification' in window) {
      // console.log(`Browser supports Notifications, lets init Messaging`)
      this.initMessaging()
    }
    else {
      // console.log(`Browser does not supports Notifications, lets skip Messaging`)
    }
    this.initRenderer()
    this.presence$ = this.presence.getPresence(this.user.uid)

    addEventListener('fullscreenchange', (event) => {
      // document.fullscreenElement will point to the element that
      // is in fullscreen mode if there is one. If there isn't one,
      // the value of the property is null.
      if (document.fullscreenElement) {
        this.showFullscreen = true
      }
      else {
        this.showFullscreen = false
      }
    })

  }
  ngOnDestroy(): void {
    this.componentWasDestroyed$.next(true)
  }

  toggleFullscreen(): void {
    this.showFullscreen = !this.showFullscreen
    if (this.showFullscreen) {
      document.documentElement.requestFullscreen()
    }
    else {
      document.exitFullscreen()
    }
  }

  openMenu(content) {
    this.offcanvasService.open(content).result.then(
      (result) => {
        console.log(`Closed with: ${result}`)
      },
      (reason) => {
        console.log(`Dismissed ${reason}`)
      },
    );
  }

  openSettings(): void {
    const modalOptions: NgbModalOptions = {
      centered: false,
      animation: true,
      backdrop: false,
      keyboard: false,
      size: 'xl',
    }

    this.modalService.open(SettingsComponent, modalOptions)
  }

  showInfo(info: string): void {

    const modalOptions: NgbModalOptions = {
      centered: false,
      animation: true,
      backdrop: true,
      keyboard: true,
      size: 'lg',
    }

    switch (info) {
      case 'privacyPolicy':
        this.modalService.open(PrivacyPolicyComponent, modalOptions)
        break
      case 'termsOfUse':
        this.modalService.open(TermsOfUseComponent, modalOptions)
        break
      case 'versionHistory':
        this.modalService.open(VersionHistoryComponent, modalOptions)
        break
      case 'purchaseHistory':
        modalOptions.backdrop = 'static'
        this.modalService.open(PurchasesComponent, modalOptions)
        break
    }
  }

  private initMessaging() {
    this.messaging.getPermission()

    this.messaging.permissionGranted$
      .pipe(takeUntil(merge(this.componentWasDestroyed$, this.auth.isLoggedOut$)))
      .subscribe(() => {
        // console.log('notification access granted')
        this.messaging.receiveMessage()
      })
    this.messaging.currentMessage$
      .pipe(takeUntil(merge(this.componentWasDestroyed$, this.auth.isLoggedOut$)))
      .subscribe((message: INotification) => {
        if (message !== null) {
          if (message.notification.route) {
            const parsedRoute = message.notification.route.replace('https://tolaria.app', '')
            // check if user is in the present route and if not, add the notification
            if (parsedRoute !== this.router.url) {
              this.messages.unshift(message)
            }
          }
        }
      })
  }
  private initRenderer() {
    this.renderer.listen('document', 'click', (e: Event) => {
      const path = e.composedPath()
      // console.log(this.notificationButton.nativeElement)
      // console.log(path)
      if (this.notificationButton &&
        this.notificationButton.nativeElement &&
        path.indexOf(this.notificationButton.nativeElement) > -1) {
        // console.log('clicked inside message list')
      }
      else {
        // console.log('clicked outside message list')
        if (this.showMessageList) {
          // console.log('message list is open, clear all notifications as we can assume these are noticed')
          this.messages.filter(m => m.hasRead === false).forEach((message: INotification) => {
            message.hasRead = true
          })
        }
        this.showMessageList = false
      }
    })
  }
  getRoute(route): string {
    return route.split('/')[1]
  }
  showSearchBar(): boolean {
    switch (this.router.url.split('/')[1]) {
      case 'events':
        return true
      case 'event-lobby':
        return false
    }
  }
  notificationClick(index: number) {
    if (this.messages[index].notification.route) {
      const parsedRoute = this.messages[index].notification.route.replace('https://tolaria.app', '')
      this.messages[index].hasRead = true
      this.router.navigate([parsedRoute])
    }
    else {
      this.messages[index].hasRead = true
    }
  }
  get parsedRoute() {
    return this.router.url.split('/')[1]
  }
  get unreadNotifications() {
    return this.messages.filter(m => m.hasRead === false).length
  }
  userInitials(displayName) {
    const initials = displayName.match(/\b\w/g) || []
    return ((initials.shift() || '') + (initials.pop() || '')).toUpperCase()
  }
  showDownToPlayConfigPanel(state: boolean) {
    switch (state) {
      case true:
        clearTimeout(this.timer)
        this.timer = setTimeout(() => {
          this.showDownToPlaySettings = state
        }, 500)
        break

      case false:
        clearTimeout(this.timer)
        this.showDownToPlaySettings = state
        break
    }
  }

  signOut() {
    this.auth.signOut()
  }

  public get route(): string {
    return this.router.url.split('/')[1]
  }

}
