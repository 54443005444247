import { NgbModal, NgbModalOptions } from '@ng-bootstrap/ng-bootstrap'
import { HttpClient } from '@angular/common/http'
import { Component, OnInit, ViewChild, ElementRef, Input } from '@angular/core'
import { combineLatest, Observable, of } from 'rxjs'
import { map, catchError } from 'rxjs/operators'
import * as _ from 'lodash'
import { AngularFirestore } from '@angular/fire/compat/firestore'
import { DomSanitizer, SafeResourceUrl } from '@angular/platform-browser';


export enum RSSFeedType {
  blog = 'blog',
  pod = 'pod',
  vlog = 'vlog',
}
export interface IRSSFeed {
  name: string
  url: string
  type: RSSFeedType
  class: string
  logoUrl: string
  enabled: boolean
}
export interface IRSSFeedItem {
  feedMeta: IRSSFeed
  author: string
  categories: string[]
  content: string
  description: string
  enclosure: {
    thumb: string
  }
  guid: string
  link: string
  isYoutube?: boolean
  embedUrl?: SafeResourceUrl
  pubDate: string
  thumbnail: string
  title: string
}

@Component({
  selector: 'app-news-feed',
  templateUrl: './news-feed.component.html',
  styleUrls: ['./news-feed.component.css']
})
export class NewsFeedComponent implements OnInit {

  @ViewChild('readerModal') readerModal: ElementRef
  @Input() numFeeds: number
  @Input() showMoreButton: boolean = false
  @Input() fullpage: boolean = false

  constructor(
    private http: HttpClient,
    private afs: AngularFirestore,
    private modalService: NgbModal,
    private sanitizer: DomSanitizer
  ) { }


  ngOnInit() {
    this.fetchRSSFeed()
  }

  public reader: IRSSFeedItem
  public rssFeed$: Observable<IRSSFeedItem[]>

  public showReader(feed: IRSSFeedItem): void {
    this.reader = feed
    const modalOptions: NgbModalOptions = {
      centered: false,
      animation: true,
      backdrop: true,
      keyboard: true,
      size: 'lg',
      windowClass: ''
    }
    this.modalService.open(this.readerModal, modalOptions)
  }

  private extractVideoId(url: string): string {
    const regex = /(?:v=|\/)([0-9A-Za-z_-]{11})(?:[?&]|$)/;
    const match = url.match(regex);
    return match ? match[1] : null;
  }

  private async fetchRSSFeed() {

    const apiKey = 'dvfk1xfelwpshqpbwfzazgsijhsehmrjaljqc7t2'
    const apiUrl = 'https://api.rss2json.com/v1/api.json'

    this.afs.collection<IRSSFeed>('rssFeeds').get().pipe(
      map(snap => {
        const feeds: IRSSFeed[] = []
        snap.docs.forEach(doc => feeds.push(doc.data()))
        return feeds
      })
    ).toPromise().then(async (feedUris) => {

      const feeds: Observable<IRSSFeedItem[]>[] = []

      for await (const feed of feedUris) {
        const observable = this.http
          .get(apiUrl, {
            params: {
              rss_url: feed.url,
              count: 10
            }
          })
          .pipe(
            map((response: any) => {
              const res: IRSSFeedItem[] = []
              response.items.forEach((i: IRSSFeedItem) => {
                const item: IRSSFeedItem = i
                item.feedMeta = feed
                if (item.feedMeta.type === 'vlog' && item.feedMeta.url.includes('youtube')) {
                  const videoId = this.extractVideoId(item.link)
                  item.isYoutube = true
                  item.embedUrl = this.getSafeUrl(`https://www.youtube.com/embed/${videoId}`)
                }
                else {
                  item.isYoutube = false
                  item.embedUrl = null
                }
                res.push(item)
              })
              console.log('NewsFeedComponent:: feed added ->', res)
              return res
            }),
            catchError((err, caught) => {
              console.error('NewsFeedComponent:: unexpected error ->', caught, err)
              return of([])
            })
          )

        feeds.push(observable)
      }

      this.rssFeed$ = combineLatest(feeds).pipe(
        map(feeds => {
          let result: IRSSFeedItem[] = []
          feeds.forEach(feed => {
            result = _.concat(result, feed)
          })
          return result
        })
      )

      return null
    })

  }

  public getSafeUrl(videoUrl: string): SafeResourceUrl {
    return this.sanitizer.bypassSecurityTrustResourceUrl(videoUrl);
  }
  

}
