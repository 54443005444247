<div class="d-flex align-items-center gap-2 flex-wrap flex-md-nowrap flex-grow-1">

  <div class="flex-grow-1"></div>

  <div class="input-group flex-nowrap order-md-0 order-1">

    <input class="form-control form-control-sm rounded-2 rounded-md-0"
      type="text"
      [ngClass]="{ 'rounded-2': rounded }"
      [(ngModel)]="matchListing.filters.filterString"
      placeholder="Filter matches"
      [disabled]="!matchListing.filters.ready">

    <button class="btn btn-sm d-none d-lg-block"
      [ngClass]="{
        'btn-outline-secondary' : !matchListing.filters.showFilters,
        'btn-secondary' : matchListing.filters.showFilters
      }"
      [disabled]="!matchListing.filters.ready"
      (click)="matchListing.filters.showFilters = !matchListing.filters.showFilters">
      <fa-icon [icon]="icon.filter"></fa-icon>
    </button>

  </div>

  <div class="btn-group flex-grow-1 flex-md-grow-0">

    <button [routerLink]="['/matches', 'list']"
      type="button"
      class="btn btn-sm d-flex align-items-center"
      [ngClass]="route === 'list' ? 'btn-secondary' : 'btn-outline-secondary'"
      ngbTooltip="List of all your matches"
      [openDelay]="250"
      container="body">
      <fa-icon [icon]="icon.list" [fixedWidth]="true"></fa-icon>
      <span class="d-xxl-block d-md-none d-sm-block ms-2">List</span>
    </button>

    <button [routerLink]="['/matches', 'statistics']"
      type="button"
      class="btn btn-sm d-flex align-items-center"
      [ngClass]="route === 'stats' ? 'btn-secondary' : 'btn-outline-secondary'"
      ngbTooltip="Have a look at your match statistics"
      [openDelay]="250"
      container="body">
      <fa-icon [icon]="icon.statistics" [fixedWidth]="true"></fa-icon>
      <span class="d-xxl-block d-md-none d-sm-block ms-2">Statistics</span>
    </button>

    <button [routerLink]="['/matches', 'calendar']"
      type="button"
      class="btn btn-sm d-flex align-items-center"
      [ngClass]="route === 'cal' ? 'btn-secondary' : 'btn-outline-secondary'"
      ngbTooltip="Your matches in a calendar view"
      [openDelay]="250"
      container="body">
      <fa-icon [icon]="icon.calendar" [fixedWidth]="true"></fa-icon>
      <span class="d-xxl-block d-md-none d-sm-block ms-2">Calendar</span>
    </button>

  </div>

</div>
