import { CommonModule } from '@angular/common';
import { Component, ElementRef, HostListener, Input, OnDestroy, OnInit } from '@angular/core';
import { NgbTooltipModule } from '@ng-bootstrap/ng-bootstrap';
import { BehaviorSubject, Subject, takeUntil } from 'rxjs';
import { EventGridItemComponent } from 'src/app/private/play/events/components/event-grid-item/event-grid-item.component';
import { EventListingService, IEventTemplate } from 'src/app/private/play/events/services/event-listing.service';

@Component({
  // eslint-disable-next-line @angular-eslint/component-selector
  selector: 'tolaria-tournament-viewer',
  templateUrl: './tolaria-tournament-viewer.component.html',
  styleUrls: ['./tolaria-tournament-viewer.component.css'],
  standalone: true,
  imports: [
    CommonModule,
    NgbTooltipModule,
    EventGridItemComponent,
  ]
})
export class TolariaTournamentViewerComponent implements OnInit, OnDestroy {
  @Input() set docid(id: string) {
    this.eventDocId = id
  }
  
  public eventDocId!: string

  public tournament$ = new BehaviorSubject<IEventTemplate>(null)
  public showAll: boolean = false
  public isFocused: boolean = false
  private destroyed$ = new Subject<boolean>()

  @HostListener('document:click', ['$event'])
  clickout(event: any) {
    this.isFocused = this.host.nativeElement.contains(event.target)
  }

  constructor(
    private readonly host: ElementRef<HTMLElement>,
    private readonly eventListing: EventListingService,
  ) {
    
  }

  ngOnInit() {
    this.eventListing.ready$.pipe(takeUntil(this.destroyed$)).subscribe(ready => {
      console.log('TolariaTournamentViewerComponent:: event listing emitted ready state -> ', ready)
      if (ready && this.tournament$.getValue() === null) {
        console.log('TolariaTournamentViewerComponent:: event listig ready, lets get the event')
        this.getTournament()
      }
    })
  }

  ngOnDestroy(): void {
    this.host.nativeElement.remove()
    this.destroyed$.next(true)
  }

  public destroy(): void {
    this.host.nativeElement.remove()
    this.destroyed$.next(true)
  }

  private async getTournament() {
    console.log('TolariaTournamentViewerComponent:: asking even listing for tournament -> ', this.eventDocId)
    const tourney = await this.eventListing.getEventById(this.eventDocId)
    console.log('TolariaTournamentViewerComponent:: got event', tourney)
    this.tournament$.next(tourney)
  }

  

}
