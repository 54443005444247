import { Injectable } from '@angular/core';
import { AngularFirestore } from '@angular/fire/compat/firestore';
import { BehaviorSubject, combineLatest, firstValueFrom, map, Observable, Subscription } from 'rxjs';
import { PlayerExtender } from 'src/app/private/play/tournament/services/helpers/player-extender.helper';
import { PlayerTieBreakers } from 'src/app/services/event/tiebreakers';
import { PlayerNameService } from 'src/app/services/players/player-name.service';
import { IEventDetails, IEventPlayerDetails, ILeagueDocument, ILeaguePlayer, ILeaguePlayerMeta, ILeaguePointInfo, ILeaugePlayerPoints, IMatchData, LeaguePointType } from 'tolaria-cloud-functions/src/_interfaces';

export interface ILeagueTemplate {
  document$: BehaviorSubject<ILeagueDocument>
  players$: BehaviorSubject<LeaguePlayer[]>
  events$: BehaviorSubject<LeagueEvent[]>
}

export interface LeagueEvent {
  eventDocId: string
  document: BehaviorSubject<IEventDetails>
  players: BehaviorSubject<IEventPlayerDetails[]>
  matches: BehaviorSubject<IMatchData[]>
}

export interface LeaguePlayer {
  eventPlayers: IEventPlayerDetails[]
}

export interface LeagueEventMeta {
  event: IEventDetails
  matches: IMatchData[]
  players: IEventPlayerDetails[]
}

export interface LeagueListingFilter {
  text: string
  showArchived: boolean
}

@Injectable({
  providedIn: 'root'
})
export class LeagueListingService {

  private _leagues = new Map<string, ILeagueTemplate>()
  public leagues$ = new BehaviorSubject<ILeagueDocument[]>(null)
  public leagueEvents$ = new BehaviorSubject<Map<string, IEventDetails>>(null)
  public leagueEventSubDocs$ = new BehaviorSubject<Map<string, LeagueEventMeta>>(null)
  public leagueEventMatches$ = new BehaviorSubject<Map<string, IMatchData[]>>(null)
  public leagueEventPlayers$ = new BehaviorSubject<Map<string, IEventPlayerDetails[]>>(null)
  private _leageEventDocSubs = new Map<string, Subscription>()
  private _leageEventMatchesDocSubs = new Map<string, Subscription>()
  private _leageEventPlayersDocSubs = new Map<string, Subscription>()
  private _leageEventSubDocsSubs = new Map<string, Subscription>()
  public ready$ = new BehaviorSubject<boolean>(false)
  public loadingItems: number[] = [0, 1, 2, 3, 4, 5, 6, 7, 8, 9]

  public filter: LeagueListingFilter = {
    text: '',
    showArchived: false,
  }

  constructor(
    private readonly firestore: AngularFirestore,
    private readonly playerNames: PlayerNameService,
  ) {
    this.playerNames.serviceReady$.subscribe(ready => {
      if (ready && this.ready$.getValue() !== true) {
        console.log('LeagueListingService:: player names ready, lets initialize service')
        this.initService()
      }
    })
  }

  private initService(): void {
    this.firestore
      .collection<ILeagueDocument>('leagues')
      .valueChanges()
      .subscribe((docs) => {
        console.log('LeagueListingService:: documents emitted a new value ->', docs)
        this.leagues$.next(docs)
        this.ready$.next(true)
        // for each league not created already, initialize observers
        // for (const doc of docs) {
        //   // this.initLeagueObservers(doc)
        //   this.initLeagueEventObservers(doc)
        // }
      })
  }

  public initLeagueEventObservers(doc: ILeagueDocument) {
    console.log(`LeagueListingService:: initializing tournament documents subscriptions for league ${doc.name}`)
    for (const eventDocId of doc.eventDocIds) {
      // get the doc subscription
      let subscription = this._leageEventDocSubs.get(eventDocId)
      // create new subscription and update league event document map subject
      if (subscription === undefined) {
        // get the document observable
        const docObserver = this.firestore.collection('events').doc<IEventDetails>(eventDocId).valueChanges()
        // create the subscription
        const docSub = docObserver.subscribe((eventDoc) => {
          const currentEventsMap = this.leagueEvents$.getValue()
          const updatedEventsMap = new Map(currentEventsMap)
          updatedEventsMap.set(eventDocId, eventDoc)
          this.leagueEvents$.next(updatedEventsMap)
          this.initLeagueEventSubDocObservers(eventDoc)
        })
        // add the subscription to the map of subscriptions
        this._leageEventDocSubs.set(eventDocId, docSub)
      }
      else {
        console.log('LeagueListingService:: subscriptions already existing, nothing to do here!')
      }
    }
  }

  private initLeagueEventSubDocObservers(eventDoc: IEventDetails) {
    console.log(`LeagueListingService:: initializing sub document subscriptions for tournament ${eventDoc.details.name}`)
    if (eventDoc.statusCode !== 8) {
      console.log('LeagueListingService:: tournament ongoing, ignore getting players and matched')
    }
    else {
      console.log('LeagueListingService:: tournament ended, lets get players and matches')
      // get the matches collection subscription
      let subDocSub = this._leageEventSubDocsSubs.get(eventDoc.docId)
      // create new subscription and update league event document map subject
      if (subDocSub === undefined) {
        // get the tournament matches collection observable
        const matchesColObserver = this.firestore.collection<IMatchData>('matches', ref => ref.where('eventDocId', '==', eventDoc.docId)).valueChanges()
        // get the tournament players collection observable
        const playersColObserver = this.firestore.collection('events').doc(eventDoc.docId).collection<IEventPlayerDetails>('players').valueChanges()

        // create the subscription
        const matchesColSub = combineLatest([matchesColObserver, playersColObserver]).subscribe(([matches, players]) => {
          if (matches.length > 0 && players.length > 0) {
            const currentEventSubDocsMap = this.leagueEventSubDocs$.getValue()
            const updatedEventSubDocsMap = new Map(currentEventSubDocsMap)
            let extendedPlayers = PlayerExtender.getList(players, matches, eventDoc)
            extendedPlayers = PlayerTieBreakers.compute(extendedPlayers)
            extendedPlayers = PlayerTieBreakers.sort(extendedPlayers)
            extendedPlayers = PlayerTieBreakers.rank(extendedPlayers, eventDoc.details.structure.isGroup)
            extendedPlayers = extendedPlayers.map(data => {
              data.eventDocId = eventDoc.docId
              data.eventName = eventDoc.details.name
              return data
            })
            const data: LeagueEventMeta = { event: eventDoc, matches, players: extendedPlayers }
            updatedEventSubDocsMap.set(eventDoc.docId, data)
            this.leagueEventSubDocs$.next(updatedEventSubDocsMap)
          }
        })
        // add the subscription to the map of subscriptions
        this._leageEventSubDocsSubs.set(eventDoc.docId, matchesColSub)
      }

      // // get the matches collection subscription
      // let matchesSubscription = this._leageEventMatchesDocSubs.get(eventDoc.docId)
      // // create new subscription and update league event document map subject
      // if (matchesSubscription === undefined) {
      //   // get the tournament match collection observable
      //   const matchesColObserver = this.firestore.collection<IMatchData>('matches', ref => ref.where('eventDocId', '==', eventDoc.docId)).valueChanges()
      //   // create the subscription
      //   const matchesColSub = matchesColObserver.subscribe((matches) => {
      //     const currentEventMatchesMap = this.leagueEventMatches$.getValue()
      //     const updatedEventMatchesMap = new Map(currentEventMatchesMap)
      //     updatedEventMatchesMap.set(eventDoc.docId, matches)
      //     this.leagueEventMatches$.next(updatedEventMatchesMap)
      //   })
      //   // add the subscription to the map of subscriptions
      //   this._leageEventMatchesDocSubs.set(eventDoc.docId, matchesColSub)
      // }

      // // get the matches collection subscription
      // let playersSubscription = this._leageEventPlayersDocSubs.get(eventDoc.docId)
      // // create new subscription and update league event document map subject
      // if (playersSubscription === undefined) {
      //   // get the tournament players collection observable
      //   const playersColObserver = this.firestore.collection('events').doc(eventDoc.docId).collection<IEventPlayerDetails>('players').valueChanges()
      //   // create the subscription
      //   const playersColSub = playersColObserver.subscribe((players) => {
      //     const currentEventPlayersMap = this.leagueEventPlayers$.getValue()
      //     const updatedEventPlayersMap = new Map(currentEventPlayersMap)
      //     const leaguePlayers = players.map(player => {
      //       player.eventDocId = eventDoc.docId
      //       return player
      //     })
      //     updatedEventPlayersMap.set(eventDoc.docId, leaguePlayers)
      //     this.leagueEventPlayers$.next(updatedEventPlayersMap)
      //   })
      //   // add the subscription to the map of subscriptions
      //   this._leageEventPlayersDocSubs.set(eventDoc.docId, playersColSub)
      // }
    }
  }

  public getLeague(leagueId: string): ILeagueDocument {
    if (leagueId === null) { return null }
    const leagues = this.leagues$.getValue()
    if (leagues === null) { return null }
    const league = leagues.find(i => i.docId === leagueId)
    return league ? league : null
  }

  public getLeagueEvents(leagueId: string): Observable<IEventDetails[]> {
    const league = this.getLeague(leagueId)
    return this.leagueEvents$.pipe(
      map((eventsMap) => {
        if (!eventsMap) {
          return []
        }
        return Array.from(eventsMap.entries())
          .filter(([event]) => league.eventDocIds.includes(event)) // Filter by eventDocIds
          .map(([, value]) => value) // Extract document
      })
    )
  }
  public getLeagueEventMatches(leagueId: string): Observable<IMatchData[]> {
    const league = this.getLeague(leagueId)
    return this.leagueEventMatches$.pipe(
      map((eventsMap) => {
        if (!eventsMap) {
          return []
        }
        return Array.from(eventsMap.entries())
          .filter(([event]) => league.eventDocIds.includes(event)) // Filter by eventDocIds
          .map(([, value]) => value).flat() // Extract documents as a single array
      })
    )
  }
  public getLeagueEventPlayers(leagueId: string): Observable<IEventPlayerDetails[]> {
    const league = this.getLeague(leagueId)
    return this.leagueEventPlayers$.pipe(
      map((eventsMap) => {
        if (!eventsMap) {
          return []
        }
        return Array.from(eventsMap.entries())
          .filter(([event]) => league.eventDocIds.includes(event)) // Filter by eventDocIds
          .map(([, value]) => value).flat() // Extract documents as a single array
      })
    )
  }
  public getLeagueEventSubDocs(leagueId: string): Observable<LeagueEventMeta[]> {
    const league = this.getLeague(leagueId)
    return this.leagueEventSubDocs$.pipe(
      map((eventsMap) => {
        if (!eventsMap) {
          return []
        }
        return Array.from(eventsMap.entries())
          .filter(([event]) => league.eventDocIds.includes(event)) // Filter by eventDocIds
          .map(([, value]) => value).flat() // Extract documents as a single array
      })
    )
  }

  public get translations() {
    return {
      [LeaguePointType.RANK]: {
        text: 'Ranking',
        tooltip: `Reward points according to their final standings`,
        explaination: `Received according to your ranking in an event`
      },
      [LeaguePointType.BRACKET_WIN]: {
        text: 'Bracket Win',
        tooltip: `Reward points for every match win of type bracket (playoffs)`,
        explaination: `Received for every match win in the playoffs of an event`
      },
      [LeaguePointType.MATCH_WIN]: {
        text: 'Match Win',
        tooltip: `Reward points for every match wins`,
        explaination: `Received for every match win during an event`
      },
      [LeaguePointType.GAME_WIN]: {
        text: 'Game Win',
        tooltip: `Reward points for every game wins`,
        explaination: `Received for every game win during an event`
      },
      [LeaguePointType.MATCH_FULFILMENT]: {
        text: 'Match Fulfilment',
        tooltip: `Reward points for finalizing all matches (used for group stages)`,
        explaination: `Received if you finish all your group matches in an event`
      },
      [LeaguePointType.CUSTOM]: {
        text: 'Custom Point',
        tooltip: `Reward points for custom achivements`,
        explaination: `Added manually by the tournament organizer`,
      }
    }
  }

  public getLeaguePlayerData(league: ILeagueDocument, leagueMeta: LeagueEventMeta[]): ILeaguePlayer[] {
    const playerDocIds = [...new Set(leagueMeta.map(i => i.players.map(p => p.playerDocId)).flat())]
    const players: ILeaguePlayer[] = playerDocIds
      .map(playerDocId => {
        const meta: ILeaguePlayerMeta = {
          playerDocId: playerDocId,
          events: leagueMeta.map(i => i.event).filter(i => i.playerDocIds.includes(playerDocId)).filter(i => i.statusCode === 8),
          matches: leagueMeta.map(i => i.matches.filter(i => i.playerDocIds.includes(playerDocId)).flat()).flat(),
          playerDocs: leagueMeta.map(i => i.players.filter(i => i.playerDocId === playerDocId)).flat(),
          league: league,
        }
        return meta
      })
      .map(player => this.mapPlayerData(player))
      .sort((a, b) => {
        const eqCheck = this.LeagueTiebreakers.equal(a, b);
        if (eqCheck === true) {
          return 0;
        }
        else {
          if (typeof eqCheck === 'number') return this.LeagueTiebreakers.diff(a, b, eqCheck);
          return 0;
        }
      })
    
    // add ranking
    let rank = 1;
    for (const [i, player] of players.entries()) {
      if (i === 0) player.leagueRank = rank
      else {
        if (this.LeagueTiebreakers.equal(players[i - 1], players[i]) === true) player.leagueRank = rank
        else rank++; player.leagueRank = rank
      }
    }

    return players
  }

  private LeagueTiebreakers = {
    equal: (a: ILeaguePlayer, b: ILeaguePlayer) => {
      if (a.leaguePoints === b.leaguePoints) {
        if (a.matchPoints === b.matchPoints) {
          if (a.gamePoints === b.gamePoints) return true;
          else return 3;
        } else return 2;
      } else return 1;
    },
    diff: (a: ILeaguePlayer, b: ILeaguePlayer, n: number) =>
      n === 1 ? b.leaguePoints - a.leaguePoints :
        n === 2 ? b.matchPoints - a.matchPoints : b.gamePoints - a.gamePoints
  }

  private mapPlayerData(player: ILeaguePlayerMeta): ILeaguePlayer {
    const points = this.awardLeaguePointsToPlayer(player.league, player)
    const mini = this.playerNames.getPlayerById(player.playerDocId)
    const leaguePlayer: ILeaguePlayer = {
      playerDocId: player.playerDocId,
      playerUid: player.playerDocs[0].playerUid,
      name: mini ? mini.name.display : player.playerDocs[0].name,
      wins: this.sumProperty(player.playerDocs, 'matchesWon'),
      losses: this.sumProperty(player.playerDocs, 'matchesLost'),
      draws: this.sumProperty(player.playerDocs, 'matchesDrawn'),
      matchPoints: this.sumProperty(player.playerDocs, 'matchPoints'),
      gamePoints: this.sumProperty(player.playerDocs, 'gamePoints'),
      leagueDocId: player.league.docId,
      leagueRank: 0,
      leaguePoints: points.points,
      leaguePointsInfo: points.pointsInfo,
      hasManualPoints: points.pointsInfo.filter(i => i.type === LeaguePointType.CUSTOM).length > 0,
    }
    return leaguePlayer
  }

  private sumProperty(arr: Array<any>, property: string) {
    let total = 0
    for (const item of arr) {
      total += item[property];
    }
    return total
  }
  private getRankSuffix(rank: number): string {
    const rankEnding = parseInt(rank.toString().substr(-1));
    if (rankEnding === 1) { return 'st' }
    else if (rankEnding === 2) { return 'nd' }
    else if (rankEnding === 3) { return 'rd' }
    else { return 'th' }
  }

  private awardLeaguePointsToPlayer(league: ILeagueDocument, playerMeta: ILeaguePlayerMeta): ILeaugePlayerPoints {
    // this function will be run for a single player at a time

    // check if there are any points to be rewarded at all, if none return an empty response
    if (league.pointStructure.length === 0) { return { points: 0, pointsInfo: [] } }

    // create the array to store all points awarded to the player
    const pointsInfo: ILeaguePointInfo[] = []

    // loop through the point structure to award points
    for (const point of league.pointStructure) {

      switch (point.type) {

        case LeaguePointType.RANK:
          for (const player of playerMeta.playerDocs) {
            const rank = player.rank === undefined ? 0 : player.rank;
            // check if the event has ended, else skip
            if (rank >= point.rankFrom && rank <= point.rankTo) {
              const pointInfo: ILeaguePointInfo = {
                point: point,
                points: point.points,
                comment: `Finnished @ ${player.rank}${this.getRankSuffix(player.rank)}`,
                type: LeaguePointType.RANK,
                eventDocId: player.eventDocId,
                eventName: player.eventName,
              }
              if (pointInfo.points > 0) (
                pointsInfo.push(pointInfo)
              )
            }
          }
          break

        case LeaguePointType.MATCH_WIN:
          for (const player of playerMeta.playerDocs) {
            const pointInfo: ILeaguePointInfo = {
              point: point,
              points: point.points * player.matchesWon,
              comment: `${player.matchesWon} matches won`,
              type: LeaguePointType.MATCH_WIN,
              eventDocId: player.eventDocId,
              eventName: player.eventName,
            }
            if (pointInfo.points > 0) (
              pointsInfo.push(pointInfo)
            )
          }
          break

        case LeaguePointType.GAME_WIN:
          for (const player of playerMeta.playerDocs) {
            const gameWins = player.gamePoints / 3 // divide the number of MatchPoints with 3 as this is the points per MatchWin
            const pointInfo: ILeaguePointInfo = {
              point: point,
              points: point.points * gameWins,
              comment: `${gameWins} games won`,
              type: LeaguePointType.GAME_WIN,
              eventDocId: player.eventDocId,
              eventName: player.eventName,
            }
            if (pointInfo.points > 0) (
              pointsInfo.push(pointInfo)
            )
          }
          break

        case LeaguePointType.BRACKET_WIN:
          for (const player of playerMeta.playerDocs) {
            // filter out all bracket matches from the event
            const bracketMatches = playerMeta.matches.filter(m => m.eventDocId === player.eventDocId && m.isType === 'bracket')
            // check if any matches to consider
            if (bracketMatches.length > 0) {
              let bracketWins = 0
              // loop through all matches and add wins if winner
              for (const match of bracketMatches) {
                const playerIs = match.player1.playerDocId === player.playerDocId ? 'player1' : 'player2'
                match[playerIs].isWinner ? bracketWins++ : null
              }
              // if any wins, create the point
              if (bracketWins > 0) {
                const pointInfo: ILeaguePointInfo = {
                  point: point,
                  points: point.points * bracketWins,
                  comment: `Won ${bracketWins} bracket matches`,
                  type: LeaguePointType.BRACKET_WIN,
                  eventDocId: player.eventDocId,
                  eventName: player.eventName,
                }
                if (pointInfo.points > 0) (
                  pointsInfo.push(pointInfo)
                )
              }
            }
          }
          break

        case LeaguePointType.MATCH_FULFILMENT:
          for (const player of playerMeta.playerDocs) {
            // filter out all bracket matches from the event
            const allMatchesPlayed = playerMeta.matches.filter(m => m.eventDocId === player.eventDocId && !m.isReported && m.isType === 'group').length === 0
            if (allMatchesPlayed) {
              const pointInfo: ILeaguePointInfo = {
                point: point,
                points: point.points,
                comment: 'Played all matches during the group stage',
                type: LeaguePointType.MATCH_FULFILMENT,
                eventDocId: player.eventDocId,
                eventName: player.eventName,
              }
              if (pointInfo.points > 0) (
                pointsInfo.push(pointInfo)
              ) 
            }
          }
          break

      }

    }

    for (const manualPoint of league.manualPoints.filter(p => p.playerDocId === playerMeta.playerDocId)) {
      const point: ILeaguePointInfo = {
        point: manualPoint,
        points: manualPoint.points,
        comment: manualPoint.comment,
        type: LeaguePointType.CUSTOM,
      }
      pointsInfo.push(point)
    }

    const points = this.sumProperty(pointsInfo, 'points');

    return {
      points,
      pointsInfo
    } as ILeaugePlayerPoints;

  }

}

interface LeaguePlayerMeta {
  playerDocId: string

}