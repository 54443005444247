import { CommonModule } from '@angular/common';
import { Component, Input } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { Router, RouterLink } from '@angular/router';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { faTh, faTable, faListUl, faFilter, faCalendar } from '@fortawesome/free-solid-svg-icons';
import { EventListingService, IEventFilter } from '../../services/event-listing.service';
import { PlayerNameService } from 'src/app/services/players/player-name.service';
import { CreateTournamentService } from '../../services/create-tournament.service';

@Component({
  selector: 'app-events-filter-bar',
  templateUrl: './events-filter-bar.component.html',
  styleUrls: ['./events-filter-bar.component.css'],
  standalone: true,
  imports: [
    CommonModule,
    FormsModule,
    RouterLink,
    FontAwesomeModule,
  ]
})
export class EventsFilterBarComponent {
  @Input() rounded: boolean = false

  public grid = faTh
  public table = faTable
  public list = faListUl
  public filter = faFilter
  public calendar = faCalendar

  constructor(
    private readonly router: Router,
    public readonly listing: EventListingService,
    private readonly playerNames: PlayerNameService,
    private readonly createTournament: CreateTournamentService
  ) { }

  public get currentRoute(): 'grid' | 'table' | 'calendar' | 'list' {
    if (this.router.url.includes('grid')) { return 'grid' }
    if (this.router.url.includes('table')) { return 'table' }
    if (this.router.url.includes('calendar')) { return 'calendar' }
    if (this.router.url.includes('list')) { return 'list' }
  }

  public get showCreateButton(): boolean {
    return this.playerNames.currentPlayerIsOrganizer || this.playerNames.currentPlayerIsAdmin
  }

  public createNewTournament(): void {
    this.createTournament.openNewTournamentForm()
  }


}
