<div class="card border-secondary border-opacity-50 rounded min-width-100 min-height-100 max-height-100 overflow-hidden">
  <ng-container *ngIf="cardOfTheDay$ | async as doc; else loadingCard">
    <ng-container *ngIf="cardData$ | async as card; else loadingCard">

      <div class="card-body no-hover flex-grow-0">

        <div class="text-larger text-center pb-3 d-flex align-items-center">
          <button class="btn btn-sm btn-blank text-secondary text-center" (click)="loadYesterday()"
            [disabled]="!showYesterdayNav" [ngClass]="{ 'invisible' : !showYesterdayNav }">
            <fa-icon [icon]="icon.yesterday" [fixedWidth]="true" class="me-2"></fa-icon>
          </button>
          <div class="flex-grow-1 btn btn-blank p-0 text-larger" (click)="loadToday()">Card of the Day</div>
          <button class="btn btn-sm btn-blank text-secondary text-center" (click)="loadTomorrow()"
            [disabled]="!showTomorrowNav" [ngClass]="{ 'invisible' : !showTomorrowNav }">
            <fa-icon [icon]="icon.tomorrow" [fixedWidth]="true" class="me-2"></fa-icon>
          </button>
        </div>

        <div class="card-wrapper {{ card.set }}" [style.width.rem]="cardW" [style.height.rem]="cardH">
          <img [src]="card.image_uris.normal">
        </div>

      </div>

      <div class="card-body flex-grow-0 pt-0 border-bottom border-secondary border-opacity-50">

        <div class="d-flex align-items-center">
          <div class="btn btn-sm btn-blank text-center flex-grow-1" (click)="likeCard()">
            <fa-icon [icon]="icon.like" [fixedWidth]="true" class="me-2"></fa-icon> {{ doc.numLikes }}
          </div>
          <div class="btn btn-sm btn-blank text-center flex-grow-1" (click)="dislikeCard()">
            <fa-icon [icon]="icon.dislike" [fixedWidth]="true" class="me-2"></fa-icon> {{ doc.numDislikes }}
          </div>
          <div class="btn btn-sm btn-blank text-center flex-grow-1">
            <fa-icon [icon]="icon.comments" [fixedWidth]="true" class="me-2"></fa-icon> {{ doc.commentCount }}
          </div>
          <ng-container *ngIf="true">
            <div class="btn btn-sm btn-blank text-center flex-grow-1">
              <fa-icon [icon]="icon.decks" [fixedWidth]="true" class="me-2"></fa-icon> {{ deckCounter$ | async }}
            </div>
          </ng-container>
        </div>

      </div>

      <!-- MESSAGES -->
      <app-message-list [messageGroupDocId]="messageGroupDocId"></app-message-list>

      <!-- CHAT FORM -->
      <tolaria-message-form class="px-2"  [messageGroupDocId]="messageGroupDocId"></tolaria-message-form>

    </ng-container>
  </ng-container>

  <ng-template #loadingCard>
    <div class="card-body d-flex flex-column align-items-center loader-container">
      <div class="pb-3 text-larger text-center">Card of the Day</div>
      <div class="card-loader-wrapper d-flex flex-column align-items-center justify-content-center flex-grow-1">
        <div class="spinner-wrapper">
          <div class="lds-spinner">
            <div></div>
            <div></div>
            <div></div>
            <div></div>
            <div></div>
            <div></div>
            <div></div>
            <div></div>
            <div></div>
            <div></div>
            <div></div>
            <div></div>
          </div>
        </div>
        <div class="text-secondary">loading card...</div>
      </div>
    </div>
  </ng-template>

</div>
