import { PlayerCountryComponent } from './../../components/players/player-country/player-country.component';
import { NgModule } from '@angular/core';
import { PlayerAvatarComponent } from 'src/app/components/players/player-avatar/player-avatar.component';
import { PlayerDisplayNameComponent } from 'src/app/components';
import { UserStatusComponent } from 'src/app/components/user-status/user-status.component';
import { QuickMessageComponent } from 'src/app/private/social/messages/components/quick-message/quick-message.component';
import { PlayerBadgesCarouselComponent } from 'src/app/components/players/player-badges-carousel/player-badges-carousel.component';
import { ClubAvatarComponent } from 'src/app/components/clubs/club-avatar/club-avatar.component';
import { ImageCropperUploaderComponent } from 'src/app/components/app-structure/image-cropper-uploader/image-cropper-uploader.component';
import { TimestampClockComponent } from 'src/app/components/app-structure/timestamp-clock/timestamp-clock.component';
import { SharedModule } from '../shared.module';
import { EventPairingsViewComponent } from 'src/app/components/events/event-lobby/event-main-view/event-pairings-view/event-pairings-view.component';
import { PairingsViewPipe } from 'src/app/pipes/pairings-view.pipe';
import { MatchPipe } from 'src/app/pipes';
import { EventDocumentFormComponent } from 'src/app/private/play/tournament/components/event-document-form/event-document-form.component';
import { PlayerRowComponent } from 'src/app/private/play/tournament/pages/tournament-management/tournament-management-players/tournament-management-players-attending/player-row/player-row.component';
import { IssueRefundRegistrationFeeComponent } from 'src/app/private/play/tournament/pages/tournament-management/tournament-management-players/tournament-management-players-attending/player-row/issue-refund-registration-fee/issue-refund-registration-fee.component';
import { NewEventComponent } from 'src/app/components/events/new-event/new-event.component';
import { EventInfoComponent } from 'src/app/components/events/event-lobby/event-details/event-info/event-info.component';
import { ReportSlipComponent } from 'src/app/private/play/tournament/components/report-slip/report-slip.component';
import { EventNameComponent } from 'src/app/components/app-structure/event-name/event-name.component';
import { RouterModule } from '@angular/router';
import { PlayerDetailsComponent } from 'src/app/components/players/player-details/player-details.component';



@NgModule({
  declarations: [
    EventInfoComponent,
    EventNameComponent,
    NewEventComponent,
    PlayerCountryComponent,
    PlayerRowComponent,
    IssueRefundRegistrationFeeComponent,
    EventDocumentFormComponent,
    EventPairingsViewComponent,
    PairingsViewPipe,
    MatchPipe,
    ReportSlipComponent,
  ],
  imports: [
    TimestampClockComponent,
    ImageCropperUploaderComponent,
    ClubAvatarComponent,
    UserStatusComponent,
    QuickMessageComponent,
    PlayerAvatarComponent,
    PlayerBadgesCarouselComponent,
    PlayerDetailsComponent,
    PlayerDisplayNameComponent,
    SharedModule,
    RouterModule,
  ],
  exports: [
    EventInfoComponent,
    EventNameComponent,
    NewEventComponent,
    PlayerAvatarComponent,
    PlayerBadgesCarouselComponent,
    PlayerCountryComponent,
    PlayerDetailsComponent,
    PlayerDisplayNameComponent,
    UserStatusComponent,
    QuickMessageComponent,
    ClubAvatarComponent,
    ImageCropperUploaderComponent,
    TimestampClockComponent,
    PlayerRowComponent,
    IssueRefundRegistrationFeeComponent,
    EventDocumentFormComponent,
    EventPairingsViewComponent,
    PairingsViewPipe,
    MatchPipe,
    ReportSlipComponent,
  ]
})
export class SharedComponentModule { }
