import { Injectable } from '@angular/core';
import { AngularFirestore, DocumentChangeAction, Query, QueryDocumentSnapshot } from '@angular/fire/compat/firestore';
import { BehaviorSubject, EMPTY, Observable, Subscription, combineLatest, firstValueFrom, map, switchMap, tap } from 'rxjs';
import { AuthService } from 'src/app/services';
import { PlayerNameService } from 'src/app/services/players/player-name.service';
import { IMessageDocument, IMessageGroupAttachment, IMessageGroupDocument, IPlayerMini } from 'tolaria-cloud-functions/src/_interfaces';
import { TolariaWysiwygMention } from '../components/tolaria-wysiwyg/tolaria-wysiwyg.interfaces';
import * as firestore from 'firebase/firestore'
import { Router } from '@angular/router';
import { v4 as uuidv4 } from 'node_modules/uuid';
import { MessageItem, MessageListService } from './message-list.service';

export interface MessageGroupItem {
  id: string
  name: string
  parsedName: string
  description: string
  preview: string
  lastMessageAt: number
  lastMessageFrom: string
  hasUnread: boolean
  images: string[]
  multipleImages: boolean
  players: IPlayerMini[]
  filterVal: string
  isEventChat: boolean
  isDraftChat: boolean
  isSingle: boolean
  createdByUid: string
  createdAt: number
  isCreator: boolean
  usersCanBeRemoved: boolean
  attachments: IMessageGroupAttachment[]
  attachmentCount: number
  pinnedMessages: string[]
  hasPinnedMessages: boolean
}

export interface MessageGroup {
  data: MessageGroupItem
  observer: BehaviorSubject<MessageGroupItem>
  pinnedMessages: Observable<MessageItem[]>
}

@Injectable({
  providedIn: 'root'
})
export class MessageGroupsService {

  private _observerCreationCalled: boolean = false
  private _messageGroupCount: number = 30
  private _messageGroupObserver: Observable<IMessageGroupDocument[]>
  private _subscription: Subscription
  private _messageGroups = new Map<string, MessageGroup>()
  private hasLoadedAll: boolean = false
  public list$ = new BehaviorSubject<MessageGroupItem[]>([])
  public serviceReady$ = new BehaviorSubject<boolean>(false)

  constructor(
    private readonly firestore: AngularFirestore,
    private readonly auth: AuthService,
    private readonly playerNames: PlayerNameService,
    private readonly router: Router,
    private readonly messageList: MessageListService,
  ) {
    this.playerNames.serviceReady$.subscribe((ready) => {
      this.logger('log', 'MessageGroupsService:: player name service ready', ready)
      if (ready && !this._observerCreationCalled) {
        this.updateObserver()
      }
    })
  }

  private debug = false
  private logger(type: string, value: string, data?: any) {
    if (this.debug || type === 'error') {
      if (data) {
        console[type](`[MessageGroupsService] -> ${value}`, data)
      }
      else {
        console[type](`[MessageGroupsService] -> ${value}`)
      }
    }
  }

  private updateObserver(limitless: boolean = false): void {

    this._observerCreationCalled = true
    this.logger('log', 'updating observer')

    // create new observer
    this._messageGroupObserver = this.firestore
      .collection<IMessageGroupDocument>('messageGroups', ref => {
        let query: Query = ref
        query = query.where('playerDocIds', 'array-contains', this.auth.user.playerId)
        query = query.where('latestMessage', '!=', null)
        query = query.orderBy('latestMessage', 'desc')
        if (!limitless) {
          query = query.limit(this._messageGroupCount)
        }
        return query
      })
      .valueChanges()

    // unsubscribe if subscription active
    if (this._subscription) {
      this._subscription.unsubscribe()
    }

    // start new subscription
    this._subscription = this._messageGroupObserver.subscribe(docs => {
      if (docs !== null && docs !== undefined) {
        this.mapGroups(docs)
      }
    })
  }

  private mapGroups(docs: IMessageGroupDocument[]) {

    let groups: MessageGroupItem[] = docs.map(i => this.mapGroup(i))

    groups.forEach(i => {
      // check if group exist
      let group = this._messageGroups.get(i.id)
      if (group) {
        group.data = i
        group.observer.next(i)
      }
      else {
        this._messageGroups.set(i.id, {
          data: i,
          observer: new BehaviorSubject<MessageGroupItem>(i),
          pinnedMessages: this.getPinnedObserver(i.id)
        })
      }
    })
    this.logger('log', 'groups mapped and ready', this._messageGroups)
    this.updateGroupList()
  }

  private updateGroupList(): void {
    const groups: MessageGroupItem[] = []
    for (const [key, value] of this._messageGroups) {
      groups.push(value.data)
    }
    this.logger('log', 'groups mapped and ready', groups.sort((a, b) => b.lastMessageAt - a.lastMessageAt))
    this.list$.next(groups.sort((a, b) => b.lastMessageAt - a.lastMessageAt))
    if (this.serviceReady$.getValue() === false) {
      this.serviceReady$.next(true)
    }
  }

  private async createMessageGroup(playerDocIds: string[]): Promise<MessageGroup | null> {
    const timestamp = firestore.Timestamp.now().seconds
    const messageGroupDoc: IMessageGroupDocument = {
      docId: uuidv4(),
      createdByUid: this.auth.user.uid,
      createdDate: timestamp,
      latestMessage: null,
      latestMessagePreview: null,
      playerDocIds: playerDocIds,
      isSingle: playerDocIds.length <= 2
    }

    return this.firestore.collection('messageGroups')
      .doc(messageGroupDoc.docId)
      .set(messageGroupDoc)
      .then(() => {
        const data = this.mapGroup(messageGroupDoc)
        const group: MessageGroup = {
          data: data,
          pinnedMessages: this.getPinnedObserver(data.id),
          observer: new BehaviorSubject<MessageGroupItem>(data)
        }
        this._messageGroups.set(data.id, group)
        return group
      })
      .catch(error => {
        this.logger('error', error)
        return null
      })
  }

  private mapGroup(data: IMessageGroupDocument): MessageGroupItem {
    let images = data.docId.includes('eventChatFor')
      ? this.imageParser([data.createdByUid], true)
      : this.imageParser(data.playerDocIds)
    let sender = this.playerNames.getPlayerById(data.latestMessageByPlayerId)
    let group: MessageGroupItem = {
      id: data.docId,
      description: data.description ? data.description : '',
      name: data.name === undefined
        ? ''
        : data.docId.includes('eventChatFor')
          ? data.name.replace('EVENT: ', '')
          : data.name,
      parsedName: this.nameParser(data.playerDocIds),
      images: images,
      multipleImages: images.length > 1,
      lastMessageAt: data.latestMessage,
      lastMessageFrom: sender !== undefined && sender !== null
        ? sender.name.first
        : data.latestMessageByPlayerId === 'scryfall-robot'
          ? 'TrikeBoy'
          : '',
      preview: data.latestMessagePreview,
      hasUnread: data.hasOwnProperty('playersLastVisit') && data.playersLastVisit[this.playerNames.currentPlayersMini.id] === undefined
        || data.hasOwnProperty('playersLastVisit') && data.playersLastVisit[this.playerNames.currentPlayersMini.id] < data.latestMessage,
      players: data.playerDocIds.map(i => this.playerNames.getPlayerById(i)),
      filterVal: data.docId.includes('eventChatFor')
        ? data.name.toLowerCase()
        : data.playerDocIds.filter(i => this.playerNames.getPlayerById(i) !== null).map(i => this.playerNames.getPlayerById(i)).map(i => [i.name.first, i.name.last, i.name.nick].join(' ')).join(' ').toLowerCase(),
      isEventChat: data.docId.includes('eventChatFor'),
      isDraftChat: data.docId.includes('draftChat'),
      isSingle: data.isSingle,
      createdByUid: data.createdByUid,
      createdAt: data.createdDate,
      isCreator: data.createdByUid === this.playerNames.currentPlayersMini.uid,
      usersCanBeRemoved: data.createdByUid === this.playerNames.currentPlayersMini.uid && !data.docId.includes('eventChatFor'),
      attachmentCount: data.attachmentCount ? data.attachmentCount : 0,
      attachments: data.attachments ? data.attachments : [],
      pinnedMessages: data.pinnedMessages ? data.pinnedMessages : [],
      hasPinnedMessages: data.pinnedMessages ? data.pinnedMessages.length > 0 : false,
    }
    return group
  }

  private getPinnedObserver(id: string) {
    return this.firestore
      .collection('messageGroups')
      .doc(id)
      .collection<IMessageDocument>('pinned')
      .valueChanges()
      .pipe(map(docs => {
        // sort the documents
        let sorted = docs.sort((a, b) => a.timestamp - b.timestamp)
        // create an array for hold all items
        let items: MessageItem[] = []
        // loop through the sorted documents and map them to messge items
        for (let [index, item] of sorted.entries()) {
          let tmp = this.messageList.mapMessage(item, index === 0 ? null : sorted[index - 1])
          items.push(tmp)
        }
        // return the message items
        return items
      }))
  }

  private imageParser(playerDocIds: string[], uid: boolean = false) {
    if (playerDocIds.length === 0) {
      return ['assets/avatars/default.jpg']
    }
    this.logger('info', 'current player mini document', this.playerNames.currentPlayersMini)
    return playerDocIds
      .filter(i => i !== this.playerNames.currentPlayersMini.id && !i.includes('temp__'))
      .map(i => {
        let player = uid ? this.playerNames.getPlayerByUid(i) : this.playerNames.getPlayerById(i)
        if (player === undefined) {
          this.logger('log', 'Cannot find player --> ', i)
        }
        return player
          ? player.avatar
            ? player.avatar
            : 'assets/avatars/default.jpg'
          : 'assets/avatars/default.jpg'
      })
      .splice(0, playerDocIds.length === 2 ? 1 : 2)
  }

  public search() {
    if (!this.hasLoadedAll) {
      this.hasLoadedAll = true
      this.updateObserver(true)
    }
  }

  public loadMore(): void {
    this._messageGroupCount = this._messageGroupCount + 10
    this.logger('log', `loading more, new count = ${this._messageGroupCount}`)
    if (this.hasLoadedAll === false) {
      this.updateObserver()
    }
  }

  public async getGroupById(id: string): Promise<MessageGroup | null> {
    if (id !== null) {
      let group = this._messageGroups.get(id)
      if (group) {
        return group
      }
      const snap = await firstValueFrom(this.firestore.collection('messageGroups').doc<IMessageGroupDocument>(id).get())
      if (snap.exists) {
        const groupData = this.mapGroup(snap.data())
        group = {
          data: groupData,
          observer: new BehaviorSubject<MessageGroupItem>(groupData),
          pinnedMessages: this.getPinnedObserver(id)
        }
        this._messageGroups.set(groupData.id, group)
        this.updateGroupList()
        return group
      }
      else {
        this.logger('log', 'MessageGroupsService:: group does not exist, should one be created?')
        return null
      }
    }
    return null
  }  

  public async getGroupByPlayers(playerDocIds: string[]) {
    const groups = this.list$.getValue()
    const groupSearch = groups.map(i => {
      return {
        id: i.id,
        playerDocIds: i.players.map(p => p.id).sort((a, b) => a.localeCompare(b))
      }
    })
    let group = groupSearch.find(i => i.playerDocIds.join(' ') === playerDocIds.sort((a, b) => a.localeCompare(b)).join(' '))
    this.logger('log', 'MessageGroupsService:: message group search result', group)
    if (group) {
      return this.getGroupById(group.id)
    }
    else {
      return await this.createMessageGroup(playerDocIds)
    }
  }

  public getPinnedMessages(id: string): Observable<MessageItem[]> {
    let group = this._messageGroups.get(id)
    if (group && group.pinnedMessages) {
      return group.pinnedMessages
    }
    return EMPTY
  }

  /**
   * Get the list of players in this message group
   * to be used by the mentioning lookup   * 
   * @param id string -> message group document id
   * @returns TolariaWysiwygMention[]
   */
  public async getMentionList(id: string): Promise<TolariaWysiwygMention[]> {
    let messageGroup: MessageGroupItem
    // check if group exist in service
    let group = this._messageGroups.get(id)
    this.logger('log', 'getMentionList', { id, group })
    
    // if found, set messageGroup from data
    if (group) {
      messageGroup = group.data
    }
    else {
      group = (await this.getGroupById(id))
    }
    
    // check if message group found, else get it
    if (messageGroup) {
      this.logger('log', 'getMentionList -> messageGroup found', { id, messageGroup })
      const players: TolariaWysiwygMention[] = []
      for (const player of messageGroup.players) {
        if (player && player.id) {
          players.push({
            playerDocId: player.id,
            playerDisplayName: player.name.display,
            value: player.name.display,
            id: player.id,
          })
        }
        else {
          this.logger('log', 'error getting player mention props', { player, messageGroup })
        }
      }
      this.logger('log', 'getMentionList -> returning mention list', { id, messageGroup, players })
      return players
    }
    else {
      this.logger('log', 'error getting player mention list, returning empty array')
      return []
    }
    
  }

  /**
   * From a list of player document ids, this will 
   * return a list of names concatenated by comma,
   * and, and a more
   * 
   * @param playerDocIds 
   * @returns names of the players joined by comma/and and/or more
   */
  public nameParser(playerDocIds: string[]): string {
      let parsedName = ''
      let additonalPlayers = 0
      let playersAddedToString = 0
      const maxLength = 30

      const ids = playerDocIds.filter(i => i !== this.playerNames.currentPlayersMini.id && !i.includes('temp__'))
      for (const id of ids) {
        const player = this.playerNames.getPlayerById(id)
        if (player) {
          const name = `${player.name.first} ${player.name.last}`
          if ((parsedName.length + name.length + 2) > maxLength) {
            additonalPlayers++
          }
          else {
            if (playersAddedToString > 0) {
              parsedName += ', '
            }
            playersAddedToString++
            parsedName += name
          }
        }
      }
      if (additonalPlayers > 0) {
        parsedName += ' and ' + additonalPlayers + ' more'
      }

      return parsedName
  }

  /**
   * Update the message group document with the a
   * timestamp connected to the current players document id
   * 
   * @param id string -> message group document id
   */
  public updateLastVisited(id: string): void {
    let timestamp = Math.round(new Date().getTime() / 1000)
    this.logger('log', `MessageListService:: update players last visit to ${timestamp}`)
    this.firestore
      .collection('messageGroups')
      .doc(id)
      .update({
        [`playersLastVisit.${this.auth.user.playerId}`]: timestamp
      })
      .catch(e => this.logger('error', e))
  }

  /**
   * Remove the current player from the message group 
   * with the given message group document id
   * 
   * @param id string -> message group document id
   */
  public leaveGroup(id: string): void {
    this.firestore
      .collection('messageGroups')
      .doc(id)
      .update({
        playerDocIds: firestore.arrayRemove(this.playerNames.currentPlayersMini.id)
      })
      .then(() => {
        this.router.navigate(['messages'])
        this._messageGroups.delete(id)
        this.updateGroupList()
      })
      .catch(e => this.logger('error', e))
  }

  /**
   * Add all players passed in to the given message group document
   * 
   * @param group MessageGroupItem
   * @param playerDocIds string[]
   * @returns Promise<boolean>
   */
  public addMembers(group: MessageGroupItem, playerDocIds: string[]): Promise<boolean> {
    return new Promise((resolve) => {
      let update = {
        playerDocIds: firestore.arrayUnion(...playerDocIds)
      }
      if ([...group.players, ...playerDocIds].length > 2) {
        update['isSingle'] = false
      }
      this.firestore
        .collection('messageGroups')
        .doc(group.id)
        .update(update)
        .then(() => resolve(true))
        .catch(e => {
          this.logger('error', e)
          resolve(false)
        })
    })
  }

  /**
   * Remove all players passed from the given message group document
   * 
   * @param group MessageGroupItem
   * @param player string[]
   * @returns Promise<boolean>
   */
  public removeMember(group: MessageGroupItem, player: IPlayerMini): Promise<boolean> {
    return new Promise((resolve) => {
      let update = {
        playerDocIds: firestore.arrayRemove(player.id)
      }
      if (group.players.length - 1 <= 2) {
        update['isSingle'] = true
      }
      this.firestore
        .collection('messageGroups')
        .doc(group.id)
        .update(update)
        .then(() => resolve(true))
        .catch(e => {
          this.logger('error', e)
          resolve(false)
        })
    })
  }

  /**
   * Save the new name of a message group docment
   * 
   * @param group MessageGroupItem
   */
  public saveName(group: MessageGroupItem): void {
    this.firestore
      .collection('messageGroups')
      .doc(group.id)
      .update({
        name: group.name
      })
      .catch(e => this.logger('error', e))
  }

  /**
   * Save the new description of a message group document
   * 
   * @param group MessageGroupItem
   */
  public saveDescription(group: MessageGroupItem): void {
    this.firestore
      .collection('messageGroups')
      .doc(group.id)
      .update({
        description: group.description
      })
      .catch(e => this.logger('error', e))
  }


  /**
   * Get the total count of message groups
   */
  public get messageGroupCount(): number {
    return this._messageGroupCount
  }



}
