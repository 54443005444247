import { CommonModule } from '@angular/common';
import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { MessageItem } from '../../../services/message-list.service';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { faBullhorn } from '@fortawesome/free-solid-svg-icons';
import { EventListingService, IEventTemplate } from 'src/app/private/play/events/services/event-listing.service';
import { BehaviorSubject, Subject, takeUntil } from 'rxjs';
import { PlayerAvatarComponent } from 'src/app/components/players/player-avatar/player-avatar.component';

@Component({
  selector: 'app-event-announcement',
  templateUrl: './event-announcement.component.html',
  styleUrls: ['./event-announcement.component.css'],
  standalone: true,
  imports: [
    CommonModule,
    FontAwesomeModule,
    PlayerAvatarComponent,
  ]
})
export class EventAnnouncementComponent implements OnInit, OnDestroy {
  @Input() msg: MessageItem

  private destroyed$ = new Subject<boolean>()
  public eventFetched$ = new BehaviorSubject<boolean>(false)
  public eventDoc$ = new BehaviorSubject<IEventTemplate>(null)

  constructor(
    private readonly events: EventListingService,
  ) {
  }
  
  ngOnInit() {
    console.log('EventAnnouncementComponent:: onInit -> ', this.msg)
    this.events.ready$
      .pipe(takeUntil(this.destroyed$))
      .subscribe(async (state) => {
        if (state) {
          console.log(`EventAnnouncementComponent:: trying to get event with id: ${this.eventDocId}`)
          let eventDoc = await this.events.getEventById(this.eventDocId)
          this.eventDoc$.next(eventDoc)
          console.log('EventAnnouncementComponent:: response -> ', eventDoc)
          this.eventFetched$.next(true)
        }
      })
  }

  ngOnDestroy(): void {
    this.destroyed$.next(true)
  }

  public onGoToLobbyPress(): void {

  }

  public get htmlData(): string {
    return this.msg
      ? this.msg.message.html
        ? this.msg.message.html
        : this.msg.message.message
      : ''
  }

  public get eventDocId(): string {
    return this.msg
      ? this.msg.message.messageGroupDocId.replace('eventChatFor[', '').replace(']', '')
      : null
  }

  public get icon() {
    return {
      horn: faBullhorn,
    }
  }

  public get organizers(): string[] {
    if (this.eventDoc$.getValue() === null) {
      return []
    }
    return [this.eventDoc$.getValue().createdByUid, ...this.eventDoc$.getValue().coOrganizers]
  }

}
