<ng-container *ngIf="leagueData$ | async as leagueData">
  <ng-container *ngIf="!isEditor; else editor">
    <div class="modal-header d-flex aligm-items-center justify-content-between">
      <div class="d-flex flex-column gap-2">Create new League</div>
      <button class="btn-close" (click)="onCancelPress()"></button>
    </div>
    <div class="modal-body">
      <ng-container [ngTemplateOutlet]="editor"></ng-container>
    </div>
    <div class="modal-footer d-flex aligm-items-center justify-content-end">
      <button class="btn btn-blank" (click)="onCancelPress()">Cancel</button>
      <button class="btn btn-primary" (click)="onCreatePress()" [disabled]="newLeagueFormDisabled">Create</button>
    </div>
  </ng-container>
  <ng-template #editor>
    <div class="d-flex flex-column gap-5">

      <ng-container *ngIf="changesMade$ | async as hasBeenChanged">
        <div class="alert alert-info m-0 d-flex align-items-center gap-2" role="alert" *ngIf="hasBeenChanged">
          <div class="spinner-border spinner-border-sm text-info" role="status"></div>
          <span>Changes has been made, saving...</span>
        </div>
      </ng-container>

      <!-- NAME -->
      <div class="d-flex flex-column gap-2">
        <div class="text-larger">Name</div>
        <input type="text" class="form-control" placeholder="" [(ngModel)]="leagueData.name" [disabled]="leagueData.archived" (ngModelChange)="onModelChanged($event)">
      </div>
    
      <!-- DESCRIPTION -->
      <div class="d-flex flex-column gap-2">
        <div class="text-larger">Description</div>
        <textarea class="form-control" rows="5" [(ngModel)]="leagueData.description" [disabled]="leagueData.archived" (ngModelChange)="onModelChanged($event)"></textarea>
      </div>
    
      <!-- DATES -->
      <div class="d-flex flex-column gap-2">
        <div class="text-larger">Period</div>
        <div class="d-flex align-items-center gap-3 flex-wrap flex-lg-nowrap">
          <div class="input-group">
            <span class="input-group-text" [style.minWidth.rem]="8">Starting date</span>
            <input
              class="form-control"
              placeholder="yyyy-mm-dd"
              [maxDate]="dateSelection.toDate - 1"
              [(ngModel)]="dateSelection.fromDate"
              ngbDatepicker
              #fromDate="ngbDatepicker"
              [disabled]="leagueData.archived"
              (dateSelect)="onDateSelection($event, 'from')"
            />
            <button class="btn btn-outline-secondary" (click)="fromDate.toggle()" type="button" [disabled]="leagueData.archived">
              <fa-icon [fixedWidth]="true" [icon]="icon.caledar"></fa-icon>
            </button>
          </div>
          <div class="input-group">
            <span class="input-group-text" [style.minWidth.rem]="8">Ending date</span>
            <input
              class="form-control"
              placeholder="yyyy-mm-dd"
              [minDate]="dateSelection.fromDate + 1"
              [(ngModel)]="dateSelection.toDate"
              ngbDatepicker
              #toDate="ngbDatepicker"
              [disabled]="leagueData.archived"
              (dateSelect)="onDateSelection($event, 'to')"
            />
            <button class="btn btn-outline-secondary" (click)="toDate.toggle()" type="button" [disabled]="leagueData.archived">
              <fa-icon [fixedWidth]="true" [icon]="icon.caledar"></fa-icon>
            </button>
          </div>
        </div>
      </div>
    
      <!-- POINT STRUCTURE -->
      <div class="d-flex flex-column gap-3">
        <div class="d-flex align-items-center justify-content-between gap-3">
          <span class="text-larger">Point Structure</span>
          <button class="btn btn-sm"
            [ngClass]="{ 'btn-outline-primary' : !showAddPoint, 'btn-danger' : showAddPoint }"
            [disabled]="leagueData.archived"
            (click)="onTogglePointFormPress()">
            {{ showAddPoint ? 'Cancel' : 'Add' }}
          </button>
        </div>
        <ng-container *ngIf="showAddPoint">
          <div class="p-3 border border-secondary border-opacity-50 rounded rounded-3 d-flex flex-column gap-3">
            <div class="d-flex align-items-center justify-content-between gap-3 flex-wrap flex-md-nowrap">
              <div class="input-group">
                <label class="input-group-text">Type</label>
                <select class="form-select" [(ngModel)]="newPoint.type">
                  <option *ngFor="let type of pointTypes" [ngValue]="type">{{ pointTypeTranslations[type].text }}</option>
                </select>
              </div>
              <div class="input-group">
                <label class="input-group-text">Points</label>
                <input type="number" class="form-control" [(ngModel)]="newPoint.points">
              </div>
            </div>
            <div *ngIf="newPoint.type !== null" class="alert alert-info m-0 p-2">{{ pointTypeTranslations[newPoint.type].tooltip }}</div>
            <ng-container *ngIf="newPoint.type === 'RANK'">
              <div class="d-flex aligm-items-center gap-3 flex-wrap flex-md-nowrap">
                <div class="flex-grow-1 flex-md-grow-0">
                  <div class="input-group-text">
                    <span class="d-flex align-items-center gap-2">
                      <input class="form-check-input mb-1" type="checkbox" [(ngModel)]="newPoint.isRange">
                      <span>Ranged?</span>
                    </span>
                  </div>
                </div>
                <ng-container *ngIf="!newPoint.isRange">
                  <div class="input-group">
                    <span class="input-group-text">Rank</span>
                    <input type="number" class="form-control" [(ngModel)]="newPoint.rankFrom" (change)="newPoint.rankTo = newPoint.rankFrom">
                  </div>
                </ng-container>
                <ng-container *ngIf="newPoint.isRange">
                  <div class="d-flex aligm-items-center gap-3 flex-wrap flex-md-nowrap flex-grow-1">
                    <div class="input-group flex-nowrap">
                      <span class="input-group-text">Rank from</span>
                      <input type="number" class="form-control" [(ngModel)]="newPoint.rankFrom">
                    </div>
                    <div class="input-group flex-nowrap">
                      <span class="input-group-text">Rank to</span>
                      <input type="number" class="form-control" [(ngModel)]="newPoint.rankTo">
                    </div>
                  </div>
                </ng-container>
              </div>
            </ng-container>
            <ng-container *ngIf="newPoint.type === 'CUSTOM'">
              <div class="form-group">
                <label class="mb-2">Explaination</label>
                <textarea rows="3" maxlength="50" class="form-control" [(ngModel)]="newPoint.customExplaination"></textarea>
              </div>
            </ng-container>
            <button type="button" class="btn btn-outline-primary" (click)="onAddPointPress()" [disabled]="newPointFormDisabled">Add point</button>
          </div>
        </ng-container>
        <ng-container *ngIf="showPointList; else noPoints">
          <div class="list-group">
            <div class="list-group-item list-group-item-action d-flex align-items-center gap-3 pe-2" *ngFor="let point of leagueData.pointStructure; let i = index">
              <div class="d-flex flex-column flex-grow-1">
                <span class="" [innerText]="pointTypeTranslations[point.type].text"></span>
                <span class="text-secondary text-small">{{ point.type === 'CUSTOM' ? point.customExplaination : pointTypeTranslations[point.type].tooltip }}</span>
              </div>
              <ng-container *ngIf="point.type === 'CUSTOM' && isEditor">
                <button class="btn btn-sm btn-outline-success" (click)="onAwardCustomPoint(point)" [disabled]="leagueData.archived">
                  Award point
                </button>  
              </ng-container>
              <ng-container *ngIf="point.type === 'RANK'">
                <span class="">Rank: {{ point.isRange ? point.rankFrom + ' - ' + point.rankTo : point.rankTo }}</span>
              </ng-container>
              <span class="alert alert-info m-0 p-0 px-2">
                <span class="text-large fw-bold">{{ point.points }}</span>
                <span class="">p</span>
              </span>
              <button class="btn btn-sm btn-danger" (click)="onRemovePointPress(i)" [disabled]="leagueData.archived">
                Remove
              </button>
            </div>
          </div>
        </ng-container>
        <ng-template #noPoints>
          <div class="list-group">
            <div class="list-group-item list-group-item-action d-flex align-items-center gap-3 pe-2">
              <span class="flex-grow-1">No point added yet. To add a point structure, press the add button above.</span>
            </div>
          </div>
        </ng-template>
      </div>

      <!-- MANUAL POINTS -->
      <div class="d-flex flex-column gap-3" *ngIf="hasManualPoints">
        <div class="d-flex align-items-center justify-content-between gap-3">
          <span class="text-larger">Manual Points</span>
        </div>
        <div class="list-group">
          <div class="list-group-item list-group-item-action d-flex align-items-center gap-3 pe-2" *ngFor="let point of leagueData.manualPoints; let index = $index">
            <div class="" [innerText]="point.comment"></div>
            <app-player-display-name class="text-secondary" [playerDocId]="point.playerDocId"></app-player-display-name>
            <div class="flex-grow-1"></div>
            <span class="alert alert-info m-0 p-0 px-2">
              <span class="text-large fw-bold">{{ point.points }}</span>
              <span class="">p</span>
            </span>
            <button class="btn btn-sm btn-danger" (click)="onRemoveCutomPoint(index)" [disabled]="leagueData.archived">
              Remove
            </button>
          </div>
        </div>
      </div>

      <!-- EVENTS -->
      <div class="d-flex flex-column gap-3" *ngIf="isEditor">
        <div class="d-flex align-items-center justify-content-between gap-3">
          <span class="text-larger">Connected Tournaments</span>
          <button class="btn btn-sm btn-outline-primary" [disabled]="leagueData.archived" (click)="onAddTournamentPress()">Add</button>
        </div>
        <ng-container *ngIf="tournamentList$ | async as tournaments; else noTournaments">
          <div class="list-group">
            <div class="list-group-item list-group-item-action d-flex align-items-center gap-3 pe-2" *ngFor="let tournament of tournaments">
              <div class="d-flex flex-column flex-grow-1">
                {{ tournament.details.name }}
              </div>
              <button class="btn btn-sm btn-danger" (click)="onRemoveTournamentPress(tournament)" [disabled]="leagueData.archived">
                Remove
              </button>
            </div>
          </div>
        </ng-container>
        <ng-template #noTournaments>
          <div class="list-group">
            <div class="list-group-item list-group-item-action d-flex align-items-center gap-3 pe-2">
              <span class="flex-grow-1">No tournaments added yet. To add a tournament, press the add button above.</span>
            </div>
          </div>
        </ng-template>
      </div>
    
      <!-- BANNER -->
      <div class="d-flex flex-column gap-3">
        <div class="d-flex align-items-center justify-content-between">
          <div class="text-larger">Banner image</div>
          <ng-container *ngIf="imageUploadBusy$ | async as loading">
            <div class="d-flex align-items-center gap-2">
              <div class="spinner-border spinner-border-sm" role="status"></div>
              <span class="">Uploading image...</span>
            </div>
          </ng-container>
          <div class="d-flex gap-2">
            <button *ngIf="isEditor"
              class="btn btn-outline-primary"
              [disabled]="leagueData.archived"
              (click)="hideBannerSelection = !hideBannerSelection">
              {{ hideBannerSelection ? 'Change' : 'Done' }}
            </button>
          </div>
        </div>
        <ng-container *ngIf="hideBannerSelection && isEditor; else uploader">
          <img class="rounded rounded-3 border border-secondary border-opacity-50" [src]="leagueData.bannerUrl" [style.minWidth.rem]="20" [style.maxWidth.rem]="40">
        </ng-container>
        <ng-template #uploader>
          <app-image-cropper-uploader (base64)="onImageLoaded($event)"></app-image-cropper-uploader>
          <div class="image-preview" *ngIf="imageData$ | async as imageData">
            <span class="text-secondary text-small">Preview</span>
            <img class="rounded rounded-23border border-secondary border-opacity-50 w-100" [src]="imageData">
          </div>
        </ng-template>
      </div>
    </div>
  </ng-template>
</ng-container>