import { Component, ElementRef, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { faArrowsAltH, faArrowsAltV, faUndoAlt } from '@fortawesome/free-solid-svg-icons';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { ImageCroppedEvent, ImageCropperModule, ImageTransform, LoadedImage } from 'ngx-image-cropper';
import { tiFlipLeft } from 'src/assets/font-icons/tolaria-flip-left';
import { tiFlipRight } from 'src/assets/font-icons/tolaria-flip-right';

@Component({
  selector: 'app-image-cropper-uploader',
  templateUrl: './image-cropper-uploader.component.html',
  styleUrls: ['./image-cropper-uploader.component.css'],
  standalone: true,
  imports: [
    ImageCropperModule,
    NgbModule,
    FontAwesomeModule,
  ],
})
export class ImageCropperUploaderComponent implements OnInit {
  @ViewChild('fileInput') fileInput: ElementRef
  @ViewChild('canvas') canvas: ElementRef
  private ctx: CanvasRenderingContext2D

  @Input() set dataUri(base64: string) {
    this.imageFile = base64
  }
  @Input() maintainAspectRatio: boolean = true
  @Input() containWithinAspectRatio: boolean = true
  @Input() aspectRatio: number = 16 / 9
  @Output() base64 = new EventEmitter

  public icon = {
    flipH: faArrowsAltH,
    flipV: faArrowsAltV,
    flipL: tiFlipLeft,
    flipR: tiFlipRight,
    undo: faUndoAlt,
  }

  private croppedImage: any = ''

  public canvasRotation: number = 0
  public rotation: number = 0
  public scale: number = 1
  public transform: ImageTransform = {}
  public format: 'png' | 'jpeg' | 'webp' | 'bmp' | 'ico' = 'jpeg'
  public resizeToWidth: number = 1600
  public orientation: number = 0 // 0 = landscape | 1 = portrait
  public imageFile: any
  public imageChangedEvent: any = ''
  public imageBase64: string | null = null
  public showError: boolean = false

  constructor() { }

  ngOnInit(): void {
    console.log({
      maintainAspectRatio: this.maintainAspectRatio,
      containWithinAspectRatio: this.containWithinAspectRatio,
      aspectRatio: this.aspectRatio,
    })
  }

  private outputData() {
    this.base64.emit(this.croppedImage);
  }

  public rotateLeft() {
    console.log('[ImageCropperUploaderComponent] --> rotateLeft')
    this.canvasRotation--
    this.flipAfterRotate()
  }

  public rotateRight() {
    console.log('[ImageCropperUploaderComponent] --> rotateRight')
    this.canvasRotation++
    this.flipAfterRotate()
  }

  public flipHorizontal() {
    console.log('[ImageCropperUploaderComponent] --> flipHorizontal')
    this.transform = {
      ...this.transform,
      flipH: !this.transform.flipH
    }
  }

  public flipVertical() {
    console.log('[ImageCropperUploaderComponent] --> flipVertical')
    this.transform = {
      ...this.transform,
      flipV: !this.transform.flipV
    }
  }

  public resetImage() {
    console.log('[ImageCropperUploaderComponent] --> resetImage')
    this.scale = 1
    this.rotation = 0
    this.canvasRotation = 0
    this.transform = {}
  }

  public zoomOut() {
    console.log('[ImageCropperUploaderComponent] --> zoomOut')
    this.scale -= .1
    this.transform = {
      ...this.transform,
      scale: this.scale
    }
  }

  public zoomIn() {
    console.log('[ImageCropperUploaderComponent] --> zoomIn')
    this.scale += .1
    this.transform = {
      ...this.transform,
      scale: this.scale
    }
  }

  private flipAfterRotate() {
    console.log('[ImageCropperUploaderComponent] --> flipAfterRotate')
    const flippedH = this.transform.flipH
    const flippedV = this.transform.flipV
    this.transform = {
      ...this.transform,
      flipH: flippedV,
      flipV: flippedH
    }
  }

  // IMAGE UPLOAD
  fileChangeEvent(event: any): void {
    this.showError = false
    const file = this.fileInput.nativeElement.files[0]
    if (file) {
      // console.log('[ImageCropperUploaderComponent.fileChangedEvent] -->', files)
      // this.imageFile = files[0]

      const reader = new FileReader()

      reader.onload = (e) => {
        const image = new Image()
        image.src = e.target.result as string
        
        image.onload = () => {
          
          // get image dimensions
          let newWidth = image.width
          let newHeight = image.height
          
          // get context of canvas and set to local variable
          this.ctx = this.canvas.nativeElement.getContext('2d')

          // check needed canvas size based on image dimensions
          if (image.width * image.height > 16777216) {
            // define new max area size (1600 x 900 [aspect ratio 16:9])
            const maxArea = 1440000
            // calculate ratio
            const ratio = Math.sqrt(maxArea / (image.width * image.height))
            // calculate new width and height
            newWidth = Math.round(image.width * ratio)
            newHeight = Math.round(image.height * ratio)
          }

          // resize the canvas
          this.canvas.nativeElement.width = newWidth
          this.canvas.nativeElement.height = newHeight
          
          try {

            // set image to context
            this.ctx.drawImage(
              image,
              0,
              0,
              newWidth,
              newHeight
            )

            // set source for cropper to the context as base64
            this.imageFile = this.ctx.canvas.toDataURL('image/png')

          }
          catch(e) {
            console.log(e)
          }

        }
      }

      reader.readAsDataURL(file)

    }
  }

  imageCropped(event: ImageCroppedEvent) {
    console.log(event)
    this.croppedImage = event.base64;
    this.outputData();
  }

  imageLoaded(image: LoadedImage): void {
    // show cropper
    console.log('imageLoaded', image)
  }

  cropperReady(): void {
    // cropper ready
    console.log('cropperReady')
  }
  loadImageFailed(error: any): void {
    // show message
    console.log('loadImageFailed', error);
    this.showError = true
  }

}
