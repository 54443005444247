<ng-template #mainMenu let-offcanvas>
  <app-main-menu class="d-flex flex-column text-normal h-100 overflow-hidden"></app-main-menu>
</ng-template>

<div class="top-bar">

  <!-- BUTTON -->
  <div class="top-bar-item d-flex align-items-center justify-content-center text-large">
    <button class="btn btn-blank"
      (click)="openMenu(mainMenu)">
      <fa-icon [icon]="icons.faBars"></fa-icon>
    </button>
  </div>

  <!-- TITLE -->
  <div class="top-bar-item title" routerLink="/dashboard" routerLinkActive="active">Tolaria</div>


  <!-- MID SECTION -->
  <div class="flex-grow-1 top-bar-item align-items-center d-flex d-sm-none"></div>
  <div class="flex-grow-1 top-bar-item justify-content-center align-items-center d-none d-sm-flex px-4">

    <ng-container [ngSwitch]="route">
      <app-league-header-controls *ngSwitchCase="'leagues'" class="flex-grow-1 justify-content-center" [style.maxWidth.px]="800" [style.minWidth.px]="350"></app-league-header-controls>
      <app-events-filter-bar *ngSwitchCase="'events'" class="flex-grow-1 justify-content-center d-none d-lg-block" [style.maxWidth.px]="800" [style.minWidth.px]="350"></app-events-filter-bar>
      <app-matches-filter-bar *ngSwitchCase="'matches'" class="flex-grow-1 justify-content-center d-none d-lg-block" [style.maxWidth.px]="800" [style.minWidth.px]="350"></app-matches-filter-bar>
      <div class="d-none d-md-flex flex-grow-1 align-items-center" *ngSwitchCase="'event-lobby'">
        <div class="event-name">
          <fa-icon [fixedWidth]="true" [icon]="icons.faTrophy" class="me-2"></fa-icon>
          <app-event-name></app-event-name>
        </div>
        <div class="flex-grow-1"></div>
        <app-event-round-timer class="d-none d-sm-block top-bar-item router-actions" [topBarWrapper]="true"></app-event-round-timer>
      </div>
    </ng-container>

  </div>

  <!-- ACTIONS -->
  <div class="action-wrapper d-none d-md-block top-bar-item">
    <div class="action-icons">
      <app-dark-mode-switch></app-dark-mode-switch>
    </div>
  
    <div *ngIf="presence$ | async as presence" class="action-icons"
      ngbTooltip="Down to play?"
      placement="bottom"
      container="body">
      <div ngbDropdown
        triggers="maual"
        autoClose="outside"
        container="body"
        #downToPlayMenu="ngbDropdown"
        (click)="downToPlayMenu.open()">
        <div class="d-inline-block" ngbDropdownToggle id="downToPlayDropdown">
          <div class="btn-action d-flex align-items-center gap-2">
            <fa-icon [fixedWidth]="true" [icon]="icons.faHandSparkles" [ngClass]="{ 'text-body' : presence.downToPlay }"></fa-icon>
            <span *ngIf="presence.downToPlay" class="alert alert-info text-smal m-0 p-0 px-2">ACTIVE</span>
          </div>
          <div ngbDropdownMenu aria-labelledby="downToPlayDropdown" class="top-bar-dropdown">
            <app-down-to-play-settings [presence]="presence" [uid]="user.uid"></app-down-to-play-settings>
          </div>
        </div>
      </div>
    </div>
  
    <div class="d-none action-icons">
      <div class="btn-action">
        <fa-icon [fixedWidth]="true" [icon]="icons.faQuestionCircle"></fa-icon>
      </div>
    </div>
  
    <div class="action-icons"
      ngbTooltip="Toggle fullscreen mode"
      container="body">
      <div class="btn-action" (click)="toggleFullscreen()">
        <fa-icon [fixedWidth]="true" [icon]="showFullscreen ? icons.faCompress : icons.faExpand"></fa-icon>
      </div>
    </div>
  
    <div #notificationButton class="action-icons">
      <div class="btn-action" [ngClass]="{ 'active' : showMessageList }" (click)="showMessageList = !showMessageList">
        <fa-icon [fixedWidth]="true" [icon]="icons.faBell" [ngClass]="{ 'rotate-20deg' : unreadNotifications > 0 }">
        </fa-icon>
      </div>
      <div class="notification-badge" *ngIf="unreadNotifications > 0"></div>
      <div #notificationList class="message-list" *ngIf="showMessageList">
        <div class="message" *ngFor="let message of messages; let i = index" (click)="notificationClick(i)"
          [ngClass]="{ 'unread' : !message.hasRead}">
          <div class="icon">
            <img [src]="message?.notification.icon">
          </div>
          <div class="content">
            <div class="notifiction-title">{{ message?.notification.title }}</div>
            <div class="notifiction-body">{{ message?.notification.body }}</div>
          </div>
        </div>
      </div>
    </div>
  </div>

  <!-- USER MENU -->
  <div class="top-bar-item user" ngbDropdown container="body" placement="bottom-right" #downToPlayMenu="ngbDropdown">
    <div class="d-inline-block" ngbDropdownToggle id="settingsDropdown">
      <div class="user-photo cursor-pointer">
        <img [src]="user.avatar">
      </div>
      <div ngbDropdownMenu aria-labelledby="settingsDropdown" class="top-bar-dropdown">
        <button ngbDropdownItem (click)="showInfo('versionHistory')">
          <fa-icon [fixedWidth]="true" [icon]="icons.faHistory" class="me-2"></fa-icon>Version History
        </button>
        <button ngbDropdownItem (click)="showInfo('privacyPolicy')">
          <fa-icon [fixedWidth]="true" [icon]="icons.faIdBadge" class="me-2"></fa-icon>Privacy Policy
        </button>
        <button ngbDropdownItem (click)="showInfo('termsOfUse')">
          <fa-icon [fixedWidth]="true" [icon]="icons.faFileSignature" class="me-2"></fa-icon>Terms of Use
        </button>
        <button ngbDropdownItem (click)="showInfo('purchaseHistory')">
          <fa-icon [fixedWidth]="true" [icon]="icons.faReceipt" class="me-2"></fa-icon>Purchases
        </button>
        <button ngbDropdownItem (click)="openSettings()">
          <fa-icon [fixedWidth]="true" [icon]="icons.faCog" class="me-2"></fa-icon>Settings
        </button>
        <button ngbDropdownItem (click)="signOut()" class="text-red">
          <fa-icon [fixedWidth]="true" [icon]="icons.faSignOutAlt" class="me-2"></fa-icon>Sign out
        </button>
        <div class="m-1 border-top pt-2">
          <app-version-number></app-version-number>
        </div>
      </div>
    </div>
  </div>

</div>
