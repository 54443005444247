<ng-container *ngIf="event$ | async as event">


  <div class="mb-3 position-sticky"
    *ngIf="showValidationInForm"
    [style.top.rem]="0"
    [style.zIndex]="10">
    <div class="alert {{ validationCheck.valid ? 'alert-success' : 'alert-warning' }}">
      {{ validationText }}
    </div>
  </div>


  <ng-container [ngTemplateOutlet]="eventDetails"></ng-container>
  <ng-container [ngTemplateOutlet]="eventDescription"></ng-container>
  <ng-container [ngTemplateOutlet]="eventBanner"></ng-container>
  <ng-container [ngTemplateOutlet]="eventRegistration"></ng-container>
  <ng-container [ngTemplateOutlet]="eventStructure"></ng-container>
  <ng-container [ngTemplateOutlet]="eventSettings"></ng-container>





  <ng-template #eventDetails>

    <div class="my-3 p-3 bg-body rounded shadow-sm" id="wrapper-Details" #configDetails>
      <div class="mb-3 text-larger">Details</div>

      <div class="mb-3">
        <label class="form-label" for="eventName">Name</label>
        <input type="text"
          class="form-control"
          id="eventName"
          aria-describedby="eventName"
          [(ngModel)]="event.details.name"
          [disabled]="disabledAfterStart"
          (ngModelChange)="onPropertyChanged('details.name')">
        <small id="eventName" class="form-text text-secondary">Give your event a sweet name!</small>
      </div>

      <div class="mb-3">
        <label class="form-label" for="eventLocation">Location</label>
        <div class="input-group">
          <input type="text"
            class="form-control"
            id="eventLocation"
            aria-describedby="button-location-details"
            [(ngModel)]="event.details.location.name"
            [disabled]="disabledAfterStart"
            (ngModelChange)="onPropertyChanged('details.location.name')">
          <button class="btn btn-outline-secondary" type="button" id="button-location-details" (click)="showLocationExtra = !showLocationExtra">Add address and more</button>
        </div>
      </div>

      <ng-container *ngIf="showLocationExtra">

        <div class="mb-3">
          <label class="form-label" for="eventLocationAddress">Address</label>
          <input type="text"
            class="form-control"
            id="eventLocationAddress"
            [(ngModel)]="event.details.location.address"
            [disabled]="disabledAfterStart"
            (ngModelChange)="onPropertyChanged('details.location.address')">
        </div>

        <div class="mb-3">
          <label class="form-label" for="eventLocationExtra">Extra</label>
          <input type="text"
            class="form-control"
            id="eventLocationExtra"
            [(ngModel)]="event.details.location.extra"
            [disabled]="disabledAfterStart"
            (ngModelChange)="onPropertyChanged('details.location.extra')">
        </div>

        <div class="row">

          <div class="mb-3 col" [style.minWidth.px]="300">
            <label class="form-label" for="eventPostalCode">Postal code</label>
            <input type="text"
              class="form-control"
              id="eventPostalCode"
              [(ngModel)]="event.details.location.postalCode"
              [disabled]="disabledAfterStart"
              (ngModelChange)="onPropertyChanged('details.location.postalCode')">
          </div>

          <div class="mb-3 col" [style.minWidth.px]="300">
            <label class="form-label" for="eventPostalArea">Postal area</label>
            <input type="text"
              class="form-control"
              id="eventPostalArea"
              [(ngModel)]="event.details.location.postalArea"
              [disabled]="disabledAfterStart"
              (ngModelChange)="onPropertyChanged('details.location.postalArea')">
          </div>

        </div>

        <div class="mb-3">
          <label class="form-label" for="eventLocationUrl">Website</label>
          <input type="text"
            class="form-control"
            id="eventLocationUrl"
            [(ngModel)]="event.details.location.url"
            [disabled]="disabledAfterStart"
            (ngModelChange)="onPropertyChanged('details.location.url')">
        </div>

      </ng-container>


      <div class="row">

        <div class="mb-3 col" [style.minWidth.px]="300">
          <label class="form-label" for="eventStart">Start</label>
          <div class="input-group">
            <input #eventStart
              type="text"
              class="form-control"
              id="eventStart"
              (focus)="onDateTimePickerSelect('eventStart')"
              [disabled]="disabledAfterStart"
              [value]="event.details.datestampFrom === null
                ? ''
                : (event.details.datestampFrom | date: datePipeFormat) + timezoneOffset">
            <button class="btn btn-outline-secondary"
              [disabled]="disabledAfterStart"
              (click)="onDateTimePickerSelect('eventStart')">
              <fa-icon [icon]="calendar" [fixedWidth]="true"></fa-icon>
            </button>
          </div>
        </div>

        <div class="mb-3 col" [style.minWidth.px]="300">
          <label class="form-label" for="eventEnd">End</label>
          <div class="input-group">
            <input #eventEnd
              type="text"
              class="form-control"
              id="eventEnd"
              (focus)="onDateTimePickerSelect('eventEnd')"
              [disabled]="disabledAfterStart"
              [value]="event.details.datestampTo === null
                ? ''
                : (event.details.datestampTo | date : datePipeFormat) + timezoneOffset">
            <button class="btn btn-outline-secondary"
              [disabled]="disabledAfterStart"
              (click)="onDateTimePickerSelect('eventEnd')">
              <fa-icon [icon]="calendar" [fixedWidth]="true"></fa-icon>
            </button>
          </div>
        </div>

      </div>

      <div class="mb-3 text-secondary text-small">
        Client timezone offset <span class="text-bold">{{ dateReference | date : 'OOOO' }}</span> will be used unless a specific timezone
        offset is specified <a (click)="showTimezoneOffsetSelection = !showTimezoneOffsetSelection" class="cursor-pointer">here</a>.
      </div>

      <ng-container *ngIf="showTimezoneOffsetSelection">
        <div class="mb-3 d-flex align-items-center mt-3">
          <div class="flex-grow-1">Override local timezone offset</div>
          <div class="form-check form-switch">
            <input type="checkbox"
              class="form-check-input"
              id="setting-override-timezone-offset"
              [disabled]="disabledAfterStart"
              [(ngModel)]="overrideTimezoneOffset"
              (ngModelChange)="onPropertyChanged('override-timezone-offset-off')">
            <label class="form-check-label" for="setting-override-timezone-offset"></label>
          </div>
        </div>
        <div class="mb-3 d-flex align-items-center mt-3">
          <div class="flex-grow-1">Timezone offset</div>
          <div class="form-group form-group-sm m-0">
            <select class="form-select form-select-sm text-end"
              [disabled]="!overrideTimezoneOffset || disabledAfterStart"
              [(ngModel)]="event.details.GMT_offset"
              (ngModelChange)="onPropertyChanged('details.GMT_offset')">
              <option [value]="offset" *ngFor="let offset of timezoneOffsetList">{{ offset }}</option>
            </select>
          </div>
        </div>
      </ng-container>

    </div>

  </ng-template>

  <ng-template #eventDescription>

    <div class="my-3 p-3 bg-body rounded shadow-sm" id="wrapper-Description" #configDescription>
      <div class="mb-3 text-larger">Description</div>

      <ckeditor #editor
        [config]="ckeConfig"
        [(ngModel)]="event.details.description"
        (ngModelChange)="onPropertyChanged('description')"
        id="description"
        name="description"
        type="divarea">
      </ckeditor>

    </div>

  </ng-template>

  <ng-template #eventBanner>

    <div class="my-3 p-3 bg-body rounded shadow-sm" id="wrapper-Banner" #configBanner>
      <div class="mb-3 text-larger d-flex align-items-center">
        <div class="flex-grow-1">
          Banner
        </div>

        <button class="btn btn-sm btn-blank"
          *ngIf="editBanner"
          (click)="editBanner = false">
          Cancel
        </button>
        <button class="btn btn-sm btn-outline-main"
          *ngIf="event.bannerUrl && event.bannerUrl !== ''"
          [disabled]="editBanner"
          (click)="editBanner = true">
          Edit banner
        </button>
      </div>



      <ng-container *ngIf="!event.bannerUrl || event.bannerUrl === '' || editBanner; else showBanner">

        <app-image-cropper-uploader
          [aspectRatio]="2 / 1"
          (base64)="onImageCropped($event)">
        </app-image-cropper-uploader>

        <div class="d-flex align-items-center mb-3">
          <div class="flex-grow-1">Preview</div>
          <button class="btn btn-sm btn-outline-main"
            [disabled]="!showImagePreview"
            (click)="onUploadEventBanner()">
            Upload image
          </button>
        </div>

        <div class="image-preview-wrapper" *ngIf="showImagePreview">
          <img [src]="imageToUpload">
        </div>


      </ng-container>

      <ng-template #showBanner>
        <div class="image-preview-wrapper">
          <img [src]="event.bannerUrl">
        </div>
      </ng-template>



    </div>

  </ng-template>

  <ng-template #eventRegistration>

    <div class="my-3 p-3 bg-body rounded shadow-sm" id="wrapper-Registration" #configRegistration>
      <div class="mb-3 text-larger">Registration</div>

      <div class="row">

        <div class="mb-3 col" [style.minWidth.px]="300">
          <label class="form-label" for="eventRegistrationStart">Open</label>
          <div class="input-group">
            <input #eventRegistrationOpen
              type="text"
              class="form-control"
              id="eventRegistrationStart"
              (focus)="onDateTimePickerSelect('eventRegistrationStart')"
              [disabled]="disabledAfterStart"
              [value]="event.details.registrationOpensTimestamp === null
                ? ''
                : (event.details.registrationOpensTimestamp | date : datePipeFormat) + timezoneOffset">
            <button class="btn btn-outline-secondary"
              (click)="onDateTimePickerSelect('eventRegistrationStart')"
              [disabled]="disabledAfterStart">
              <fa-icon [icon]="calendar" [fixedWidth]="true"></fa-icon>
            </button>
          </div>
        </div>

        <div class="mb-3 col" [style.minWidth.px]="300">
          <label class="form-label" for="eventRegistrationClose">Close</label>
          <div class="input-group">
            <input #eventRegistrationClose
              type="text"
              class="form-control"
              id="eventRegistrationClose"
              (focus)="onDateTimePickerSelect('eventRegistrationClose')"
              [disabled]="disabledAfterStart"
              [value]="event.details.registrationClosesTimestamp === null
                ? ''
                : (event.details.registrationClosesTimestamp | date : datePipeFormat) + timezoneOffset">
            <button class="btn btn-outline-secondary"
              (click)="onDateTimePickerSelect('eventRegistrationClose')"
              [disabled]="disabledAfterStart">
              <fa-icon [icon]="calendar" [fixedWidth]="true"></fa-icon>
            </button>
          </div>
        </div>

      </div>

      <div class="mb-3 text-secondary text-small">
        Client timezone offset <span class="text-bold">{{ dateReference | date : 'OOOO' }}</span> will be used unless a specific timezone offset has been specified above.
      </div>

      <div class="d-flex my-3">
        <div class="bd-placeholder-img rounded me-3" [style.minWidth.px]="32" [style.minHeight.px]="32">
          <fa-icon [icon]="coins" [fixedWidth]="true" class="text-larger"></fa-icon>
        </div>
        <div class="flex-grow-1 pb-3 mb-0 small lh-125">
          <div class="d-flex justify-content-between align-items-center w-100">
            <span class="text-gray-dark">Registration fee</span>
            <div class="form-check form-switch">
              <input type="checkbox"
                class="form-check-input"
                id="registrationFeeActive"
                [disabled]="disabledAfterStart || !showRegistrationFeeForm"
                [(ngModel)]="event.details.registrationFee.active"
                (ngModelChange)="onPropertyChanged('details.registrationFee.active')">
              <label class="form-check-label" for="registrationFeeActive"></label>
            </div>
          </div>
          <span class="d-block text-small text-secondary">
            Enable this option if you need help tracking the player registration fees. Either manually handle payments, or
            use the built-in payment solution provided to you here by Tolaria.
          </span>
        </div>

      </div>


      <ng-container [ngTemplateOutlet]="eventRegistrationFee" *ngIf="showRegistrationFeeForm; else howToBecomeConnected"></ng-container>



    </div>

  </ng-template>

  <ng-template #eventRegistrationFee>

    <div class="mb-3 mt-4 ms-5 p-3 rounded border border-secondary" id="wrapper-RegistrationFee" *ngIf="event.details.registrationFee.active">

      <div class="d-flex mb-3">
        <div class="bd-placeholder-img rounded me-3" [style.minWidth.px]="32" [style.minHeight.px]="32">
          <fa-icon [icon]="amount" [fixedWidth]="true" class="text-larger"></fa-icon>
        </div>
        <div class="flex-grow-1 pb-3 mb-0 small lh-125 border-bottom border-secondary border-gray">
          <div class="d-flex justify-content-between align-items-center w-100">
            <span class="text-gray-dark">Amount</span>
            <input type="number"
              class="form-control"
              id="registrationFeeAmount"
              [style.maxWidth.rem]="5"
              [min]="minRegFee"
              [disabled]="!event.details.registrationFee.active || disabledAfterStart"
              [(ngModel)]="event.details.registrationFee.amount"
              (ngModelChange)="onPropertyChanged('details.registrationFee.amount')">
          </div>
          <span class="d-block text-small text-secondary">
            Enter the amount for the registration fee. Your default currency (<span class="text-bold text-uppercase text-orange">{{ event.details.registrationFee.currency }}</span>) will be used.
          </span>
        </div>
      </div>

      <div class="d-flex mb-3">
        <div class="bd-placeholder-img rounded me-3" [style.minWidth.px]="32" [style.minHeight.px]="32">
          <fa-icon [icon]="tolaria" [fixedWidth]="true" class="text-larger"></fa-icon>
        </div>
        <div class="flex-grow-1 pb-3 mb-0 small lh-125 border-bottom border-secondary border-gray">
          <div class="d-flex justify-content-between align-items-center w-100">
            <span class="text-gray-dark">Tolaria payment</span>
            <div class="form-check form-switch">
              <input type="checkbox"
                class="form-check-input"
                id="registrationFeeTolariaPayment"
                [disabled]="!event.details.registrationFee.active || disabledAfterStart"
                [(ngModel)]="event.details.registrationFee.tolariaPayment"
                (ngModelChange)="onPropertyChanged('details.registrationFee.tolariaPayment')">
              <label class="form-check-label" for="registrationFeeTolariaPayment"></label>
            </div>
          </div>
          <span class="d-block text-small text-secondary">
            Attendees will be able to pay the registration fee through the built-in Tolaria payment solution.
            A platform fee is added for each transaction. For more information, read <span class="link cursor-pointer text-orange" (click)="showStripeInfo()">this</span>. 
          </span>
        </div>
      </div>

      <div class="d-flex mb-3">
        <div class="bd-placeholder-img rounded me-3" [style.minWidth.px]="32" [style.minHeight.px]="32">
          <fa-icon [icon]="cashRegister" [fixedWidth]="true" class="text-larger"></fa-icon>
        </div>
        <div class="flex-grow-1 pb-3 mb-0 small lh-125 border-bottom border-secondary border-gray">
          <div class="d-flex justify-content-between align-items-center w-100">
            <span class="text-gray-dark">Force payment when attending</span>
            <div class="form-check form-switch">
              <input type="checkbox"
                class="form-check-input"
                id="registrationForcePaymentWhenAttendinging"
                [disabled]="!event.details.registrationFee.active || disabledAfterStart"
                [(ngModel)]="event.details.registrationFee.forcePaymentWhenAttending"
                (ngModelChange)="onPropertyChanged('details.registrationFee.forcePaymentWhenAttending')">
              <label class="form-check-label" for="registrationForcePaymentWhenAttendinging"></label>
            </div>
          </div>
          <span class="d-block text-small text-secondary">
            To be able to attend, the player must pay the registration fee up front. 
          </span>
        </div>
      </div>

      <div class="d-flex mb-3">
        <div class="bd-placeholder-img rounded me-3" [style.minWidth.px]="32" [style.minHeight.px]="32">
          <fa-icon [icon]="charity" [fixedWidth]="true" class="text-larger"></fa-icon>
        </div>
        <div class="flex-grow-1 mb-0 small lh-125">
          <div class="d-flex justify-content-between align-items-center w-100">
            <span class="text-gray-dark">Charity extra</span>
            <div class="form-check form-switch">
              <input type="checkbox"
                class="form-check-input"
                id="registrationFeeTolariaPayment"
                [disabled]="!event.details.registrationFee.active || !event.details.registrationFee.tolariaPayment || disabledAfterStart"
                [(ngModel)]="event.details.registrationFee.charityExtra"
                (ngModelChange)="onPropertyChanged('details.registrationFee.charityExtra')">
              <label class="form-check-label" for="registrationFeeCharityExtra"></label>
            </div>
          </div>
          <span class="d-block text-small text-secondary">
            Attendees may add any extra amount when paying through the built-in Tolaria payment solution.<br>
            The amount will be transferred to your connected account and for the player this will be marked
            as not refundable.
          </span>
        </div>
      </div>

    </div>

  </ng-template>

  <ng-template #howToBecomeConnected>
    <div class="mb-3 mt-4 ms-5 p-3 rounded border border-warning text-warning">
      It's not possible to enable the registration fee option unless you are enrolled as a Stripe connected account.
      When enrolled, you will be able to receive payments and perform payouts to your bank account.
      This also enables all the manual payment tracking that Tolaria.app provides.
      <br>
      To start the enrollment process, head over to your settings and select <span class="text-orange">Payments</span>.
    </div>
  </ng-template>

  <ng-template #eventStructure>
    <div class="my-3 p-3 bg-body rounded shadow-sm" id="wrapper-Structure">
      <div class="mb-3 text-larger">Structure</div>

      <div class="mb-3">
        <label class="form-label" for="eventStructure">Structure</label>
        <select class="form-select"
          id="eventStructure"
          [disabled]="selectedStructure === null || disabledAfterStart"
          [(ngModel)]="selectedStructureName"
          (ngModelChange)="onStructureChanged()">
          <option [value]="structure" *ngFor="let structure of structures">{{ structure }}</option>
        </select>

        <div class="mt-2 p-3 text-secondary text-small" *ngIf="selectedStructure !== null">
          {{ selectedStructure.description }}
        </div>

      </div>


      <ng-container [ngSwitch]="selectedStructure.name">
        <ng-container *ngSwitchCase="'Swiss'"       [ngTemplateOutlet]="configSwiss"></ng-container>
        <ng-container *ngSwitchCase="'Group'"       [ngTemplateOutlet]="configGroup"></ng-container>
        <ng-container *ngSwitchCase="'Batch'"       [ngTemplateOutlet]="configBatch"></ng-container>
        <ng-container *ngSwitchCase="'Bracket'"     [ngTemplateOutlet]="configBracket"></ng-container>
        <ng-container *ngSwitchCase="'Round Robin'" [ngTemplateOutlet]="configRoundRobin"></ng-container>
      </ng-container>




      <div class="row">

        <div class="mb-3 col" [style.minWidth.px]="300">
          <label class="form-label" for="eventLocation">Type</label>
          <select class="form-select mb-3"
            [(ngModel)]="event.details.type"
            [disabled]="disabledAfterStart"
            (ngModelChange)="onPropertyChanged('details.type')">
            <option value="Constructed">Constructed</option>
            <option value="Limited">Limited</option>
          </select>
        </div>

        <div class="mb-3 col" [style.minWidth.px]="300">
          <label class="form-label" for="eventLocation">Format</label>
          <select class="form-select mb-3"
          [disabled]="formats.length === 0 || disabledAfterStart"
          [(ngModel)]="event.details.format"
          (ngModelChange)="onPropertyChanged('details.format')">
            <option value="1" *ngFor="let format of formats" [value]="format.name">{{ format.name }}</option>
          </select>
        </div>

      </div>

      <div class="row">

        <div class="mb-3 col" [style.minWidth.px]="300">
          <label class="form-label" [ngClass]="{ 'text-secondary' : rulesets.length === 0 || disabledAfterStart }" for="eventLocation">Ruleset</label>
          <select class="form-select mb-3"
            [disabled]="rulesets.length === 0 || disabledAfterStart"
            [compareWith]="byName"
            [(ngModel)]="event.details.ruleset"
            (ngModelChange)="onPropertyChanged('details.ruleset')">
            <option [ngValue]="ruleset" *ngFor="let ruleset of rulesets">{{ ruleset.name }}</option>
          </select>
        </div>

        <div class="mb-3 col" [style.minWidth.px]="300">
          <label class="form-label" [ngClass]="{ 'text-secondary' : reprintPolicies.length === 0 || disabledAfterStart }" for="eventLocation">Reprint policy</label>
          <select class="form-select mb-3"
            [disabled]="reprintPolicies.length === 0 || disabledAfterStart"
            [compareWith]="byName"
            [(ngModel)]="event.details.reprintPolicy"
            (ngModelChange)="onPropertyChanged('details.reprintPolicy')">
            <option [ngValue]="policy" *ngFor="let policy of reprintPolicies">{{ policy.name }}</option>
          </select>
        </div>

      </div>

    </div>
  </ng-template>

  <ng-template #eventSettings>
    <div class="my-3 p-3 bg-body rounded shadow-sm" id="wrapper-Settings">
      <div class="mb-3 text-larger">Settings</div>

      <ng-container *ngIf="settings$ | async as settings">
        <div class="d-flex pt-3"
          *ngFor="let item of settings; let i = index; trackBy : trackSettingsBy"
          [ngClass]="{ 'text-secondary' : !item.enabled }">
          <div class="bd-placeholder-img rounded me-3" [style.minWidth.px]="32" [style.minHeight.px]="32">
            <ng-container *ngIf="item.icon !== null">
              <fa-icon [icon]="item.icon" [fixedWidth]="true" class="text-larger"></fa-icon>
            </ng-container>
          </div>
          <div class="flex-grow-1 pb-3 mb-0 small lh-125 border-bottom border-secondary border-gray">
            <div class="d-flex justify-content-between align-items-center w-100">
              <span>{{ item.title }}</span>
              <div class="form-check form-switch">
                <ng-container [ngSwitch]="item.dataType">
                  <ng-container *ngSwitchCase="'boolean'">
                    <input type="checkbox"
                      class="form-check-input"
                      [id]="'setting-' + item.property"
                      [(ngModel)]="item.value"
                      [disabled]="!item.enabled"
                      (change)="onSettingChanged(item)">
                      <label class="form-check-label" [for]="'setting-' + item.property"></label>
                    </ng-container>
                    <ng-container *ngSwitchCase="'number'">
                      <input type="number"
                      class="form-control"
                      [id]="'setting-' + item.property"
                      [min]="item.min"
                      [style.maxWidth.rem]="5"
                      [(ngModel)]="item.value"
                      [disabled]="!item.enabled"
                      (change)="onSettingChanged(item)">
                  </ng-container>
                </ng-container>
              </div>
            </div>
            <span class="d-block text-small text-secondary" [innerHTML]="item.description"></span>
          </div>
        </div>
      </ng-container>
    </div>
  </ng-template>

  <ng-template #configSwiss>
    <div class="p-3">

      <div class="d-flex mb-3">
        <div class="bd-placeholder-img rounded me-3" [style.minWidth.px]="32" [style.minHeight.px]="32">
          <fa-icon [icon]="playoff" [fixedWidth]="true" class="text-larger"></fa-icon>
        </div>
        <div class="flex-grow-1 pb-3 mb-0 small lh-125 border-bottom border-secondary border-gray">
          <div class="d-flex justify-content-between align-items-center w-100">
            <span class="text-gray-dark">Playoff after swiss</span>
            <div class="form-check form-switch">
              <input type="checkbox"
                class="form-check-input"
                id="swissPlayoff"
                [disabled]="disabledAfterStart"
                [(ngModel)]="event.details.structure.swiss.hasBracketAfterSwiss"
                (ngModelChange)="onPropertyChanged('details.structure.swiss.hasBracketAfterSwiss')">
              <label class="form-check-label" for="swissPlayoff"></label>
            </div>
          </div>
          <span class="d-block text-small text-secondary">
            After the swiss has finished, you will be able to configure the single elimination bracket for the playoff.<br>
            You will freely decide the size of the bracket and will be able to change seeding if needed.</span>
        </div>
      </div>

      <div class="d-flex mb-3">
        <div class="bd-placeholder-img rounded me-3" [style.minWidth.px]="32" [style.minHeight.px]="32">
          <fa-icon [icon]="standings" [fixedWidth]="true" class="text-larger"></fa-icon>
        </div>
        <div class="flex-grow-1 pb-3 mb-0 small lh-125 border-bottom border-secondary border-gray">
          <div class="d-flex justify-content-between align-items-center w-100">
            <span class="text-gray-dark">Hide standings until posted</span>
            <div class="form-check form-switch">
              <input type="checkbox"
                class="form-check-input"
                id="swissStandingsPriorLastRound"
                [disabled]="disabledAfterStart"
                [(ngModel)]="event.details.structure.swiss.hideStandingsUntilPosted"
                (ngModelChange)="onPropertyChanged('details.structure.swiss.hideStandingsUntilPosted')">
              <label class="form-check-label" for="swissStandingsPriorLastRound"></label>
            </div>
          </div>
          <span class="d-block text-small text-secondary">
            When you end the next last swiss round of a swiss tournament, the standings will automatically be posted.<br>
            Enable this feature if you want the standings to be hidden until you decide to make it visible.</span>
        </div>
      </div>

      <div class="d-flex mb-3 pb-3">
        <div class="bd-placeholder-img rounded me-3" [style.minWidth.px]="32" [style.minHeight.px]="32">
          <fa-icon [icon]="teams" [fixedWidth]="true" class="text-larger"></fa-icon>
        </div>
        <div class="flex-grow-1 pb-3 mb-0 small lh-125 border-bottom border-secondary border-gray">
          <div class="d-flex justify-content-between align-items-center w-100">
            <span class="text-gray-dark">Teams</span>
            <div class="form-check form-switch">
              <input type="checkbox"
                class="form-check-input"
                id="swissTeams"
                [disabled]="disabledAfterStart"
                [(ngModel)]="event.details.structure.swiss.teams"
                (ngModelChange)="onPropertyChanged('details.structure.swiss.teams')">
              <label class="form-check-label" for="swissTeams"></label>
            </div>
          </div>
          <span class="d-block text-small text-secondary">
            By selecting this option you will pair up players as teams of three players. Each team need a name and three players.<br>
            The players will be seated to a specific seat (A/B/C) and when pairings run, teams will be matched against each other<br>
            and the players will be paired with the player of the opposing team with the same seat.
          </span>
        </div>
      </div>

    </div>
  </ng-template>

  <ng-template #configGroup>
    <div class="p-3">
      <div class="d-flex mb-3">
        <div class="bd-placeholder-img rounded me-3" [style.minWidth.px]="32" [style.minHeight.px]="32">
          <fa-icon [icon]="playoff" [fixedWidth]="true" class="text-larger"></fa-icon>
        </div>
        <div class="flex-grow-1 pb-3 mb-0 small lh-125 border-bottom border-secondary border-gray">
          <div class="d-flex justify-content-between align-items-center w-100">
            <span class="text-gray-dark">Playoff after group stage</span>
            <div class="form-check form-switch">
              <input type="checkbox"
                class="form-check-input"
                id="groupPlayoff"
                [disabled]="disabledAfterStart"
                [(ngModel)]="event.details.structure.group.hasBracketAfterGroupStage"
                (ngModelChange)="onPropertyChanged('details.structure.group.hasBracketAfterGroupStage')">
              <label class="form-check-label" for="groupPlayoff"></label>
            </div>
          </div>
          <span class="d-block text-small text-secondary">
            After the group stage has finished, you will be able to configure the single elimination bracket for the playoff.<br>
            You will freely decide the size of the bracket and will be able to change seeding if needed.</span>
        </div>
      </div>
    </div>
  </ng-template>

  <ng-template #configBatch>
    <div class="p-3">
      <div class="d-flex mb-3">
        <div class="bd-placeholder-img rounded me-3" [style.minWidth.px]="32" [style.minHeight.px]="32">
          <fa-icon [icon]="playoff" [fixedWidth]="true" class="text-larger"></fa-icon>
        </div>
        <div class="flex-grow-1 pb-3 mb-0 small lh-125 border-bottom border-secondary border-gray">
          <div class="d-flex justify-content-between align-items-center w-100">
            <span class="text-gray-dark">Playoff after batch</span>
            <div class="form-check form-switch">
              <input type="checkbox"
                class="form-check-input"
                id="batchPlayoff"
                [disabled]="disabledAfterStart"
                [(ngModel)]="event.details.structure.batch.hasBracketAfterBatch"
                (ngModelChange)="onPropertyChanged('details.structure.batch.hasBracketAfterBatch')">
              <label class="form-check-label" for="batchPlayoff"></label>
            </div>
          </div>
          <span class="d-block text-small text-secondary">
            After the batches are finished, you will be able to configure the single elimination bracket for the playoff.<br>
            You will freely decide the size of the bracket and will be able to change seeding if needed.</span>
        </div>
      </div>
    </div>
  </ng-template>

  <ng-template #configRoundRobin>
  </ng-template>

  <ng-template #configBracket>
    <div class="p-3">
      <div class="d-flex mb-3">
        <div class="bd-placeholder-img rounded me-3" [style.minWidth.px]="32" [style.minHeight.px]="32">
          <fa-icon [icon]="singleElim" [fixedWidth]="true" class="text-larger"></fa-icon>
        </div>
        <div class="flex-grow-1 pb-3 mb-0 small lh-125 border-bottom border-secondary border-gray">
          <div class="d-flex justify-content-between align-items-center w-100">
            <span class="text-gray-dark">Single elimination bracket</span>
            <div class="custom-control custom-radio">
              <input type="radio"
                class="form-check-input"
                id="bracketSingleElimination"
                [disabled]="disabledAfterStart"
                [checked]="event.details.structure.bracket.singleElimination"
                (click)="event.details.structure.bracket.singleElimination = true;
                         event.details.structure.bracket.doubleElimination = false;
                         onPropertyChanged('details.structure.bracket.singleElimination')">
              <label class="form-check-label" for="bracketSingleElimination"></label>
            </div>
          </div>
          <span class="d-block text-small text-secondary">
            A single-elimination, knockout, or sudden death tournament is a type of elimination tournament where<br>
            the loser of each match-up is imd-flextely eliminated from the tournament. Each winner will play another<br>
            in the next round, until the final match-up, whose winner becomes the tournament champion.
          </span>
        </div>
      </div>
      <div class="d-flex mb-3">
        <div class="bd-placeholder-img rounded me-3" [style.minWidth.px]="32" [style.minHeight.px]="32">
          <fa-icon [icon]="doubleElim" [fixedWidth]="true" class="text-larger"></fa-icon>
        </div>
        <div class="flex-grow-1 pb-3 mb-0 small lh-125 border-bottom border-secondary border-gray">
          <div class="d-flex justify-content-between align-items-center w-100">
            <span class="text-gray-dark">Double elimination bracket</span>
            <div class="custom-control custom-radio">
              <input type="radio"
                class="form-check-input"
                id="bracketDoubleElimination"
                [disabled]="disabledAfterStart"
                [checked]="event.details.structure.bracket.doubleElimination"
                (click)="event.details.structure.bracket.doubleElimination = true;
                         event.details.structure.bracket.singleElimination = false;
                         onPropertyChanged('details.structure.bracket.doubleElimination')">
              <label class="form-check-label" for="bracketDoubleElimination"></label>
            </div>
          </div>
          <span class="d-block text-small text-secondary">
            A double-elimination tournament is a type of elimination tournament competition in which a participant<br>
            ceases to be eligible to win the tournament's championship upon having lost two games or matches.<br>
            It stands in contrast to a single-elimination tournament, in which only one defeat results in elimination.
          </span>
        </div>
      </div>
    </div>
  </ng-template>


</ng-container>
