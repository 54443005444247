<div class="card border-secondary border-opacity-50 rounded min-width-100 h-100 overflow-hidden d-flex flex-column" *ngIf="!fullpage; else content">
  <div class="no-hover border-bottom border-secondary border-opacity-50 p-2">
    <div class="text-larger text-center">News Feed</div>
  </div>
  <div class="no-hover p-0 flex-grow-1 overflow-x-hidden scrollable">
    <ng-template *ngTemplateOutlet="content"></ng-template>
  </div>
  <div class="card-footer">
    <div class="flex-grow-1 content-wrap d-flex flex-column gap-3">
      <div class="d-flex flex-row gap-1 mt-3">
        <div class="btn btn-outline-primary" [routerLink]="['/news']">
          More content, see the news section
        </div>
      </div>
      <div class="d-flex flex-column gap-1">
        <div class="feed-origin">
          Want to add you content here?
        </div>
        <div class="d-flex flex-row align-items-center gap-2">
          Contact <app-player-display-name playerDocId="d37c411c-2004-44ef-958d-c2781eea2429">
          </app-player-display-name>
        </div>
      </div>
    </div>
  </div>
</div>

<ng-template #content>

  <ng-container *ngIf="rssFeed$ | async as feeds; else loader">
    <ng-container *ngFor="let feed of feeds | newsFeed : numFeeds">
      <div class="d-flex flex-row align-items-center feed-item" (click)="showReader(feed)">

        <div class="flex-grow-0 logo me-3">
          <img [src]="feed.feedMeta.logoUrl">
        </div>

        <div class="flex-grow-1 content-wrap">
          <div class="feed-title flex-grow-1 mt-2">{{ feed.title }}</div>
          <div class="d-flex align-items-center">
            <div class="feed-origin">{{ feed.feedMeta.name }}</div>
            <div class="feed-type {{ feed.feedMeta.type }}">{{ feed.feedMeta.type }}</div>
          </div>
        </div>

      </div>
    </ng-container>
  </ng-container>

  <ng-template #loader>
    <div class="d-flex flex-column align-items-center m-5">
      <div class="d-flex flex-column align-items-center justify-content-center flex-grow-1">
        <div class="spinner-wrapper">
          <div class="lds-spinner">
            <div></div>
            <div></div>
            <div></div>
            <div></div>
            <div></div>
            <div></div>
            <div></div>
            <div></div>
            <div></div>
            <div></div>
            <div></div>
            <div></div>
          </div>
        </div>
        <div class="text-secondary">loading awesome old shcool content from the underground...</div>
      </div>
    </div>
  </ng-template>
</ng-template>

<ng-template #readerModal>
  <div class="modal-header">{{ reader.title }} &#64; {{ reader.feedMeta.name }}</div>
  <div class="modal-body" [innerHtml]="reader.content" *ngIf="!reader.isYoutube"></div>
  <div class="modal-body" *ngIf="reader.isYoutube">
    <div class="iframe-container">
      <iframe [src]="reader.embedUrl"
        frameborder="0"
        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
        allowfullscreen>
      </iframe>
    </div>
  </div>
  <div class="modal-footer text-start">
    <a [href]="reader.link" target="_blank" class="text-secondary" [innerHtml]="reader.link"></a>
  </div>
</ng-template>
