import { IEventTeamMeta } from "src/app/private/play/tournament/services/tournament-data-helper.service"
import { IEventPlayerDetails, IEventTeam } from "tolaria-cloud-functions/src/_interfaces"


export const RoundToFourDecimals = (num: number): number => {
  // @ts-expect-error
  return + (Math.round(num + 'e+5') + 'e-5')

}


export const Tiebreakers = {
  equal: (a: IEventPlayerDetails, b: IEventPlayerDetails) => {
    if (a.matchPoints === b.matchPoints) {
      if (a.opponentMatchWinPercentage === b.opponentMatchWinPercentage) {
        if (a.gameWinPercentage === b.gameWinPercentage) {
          if (a.opponentGameWinPercentage === b.opponentGameWinPercentage) return true
          else return 4
        } else return 3
      } else return 2
    } else return 1
  },
  diff: (a: IEventPlayerDetails, b: IEventPlayerDetails, n: number) =>
    n === 1 ? b.matchPoints - a.matchPoints :
      n === 2 ? b.opponentMatchWinPercentage - a.opponentMatchWinPercentage :
        n === 3 ? b.gameWinPercentage - a.gameWinPercentage : b.opponentGameWinPercentage - a.opponentGameWinPercentage
}

export const TeamTieBreakers = {
  /**
   * Computes tiebreakers for all tems in a tournament.
   * @param teams The list of teams
   * @internal
  */
  compute: (teams: IEventTeamMeta[]) => {

    // Compute game win percentage, match win percentage, and cumulative score for each player
    for (let team of teams) {

      // Redet tiebreakers to zero
      if (!team.hasOwnProperty('tiebreakers')) {
        Object.assign(team, {
          tiebreakers: {
            gameWinPct: 0,
            matchWinPct: 0,
            oppGameWinPct: 0,
            oppMatchWinPct: 0,
          }
        })
      }
      else {
        team.tiebreakers.gameWinPct = 0
        team.tiebreakers.matchWinPct = 0
        team.tiebreakers.oppGameWinPct = 0
        team.tiebreakers.oppMatchWinPct = 0
      }

      // Calculate cumulative score
      team.matchPoints = (team.matchesWon * 3) + (team.matchesDrawn * 1)
      team.gamePoints = (team.gamesWon * 3) + (team.gamesDrawn * 1)

      // Calculate game win percentage (number of game points divided by max game points possible)
      team.tiebreakers.gameWinPct = RoundToFourDecimals(team.gamePoints / (team.gamesPlayed * 3))

      // Calculate match win percentage (number of match points divided by max match points possible)
      team.tiebreakers.matchWinPct = RoundToFourDecimals(team.matchPoints / (team.matchesPlayed * 3))


      // Adjust tiebreakers if less than 0.33% (Match-Win-Percentage & Game-Win-Percentage)
      if (!team.tiebreakers.gameWinPct || team.tiebreakers.gameWinPct < 0.33) {
        team.tiebreakers.gameWinPct = 0.33
      }
      if (!team.tiebreakers.matchWinPct || team.tiebreakers.matchWinPct < 0.33) {
        team.tiebreakers.matchWinPct = 0.33
      }

    }

    // Calculate all remaining tiebreakers except for opponent's opponent match win percentage
    for (let team of teams) {

      // If the current player has played no matches, contine
      if (team.teamsPlayed.length === 0) continue

      // Get all opponents
      const opponents = teams.filter(opp => team.teamsPlayed.filter(i => i !== '*** BYE ***').some(result => result === opp.id))

      // Calculate opponent match win percentage
      team.tiebreakers.oppMatchWinPct = RoundToFourDecimals(opponents.reduce((sum, opponent) => sum + opponent.tiebreakers.matchWinPct, 0) / opponents.length)

      // Calculate opponent game win percentage
      team.tiebreakers.oppGameWinPct = RoundToFourDecimals(opponents.reduce((sum, opponent) => sum + opponent.tiebreakers.gameWinPct, 0) / opponents.length)

    }


    return teams


  },

  /**
   * Sorts teams by points and tiebreakers.
   * @param teams The array of teams being sorted.
   * @returns A sorted array of players.
   */
  sort: (teams: IEventTeamMeta[]): IEventTeamMeta[] => {

    const tiebreakers = [
      'opponent match win percentage',
      'game win percentage',
      'opponent game win percentage'
    ]

    return teams.sort((teamA, teamB) => {

      // If the players have different match points, sort them by match points
      if (teamA.matchPoints !== teamB.matchPoints) return teamB.matchPoints - teamA.matchPoints

      // Go through tiebreakers individually and return the first difference
      for (let i = 0; i < tiebreakers.length; i++) {
        switch (tiebreakers[i]) {
          case 'game win percentage':
            if (teamA.tiebreakers.gameWinPct === teamB.tiebreakers.gameWinPct) continue;
            else return teamB.tiebreakers.gameWinPct - teamA.tiebreakers.gameWinPct;
          case 'opponent game win percentage':
            if (teamA.tiebreakers.oppGameWinPct === teamB.tiebreakers.oppGameWinPct) continue;
            else return teamB.tiebreakers.oppGameWinPct - teamA.tiebreakers.oppGameWinPct;
          case 'opponent match win percentage':
            if (teamA.tiebreakers.oppMatchWinPct === teamB.tiebreakers.oppMatchWinPct) continue;
            else return teamB.tiebreakers.oppMatchWinPct - teamA.tiebreakers.oppMatchWinPct;
        }
      }

      // If all tiebreakers are equal, sort by ID
      return teamB.id < teamA.id ? 1 : -1;
    });

  },

  /**
   * Rank teams by points and tiebreakers.
   * @param teams The array of teams being ranked.
   * @returns An array of teams ranked by the regular tiebreakers.
   */
  rank: (teams: IEventTeamMeta[]): IEventTeamMeta[] => {

    let rank = 1
    let seed = 1
    for (const [i, team] of teams.entries()) {
      if (i > 0) {
        // Go through tiebreakers individually and if different, increase the rank
        if (team.tiebreakers.gameWinPct !== teams[i - 1].tiebreakers.gameWinPct) {
          rank++;
        }
        else if (team.tiebreakers.oppGameWinPct !== teams[i - 1].tiebreakers.oppGameWinPct) {
          rank++;
        }
        else if (team.tiebreakers.oppMatchWinPct !== teams[i - 1].tiebreakers.oppMatchWinPct) {
          rank++
        }
      }

      team.rank = rank
      team.seed = seed

      seed++
    }

    return teams

  }


}

export const PlayerTieBreakers = {
  /**
   * Computes tiebreakers for all players in a tournament.
   * @param players The list of players
   * @internal
  */
  compute: (players: IEventPlayerDetails[]) => {

    // Compute game win percentage, match win percentage, and cumulative score for each player
    for (let player of players) {

      // Reset tiebreakers to zero
      player.gameWinPercentage = 0
      player.matchWinPercentage = 0
      player.opponentGameWinPercentage = 0
      player.opponentMatchWinPercentage = 0

      // Calculate cumulative score
      player.matchPoints = (player.matchesWon * 3) + (player.matchesDrawn * 1)
      player.gamePoints = (player.gamesWon * 3) + (player.gamesDrawn * 1)

      // Calculate game win percentage (number of game points divided by max game points possible)
      player.gameWinPercentage = RoundToFourDecimals(player.gamePoints / (player.gamesPlayed * 3))

      // Calculate match win percentage (number of match points divided by max match points possible)
      player.matchWinPercentage = RoundToFourDecimals(player.matchPoints / (player.matchesPlayed * 3))


      // Adjust tiebreakers if less than 0.33% (Match-Win-Percentage & Game-Win-Percentage)
      if (!player.gameWinPercentage || player.gameWinPercentage < 0.33) {
        player.gameWinPercentage = 0.33
      }
      if (!player.matchWinPercentage || player.matchWinPercentage < 0.33) {
        player.matchWinPercentage = 0.33
      }

    }

    // Calculate all remaining tiebreakers except for opponent's opponent match win percentage
    for (const player of players) {

      // If the current player has played no matches, contine
      if (player.opponentsDocIds.length === 0) continue

      // Get all opponents
      const opponents = players.filter(opp => player.opponentsDocIds.filter(i => i !== '*** BYE ***').some(result => result === opp.playerDocId))

      // Calculate opponent match win percentage
      player.opponentMatchWinPercentage = RoundToFourDecimals(opponents.reduce((sum, opponent) => sum + opponent.matchWinPercentage, 0) / opponents.length)
      if (isNaN(player.opponentMatchWinPercentage)) {
        player.opponentMatchWinPercentage = 0
      }

      // Calculate opponent game win percentage
      player.opponentGameWinPercentage = RoundToFourDecimals(opponents.reduce((sum, opponent) => sum + opponent.gameWinPercentage, 0) / opponents.length)
      if (isNaN(player.opponentGameWinPercentage)) {
        player.opponentGameWinPercentage = 0
      }

    }


    return players


  },

  /**
   * Sorts players by points and tiebreakers.
   * @param players The array of players being sorted.
   * @returns A sorted array of players.
   */
  sort: (players: IEventPlayerDetails[]): IEventPlayerDetails[] => {

    const tiebreakers = [
      'playoff results',
      'match points',
      'opponent match win percentage',
      'game win percentage',
      'opponent game win percentage'
    ]

    return players.sort((playerA, playerB) => {

      // Go through tiebreakers individually and return the first difference
      for (let i = 0; i < tiebreakers.length; i++) {
        switch (tiebreakers[i]) {
          case 'playoff results':
            if (playerA.playoffMatchesPlayed === 0 && playerB.playoffMatchesPlayed === 0) {
              continue
            }
            else if (playerA.playoffMatchesPlayed > 0 || playerB.playoffMatchesPlayed > 0) {
              // player A has won more bracket matches
              if (playerA.playoffMatchesPlayed > playerB.playoffMatchesWon) {
                return -1
              }
              // player B has won more bracket matches
              else if (playerB.playoffMatchesPlayed > playerA.playoffMatchesWon) {
                return 1
              }
              // player A advanced further into the bracket than player B
              else if (playerA.playoffMatchesPlayed > 0 && playerA.playoffMatchesPlayed > playerB.playoffMatchesPlayed) {
                return -1
              }
              // player B advanced further into the bracket than player A
              else if (playerB.playoffMatchesPlayed > 0 && playerB.playoffMatchesPlayed > playerA.playoffMatchesPlayed) {
                return 1
              }
            }
          case 'match points':
            // If the players have different match points, sort them by match points
            if (playerA.matchPoints !== playerB.matchPoints) {
              return playerB.matchPoints - playerA.matchPoints
            }
          case 'opponent match win percentage':
            if (playerA.opponentMatchWinPercentage === playerB.opponentMatchWinPercentage) {
              continue
            }
            else {
              return playerB.opponentMatchWinPercentage - playerA.opponentMatchWinPercentage
            }
          case 'game win percentage':
            if (playerA.gameWinPercentage === playerB.gameWinPercentage) {
              continue
            }
            // need to make sure that pervious breaker does not become overruled
            else if (playerA.opponentMatchWinPercentage > playerB.opponentMatchWinPercentage) {
              return 0
            }
            else {
              return playerB.gameWinPercentage - playerA.gameWinPercentage;
            }
          case 'opponent game win percentage':
            if (playerA.opponentGameWinPercentage === playerB.opponentGameWinPercentage) {
              continue
            }
            // need to make sure that pervious breaker does not become overruled
            else if (playerA.gameWinPercentage > playerB.gameWinPercentage) {
              return 0
            }
            else {
              return playerB.opponentGameWinPercentage - playerA.opponentGameWinPercentage
            }
        }
      }

      // If all tiebreakers are equal, sort by ID
      return playerB.playerDocId < playerA.playerDocId ? 1 : -1;
    });

  },

  /**
   * Rank players by points and tiebreakers.
   * @param players The array of players being ranked.
   * @returns An array of players ranked by the regular tiebreakers.
   */
  rank: (players: IEventPlayerDetails[], groupRanking: boolean = false): IEventPlayerDetails[] => {

    let rank = 1
    let seed = 1
    for (const [i, player] of players.entries()) {
      if (i > 0) {
        // Go through tiebreakers individually and if different, increase the rank
        if (player.playoffMatchesPlayed > 0 && players[i -1].playoffMatchesPlayed > 0) {
          rank++
        }
        else if (player.gameWinPercentage !== players[i - 1].gameWinPercentage) {
          rank++;
        }
        else if (player.opponentGameWinPercentage !== players[i - 1].opponentGameWinPercentage) {
          rank++;
        }
        else if (player.opponentMatchWinPercentage !== players[i - 1].opponentMatchWinPercentage) {
          rank++
        }
      }

      player.rank = rank
      player.seed = seed

      seed++
    }


    if (groupRanking) {

      for (const group of [...new Set(players.map(i => i.playedInGroup))]) {

        let rank = 1

        for (const [i, player] of players.filter(x => x.playedInGroup === group).entries()) {
          if (i > 0) {
            // Go through tiebreakers individually and if different, increase the rank
            if (player.gameWinPercentage !== players[i - 1].gameWinPercentage) {
              rank++;
            }
            else if (player.opponentGameWinPercentage !== players[i - 1].opponentGameWinPercentage) {
              rank++;
            }
            else if (player.opponentMatchWinPercentage !== players[i - 1].opponentMatchWinPercentage) {
              rank++
            }
          }

          player.groupRank = rank
        }

      }

    }

    return players

  }


}
