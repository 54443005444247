import { Injectable } from '@angular/core';
import { ILeagueDocument, ILeagueManualPoint, IPlayerMini, LeaguePointType } from 'tolaria-cloud-functions/src/_interfaces';
import * as firestore from 'firebase/firestore'
import { AngularFirestore } from '@angular/fire/compat/firestore';
import { ToastService } from 'src/app/services/toast.service';

@Injectable({
  providedIn: 'root'
})
export class LeagueConfigService {

constructor(
  private readonly firestore: AngularFirestore,
  private readonly toast: ToastService,
) { }

public create(document: ILeagueDocument): Promise<boolean> {
  return new Promise((resolve, reject) => {
    document.createdTimestamp = firestore.Timestamp.now().seconds
    this.firestore
      .collection('leagues')
      .doc(document.docId)
      .set(document)
      .then(() => resolve(true))
      .catch((error) => {
        console.error('LeagueConfigService:: error creating league', error)
        reject(error)
      })
  })
}

public update(document: ILeagueDocument): Promise<boolean> {
  return new Promise((resolve, reject) => {
    this.firestore
      .collection('leagues')
      .doc(document.docId)
      .set(document)
      .then(() => {
        this.toast.show('Changes to League saved', { classname: 'success-toast', delay: 5000 })
        resolve(true)
      })
      .catch((error) => {
        this.toast.show(`Changes to League NOT saved -> ${error}`, { classname: 'error-toast', delay: 8000 })
        console.error('LeagueConfigService:: error updating league', error)
        reject(error)
      })
  })
}

public awardPoint(document: ILeagueDocument, point: ILeagueManualPoint): Promise<boolean> {
  return new Promise((resolve, reject) => {
    this.firestore
      .collection('leagues')
      .doc(document.docId)
      .update({
        manualPoints: firestore.arrayUnion(point)
      })
      .then(() => {
        this.toast.show('Custom league point awarded', { classname: 'success-toast', delay: 5000 })
        resolve(true)
      })
      .catch((error) => {
        this.toast.show(`Custom league point NOT saved -> ${error}`, { classname: 'error-toast', delay: 8000 })
        console.error('LeagueConfigService:: error updating league', error)
        reject(error)
      })
  })
}

public transfer(document: ILeagueDocument, newOwner: IPlayerMini): Promise<boolean> {
  document.createdByUid = newOwner.uid
  document.createdByPlayerDocId = newOwner.id
  return new Promise((resolve, reject) => {
    this.firestore
      .collection('leagues')
      .doc(document.docId)
      .set(document)
      .then(() => {
        this.toast.show('League archived', { classname: 'success-toast', delay: 5000 })
        resolve(true)
      })
      .catch((error) => {
        this.toast.show(`League NOT archived -> ${error}`, { classname: 'error-toast', delay: 8000 })
        console.error('LeagueConfigService:: error archiving the league ->', error)
        reject(error)
      })
  })
}

public archive(document: ILeagueDocument): Promise<boolean> {
  document.archived = true
  return new Promise((resolve, reject) => {
    this.firestore
      .collection('leagues')
      .doc(document.docId)
      .set(document)
      .then(() => {
        this.toast.show('League archived', { classname: 'success-toast', delay: 5000 })
        resolve(true)
      })
      .catch((error) => {
        this.toast.show(`League NOT archived -> ${error}`, { classname: 'error-toast', delay: 8000 })
        console.error('LeagueConfigService:: error archiving the league ->', error)
        reject(error)
      })
  })
}

public delete(document: ILeagueDocument): Promise<boolean> {
  return new Promise((resolve, reject) => {
    this.firestore
      .collection('leagues')
      .doc(document.docId)
      .delete()
      .then(() => {
        this.toast.show('League deleted', { classname: 'success-toast', delay: 5000 })
        resolve(true)
      })
      .catch((error) => {
        this.toast.show(`League NOT deleted -> ${error}`, { classname: 'error-toast', delay: 8000 })
        console.error('LeagueConfigService:: error deleting league ->', error)
        reject(error)
      })
  })
}

public get translations() {
    return {
      [LeaguePointType.RANK]: {
        text: 'Ranking',
        tooltip: 'Reward players with points according to their final standings in connected tournaments',
        explaination: 'Received according to your ranking in an event'
      },
      [LeaguePointType.BRACKET_WIN]: {
        text: 'Bracket Win',
        tooltip: 'Reward players with points for every match win of type bracket in connected tournaments (playoffs)',
        explaination: 'Received for every match win in the playoffs of an event'
      },
      [LeaguePointType.MATCH_WIN]: {
        text: 'Match Win',
        tooltip: 'Reward playes with points for every match wins in connected tournaments',
        explaination: 'Received for every match win during an event'
      },
      [LeaguePointType.GAME_WIN]: {
        text: 'Game Win',
        tooltip: 'Reward players with points for every game wins in connected tournaments',
        explaination: 'Received for every game win during an event'
      },
      [LeaguePointType.MATCH_FULFILMENT]: {
        text: 'Match Fulfilment',
        tooltip: 'Reward players with points for finalizing all matches in connected tournaments (used for group stages)',
        explaination: 'Received if you finish all your group matches in an event'
      },
      [LeaguePointType.CUSTOM]: {
        text: 'Custom Point',
        tooltip: 'Reward players with points for custom achivements. Points are added manually!',
        explaination: 'Added manually by the tournament organizer',
      }
    }
  }

}
