import { CommonModule } from '@angular/common';
import { ChangeDetectionStrategy, Component, ElementRef, Input, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { NgbActiveModal, NgbTypeahead, NgbTypeaheadModule, NgbTypeaheadSelectItemEvent } from '@ng-bootstrap/ng-bootstrap';
import { BehaviorSubject, Observable, OperatorFunction, Subject, debounceTime, distinctUntilChanged, filter, map, merge, takeUntil } from 'rxjs';
import { IEventPlayerMeta, IEventTeamMeta, TournamentDataHelperService } from 'src/app/private/play/tournament/services/tournament-data-helper.service';

@Component({
  selector: 'app-tournament-management-team-config',
  standalone: true,
  imports: [
    CommonModule,
    FormsModule,
    NgbTypeaheadModule,
  ],
  templateUrl: './tournament-management-team-config.component.html',
  styleUrls: ['./tournament-management-team-config.component.css'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class TournamentManagementTeamConfigComponent implements OnInit, OnDestroy {
  @ViewChild('instance', { static: false }) instance: NgbTypeahead
  @ViewChild('searchElement', { static: false }) searchElement: ElementRef
  @Input() set team(val: IEventTeamMeta) {
    this._team = val
    console.log(val)
    this.team$.next(JSON.parse(JSON.stringify(val)))
  }


  private destroyed$ = new Subject<boolean>()
  private _team: IEventTeamMeta
  private unassignedPlayers: IEventPlayerMeta[] = []
  public team$ = new BehaviorSubject<IEventTeamMeta>(null)
  public players$ = new BehaviorSubject<Player[]>(null)
  public selectedPlayer: { name: string, id: string } = null
  public playerSearch: string = ''

  public focus$ = new Subject<string>()
	public click$ = new Subject<string>()
  search: OperatorFunction<string, readonly IEventPlayerMeta[]> = (text$: Observable<string>) => {
    const debouncedText$ = text$.pipe(debounceTime(200), distinctUntilChanged())
		const clicksWithClosedPopup$ = this.click$.pipe(filter(() => !this.instance.isPopupOpen()))
		const inputFocus$ = this.focus$
    return merge(debouncedText$, inputFocus$, clicksWithClosedPopup$).pipe(
      debounceTime(200),
      distinctUntilChanged(),
      map((term) => this.unassignedPlayers
        .filter(i => !this.team$.getValue().playerDocIds.includes(i.eventPlayer.playerDocId))
        .filter((v) => v.eventPlayer.name.toLowerCase().indexOf(term.toLowerCase()) > -1)
        .slice(0, 10)
      ),
    )
  }
  public formatter = (x: IEventPlayerMeta) => x.eventPlayer
    ? x.eventPlayer.name
      ? x.eventPlayer.name
      : ''
    : ''

  constructor(
    private readonly helper: TournamentDataHelperService,
    private readonly modal: NgbActiveModal,
  ) { }

  ngOnInit(): void {
    this.team$.pipe(takeUntil(this.destroyed$)).subscribe(i => this.setPlayers())
    this.unassignedPlayers = this.helper.players$.getValue().filter(i => !i.info.inTeam)
  }

  ngOnDestroy(): void {
    this.destroyed$.next(true)
  }

  public outputSelected(event?: NgbTypeaheadSelectItemEvent<IEventPlayerMeta>): void {
    if (event) {
      const team = this.team$.getValue()
      team.playerDocIds.push(event.item.eventPlayer.playerDocId)
      team.players.push({
        name: event.item.eventPlayer.name,
        id: event.item.eventPlayer.playerDocId,
      })
      this.team$.next(team)
      setTimeout(() => {
        this.playerSearch = ''
        this.searchElement.nativeElement.blur()
      }, 150)
    }
  }

  public onDismiss(): void {
    this.modal.dismiss()
  }

  public onClose(): void {
    this.modal.close(this.team$.getValue())
  }

  public onSelectPlayer(player: Player): void {
    const players = this.players$.getValue()
    players.forEach(i => i.selected = i.id === player.id)
    this.players$.next(players)
    this.selectedPlayer = player
  }

  public onSeatSelected(seat: Seat): void {

    const playerCopy = JSON.parse(JSON.stringify(this.selectedPlayer))
    this.selectedPlayer = null
    const team = this.team$.getValue()
    team.seated[seat.seat] = {
      filled: true,
      name: playerCopy.name,
      id: playerCopy.id,
      isTemp: false,
    }
    team.player[seat.seat] = playerCopy.id
    this.team$.next(team)
    if (playerCopy.seatedAt !== null && playerCopy.seatedAt !== '') {
      this.onClearSeat(playerCopy.seatedAt)
    }
    this.setPlayers()
  }

  public onClearSeat(seat: string): void {
    const team = this.team$.getValue()
    team.seated[seat] = {
      filled: false,
      name: null,
      id: null,
      isTemp: null,
    }
    team.player[seat] = null
    this.team$.next(team)
  }

  public onUnassignPlayer(player: Player): void {
    // get the team
    let team = this.team$.getValue()
    // update player list of the team doc by removing the unassigned player
    team.players = team.players.filter(i => i.id !== player.id)
    // update player doc id list of the team doc by removing the unassigned player
    team.playerDocIds = team.playerDocIds.filter(i => i !== player.id)
    // check if player was seated and update seating information
    if (player.isSeated) {
      team.player[player.seatedAt] = null
      team.seated[player.seatedAt] = {
        filled: false,
        name: null,
        id: null,
        isTemp: false,
      }
    }
    // update behavior subject with new data
    this.team$.next(team)
    // add the player to the unassigned players array to be able to add it again
    this.unassignedPlayers.push(this.helper.players$.getValue().find(i => i.eventPlayer.playerDocId === player.id))
  }

  private setPlayers(): Player[] {
    let team = this.team$.getValue()
    if (team === null) {
      return []
    }

    let players: Player[] = []
    for (let player of team.players) {
      players.push({
        name: player.name,
        id: player.id,
        selected: false,
        // seatedAt: this.seats.find(i => i.playerId === player.id) ? this.seats.find(i => i.playerId === player.id).seat : null,
        // isSeated: this.seats.find(i => i.playerId === player.id) !== undefined,
        // seatLetter: this.seats.find(i => i.playerId === player.id) ? this.seats.find(i => i.playerId === player.id).seat.toUpperCase() : null,
        seatedAt: team.player.a === player.id
          ? 'a'
          : team.player.b === player.id
            ? 'b'
            : team.player.c === player.id
              ? 'c'
              : '',
        isSeated: team.player.a === player.id || team.player.b === player.id || team.player.c === player.id,
        seatLetter: team.player.a === player.id
        ? 'A'
        : team.player.b === player.id
          ? 'B'
          : team.player.c === player.id
            ? 'C'
            : '',
      })
    }

    this.players$.next(players)
  }

  public get seats(): Seat[] {
    let team = this.team$.getValue()
    if (team === null) {
      return []
    }

    let seats: Seat[] = []
    for (const [seat, data] of Object.entries(team.seated)) {
      seats.push({
        seat: seat,
        letter: seat.toUpperCase(),
        playerId: data.id,
        playerName: data.name,
        occupied: data.filled,
      })
    }

    return seats

  }

  public get disableSave(): boolean {
    return JSON.stringify(this._team) === JSON.stringify(this.team$.getValue())
  }

  public get teamIsFull(): boolean {
    if (this.players$.getValue() !== null) {
      return this.players$.getValue().length === 3
    }
    return true
  }


}

interface Player {
  name: string
  id: string
  selected: boolean
  seatedAt: string
  isSeated: boolean
  seatLetter: string
}

interface Seat {
  seat: string
  letter: string
  playerId: string
  playerName: string
  occupied: boolean
}
