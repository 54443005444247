import { IRegistrationFeePayment, ITicketMeta } from 'tolaria-cloud-functions/src/_interfaces'
import { RegistrationFeeDetailsComponent } from './registration-fee-details/registration-fee-details.component'
import { Component, ElementRef, Input, OnDestroy, ViewChild } from '@angular/core'
import { faCamera, faEllipsisV, IconDefinition, faFileAlt, faCoins, faUserCheck, faReply, faTicketAlt, faPeopleGroup } from '@fortawesome/free-solid-svg-icons'
import { IEventDetails } from 'tolaria-cloud-functions/src/_interfaces'
import { BehaviorSubject, combineLatest, Subject, takeUntil } from 'rxjs'
import { ToastService } from 'src/app/services/toast.service'
import { NgbModal, NgbModalOptions, NgbModalRef } from '@ng-bootstrap/ng-bootstrap'
import { DeckPhotoComponent } from 'src/app/components/decks/deck-photo/deck-photo.component'
import { DeckListComponent } from 'src/app/components/decks/deck-list/deck-list.component'
import { RegisterEventPaymentComponent } from 'src/app/payment/register-event-payment/register-event-payment.component'
import { IssueRefundRegistrationFeeComponent } from './issue-refund-registration-fee/issue-refund-registration-fee.component'
import { TournamentManagementPlayersService } from 'src/app/private/play/tournament/services/tournament-management-players.service';
import { IEventPlayerMeta, TournamentDataHelperService } from 'src/app/private/play/tournament/services/tournament-data-helper.service'
import { MessageModalService } from 'src/app/components/modals/message-modal/message-modal.service'
import { TournamentManagementFilteringService } from 'src/app/private/play/tournament/services/tournament-management-filtering.service'

interface IPlayerFlags {
  icon: IconDefinition
  state: boolean
  tooltip: string
  action: any
  class: string
}

@Component({
  selector: 'app-tournament-management-attending-player',
  templateUrl: './player-row.component.html',
  styles: [`
  .btn-blank.no-arrow:after {
    display: none
  }
  .hover-highligt:hover {
    background-color: rgba(var(--bs-primary-rgb), 0.125);
  }
`]
})
export class PlayerRowComponent implements OnDestroy {
  @Input() set data(val: IEventPlayerMeta) { this.player$.next(val) }
  @Input() set event(val: IEventDetails) { this.event$.next(val) }
  @Input() last: boolean

  @ViewChild('confirmRefunding', { static: false }) confirmRefunding: ElementRef

  public player$: BehaviorSubject<IEventPlayerMeta> = new BehaviorSubject<IEventPlayerMeta>(null)
  public event$: BehaviorSubject<IEventDetails> = new BehaviorSubject<IEventDetails>(null)
  public flags$: BehaviorSubject<IPlayerFlags[]> = new BehaviorSubject<IPlayerFlags[]>(null)
  public actions = faEllipsisV
  public teamIcon = faPeopleGroup

  private destroyed$: Subject<boolean> = new Subject<boolean>()

  constructor(
    private readonly helper: TournamentDataHelperService,
    private readonly player: TournamentManagementPlayersService,
    private readonly filter: TournamentManagementFilteringService,
    private readonly toast: ToastService,
    private readonly modalService: NgbModal,
    private readonly message: MessageModalService,
  ) {
    combineLatest([this.event$, this.player$]).pipe(takeUntil(this.destroyed$)).subscribe(([event, player]) => {
      if (event !== null && player !== null && player.eventPlayer !== null) {
        this.initFlags(event, player)
      }
    })
  }

  ngOnDestroy(): void {
    this.destroyed$.next(true)
  }


  public action = {

    unattend: () => {

      this.message.open({
        type: 'warning',
        title: 'Unattend player?',
        message: `Are you sure you want to unattend <strong>${this.player$.getValue().eventPlayer.name}</strong> from the event?`,
        buttons: [
          {
            type: 'dismiss',
            text: 'Cancel',
            value: '',
          },
          {
            type: 'close',
            text: 'Unattend',
            value: 'unattend',
            class: 'btn-blank text-warning'
          },
        ]
      }).then(response => {
        if (response !== null) {
          this.player.removePlayer([this.player$.getValue().eventPlayer], this.eventDocId)
            .then((res) => this.toast.show(res.text, { classname: res.status ? 'success-toast' : 'error-toast' }))
        }
      })

    },
    moveToWaitList: () => {
      this.player.removePlayer([this.player$.getValue().eventPlayer], this.eventDocId)
      this.player.addPlayerToWaitingList([this.playerDocId], this.eventDocId, this.playerDocId.includes('temp_'))
    },
    toggleDropped: () => {
      switch (this.helper.eventType) {
        case 'group':
          this.player.dropAndMore(this.player$.getValue())
          break
        default:
          this.player.setPlayerDroppedState(!this.hasDropped, this.eventDocId, [this.playerDocId])
      }
    },
    toggleCheckIn: () => {
      this.player.setPlayerCheckInState(!this.hasCheckedIn, this.eventDocId, [this.playerDocId])
        .then((res) => this.toast.show(res.text, { classname: res.status ? 'success-toast' : 'error-toast' }))
    },
    registerManualPayment: () => {
      const modalOptions: NgbModalOptions = {
        centered: false,
        animation: true,
        backdrop: true,
        keyboard: true,
        size: 'lg',
      }
      const modalRef: NgbModalRef = this.modalService.open(RegisterEventPaymentComponent, modalOptions)
      modalRef.componentInstance.event = this.event$.getValue()
      modalRef.componentInstance.player = this.player$.getValue().eventPlayer
      modalRef.componentInstance.showAsModal = true
    },
    paymentDetails: () => {
      if (this.paymentDetails !== null) {
        const modalOptions: NgbModalOptions = {
          centered: false,
          animation: true,
          backdrop: true,
          keyboard: true,
          size: 'md',
        }
        const modalRef: NgbModalRef = this.modalService.open(RegistrationFeeDetailsComponent, modalOptions)
        modalRef.componentInstance.paymentDetails = this.paymentDetails
      }
    },
    issueRefund: () => {
      const modalOptions: NgbModalOptions = {
        centered: false,
        animation: true,
        backdrop: true,
        keyboard: true,
        size: 'lg',
      }
      const modalRef: NgbModalRef = this.modalService.open(IssueRefundRegistrationFeeComponent, modalOptions)
      modalRef.componentInstance.eventData = this.event$.getValue()
      modalRef.componentInstance.playerData = this.player$.getValue().eventPlayer
      modalRef
        .result
        .then(
          (success: boolean) => { },
          () => { }
        )
    },
    showDeckList: () => {
      const modalOptions: NgbModalOptions = {
        animation: true,
        keyboard: true,
        fullscreen: true,
      }
      const modalRef: NgbModalRef = this.modalService.open(DeckListComponent, modalOptions)
      modalRef.componentInstance.showAsModal = true
      modalRef.componentInstance.deckListDocId = this.player$.getValue().eventPlayer.deckSubmission.deckListDocId
      modalRef.componentInstance.deckListVersionDocId = this.player$.getValue().eventPlayer.deckSubmission.deckVersionDocId
    },
    showDeckPhoto: () => {
      const modalOptions: NgbModalOptions = {
        animation: true,
        keyboard: true,
        fullscreen: true,
      }
      const modalRef: NgbModalRef = this.modalService.open(DeckPhotoComponent, modalOptions)
      modalRef.componentInstance.showAsModal = true
      modalRef.componentInstance.deckListDocId = this.player$.getValue().eventPlayer.deckSubmission.deckListDocId
      modalRef.componentInstance.deckListVersionDocId = this.player$.getValue().eventPlayer.deckSubmission.deckVersionDocId
    },
    addWarning: () => {

    },

    openTeamConfig: () => {
      this.player.openPlayerTeamConfig(this.player$.getValue().eventPlayer)
    }

  }


  public get eventDocId(): string {
    if (this.event$.getValue() !== null) {
      return this.event$.getValue().docId
    }
    return ''
  }


  public get isTeamTournament(): boolean {
    return this.helper.isTeamTournament
  }


  public get playerDocId(): string {
    if (this.player$.getValue() !== null) {
      return this.player$.getValue().eventPlayer.playerDocId
    }
    return ''
  }


  public get name(): string {
    if (this.player$.getValue() !== null) {
      return this.player$.getValue().eventPlayer.name
    }
    return ''
  }


  public get isTempPlayer(): boolean {
    if (this.player$.getValue() !== null) {
      return this.player$.getValue().eventPlayer.playerDocId.includes('temp__')
    }
    return true
  }


  public get countryName(): string {
    if (this.player$.getValue() !== null) {
      return this.isTempPlayer
        ? 'non-Tolarian player'
        : this.player$.getValue().playerDoc.country.name
    }
    return ''
  }


  public get emailAddress(): string {
    if (this.player$.getValue() !== null && !this.isTempPlayer) {
      return this.player$.getValue().playerDoc.email
    }
    return ''
  }


  public get hasCheckedIn(): boolean {
    if (this.player$.getValue() !== null) {
      return this.player$.getValue().info.hasCheckedIn
    }
    return false
  }


  public get hasDropped(): boolean {
    if (this.player$.getValue() !== null) {
      return this.player$.getValue().eventPlayer.dropped
    }
    return false
  }

  public get disableDrop(): boolean {
    return this.helper.isTeamTournament
      ? this.helper.statusCode > 0
      : this.helper.eventType === 'group'
        ? this.hasDropped
        : false
  }


  public get eventStarted(): boolean {
    if (this.event$.getValue() !== null) {
      return this.event$.getValue().statusCode > 0 && this.event$.getValue().statusCode !== 14
    }
    return false
  }


  public get paymentActive(): boolean {
    if (this.event$.getValue() !== null) {
      const details = this.event$.getValue().details
      return details?.registrationFee && details?.registrationFee?.active
    }
    return false
  }


  public get paymentDetails(): IRegistrationFeePayment | ITicketMeta | null {
    if (this.event$.getValue() !== null || this.player$.getValue() === null) {
      const player = this.player$.getValue().info
      const registrationFee = this.event$.getValue().details.registrationFee
      if (player.hasPaidFee) {
        return registrationFee.paidBy[this.playerDocId]
      }
      if (player.hasPaidTicket) {
        return registrationFee.paidByTicket[this.playerDocId]
      }
    }
    return null
  }

  public initFlags(event: IEventDetails, data: IEventPlayerMeta): void {


    const flags: IPlayerFlags[] = []

    // add checked-in flag
    flags.push({
      icon: faUserCheck,
      tooltip: `Player checked-in status: ${data.info.hasCheckedIn ? 'checked-in' : 'NOT checked-in'}`,
      state: data.info.hasCheckedIn,
      class: data.info.hasCheckedIn
        ? 'text-success'
        : 'text-secondary',
      action: () => {
        this.player.setPlayerCheckInState(!data.info.hasCheckedIn, event.docId, [data.eventPlayer.playerDocId])
          .then(res => {
            this.toast.show(res.text, { classname: res.status ? 'success-toast' : 'error-toast' })
          })
      }
    })

    // add deck list state if mandatory for event
    if (event.details.deckList) {
      flags.push({
        icon: faFileAlt,
        tooltip: `Deck list submission status: ${data.info.hasSubmittedDeckList ? 'submitted' : 'NOT submitted'}`,
        state: data.info.hasSubmittedDeckList,
        class: data.info.hasSubmittedDeckList
          ? 'text-success'
          : 'text-secondary',
        action: () => {
          console.log('deck list sumbission info')
        }
      })
    }

    // add deck photo state if mandatory for event
    if (event.details.deckPhoto) {
      flags.push({
        icon: faCamera,
        tooltip: `Deck photo submission status: ${data.info.hasSubmittedDeckPhoto ? 'submitted' : 'NOT submitted'}`,
        state: data.info.hasSubmittedDeckPhoto,
        class: data.info.hasSubmittedDeckPhoto
          ? 'text-success'
          : 'text-secondary',
        action: () => {
          console.log('deck photo sumbission info')
        }
      })
    }

    // add payment state if mandatory for event
    if (event.details.registrationFee && event.details.registrationFee.active) {
      if (data.info.hasRefundedFee || data.info.hasRefundedTicket) {
        flags.push({
          icon: faReply,
          tooltip: `Registration fee refunded`,
          state: false,
          class: 'text-danger',
          action: () => this.action.paymentDetails()
        })
      }
      else if (data.info.hasPaidFee || data.info.hasPaidTicket) {
        flags.push({
          icon: data.info.hasPaidFee ? faCoins : faTicketAlt,
          tooltip: data.info.hasPaidFee
            ? `Registration fee paid`
            : `Ticket that covers the registration fee purchased`,
          state: false,
          class: 'text-success',
          action: () => this.action.paymentDetails()
        })
      }
      else {
        flags.push({
          icon: faCoins,
          tooltip: `Registration fee NOT PAID`,
          state: false,
          class: 'text-secondary',
          action: () => this.action.paymentDetails()
        })
      }
    }

    this.flags$.next(flags)

  }


  public get editModeActive(): boolean {
    return this.filter.attending.editMode
  }



}
