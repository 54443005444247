import { CommonModule } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { faFilter, faFilterCircleXmark } from '@fortawesome/free-solid-svg-icons';
import { LeagueListingService } from '../../services/league-listing.service';
import { FormsModule } from '@angular/forms';
import { NgbModal, NgbModalOptions } from '@ng-bootstrap/ng-bootstrap';
import { LeagueCreationFormComponent } from '../league-creation-form/league-creation-form.component';
import { UserService } from 'src/app/private/_shared/services/user.service';

@Component({
  selector: 'app-league-header-controls',
  templateUrl: './league-header-controls.component.html',
  styleUrls: ['./league-header-controls.component.css'],
  standalone: true,
  imports: [
    CommonModule,
    FormsModule,
    FontAwesomeModule,
  ]
})
export class LeagueHeaderControlsComponent implements OnInit {

  public filterText: string = ''

  constructor(
    private readonly listing: LeagueListingService,
    private readonly modal: NgbModal,
    private readonly user: UserService,
  ) { }

  ngOnInit() {
    console.log('LeagueHeaderControlsComponent:: initialized!')
  }

  public onFilterTextChanged(event: any): void {
    this.listing.filter.text = this.filterText
  }

  public onNewLeaguePressed(): void {
    const modalOptions: NgbModalOptions = {
      centered: true,
      animation: false,
      backdrop: true,
      fullscreen: window.innerWidth < 600,
      size: 'lg',
    }
    this.modal.open(LeagueCreationFormComponent, modalOptions)
  }

  public get icon() {
    return {
      filter: faFilter,
      clearFilter: faFilterCircleXmark,
    }
  }

  public get isAdmin(): boolean {
    return this.user.isAdmin
  }

  public get isOgranizer(): boolean {
    return this.user.isOrganizer
  }

}
