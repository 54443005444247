<div class="modal-body d-flex flex-column rounded-3 p-0 overflow-hidden">
    <div class="d-flex flex-column p-4 gap-3 border-0 border-bottom border-secondary border-opacity-50">
        <div class="d-flex align-items-center w-100">
            <div class="text-large flex-grow-1">Tournament selection</div>
            <div class="btn-close cursor-pointer" (click)="onCancel()"></div>
        </div>
        <input type="text"
            [(ngModel)]="filter.search"
            (change)="listing.onSearchChanged()"
            class="form-control"
            id="tournamentPickerSearch"
            placeholder="Filter list...">
        <ng-container *ngIf="listing.loadingEndedEvents">
            <div class="d-flex align-items-center text-secondary gap-2">
                <div class="spinner-border spinner-border-sm text-secondary" role="status">
                    <span class="visually-hidden">Loading...</span>
                </div>
                Loading ended events, please wait...
            </div>
        </ng-container>
    </div>
    <div class="d-flex flex-column gap-3 p-4 flex-grow-1 scrollable">
        
        <ng-container *ngIf="listing.ready$ | async; else loading">

            <ng-container *ngIf="listing.events$ | async as events">
                
                <app-event-grid-item *ngFor="let event of events | eventListing : filter"
                    [event]="event"
                    [lobbyLink]="false"
                    [selectLink]="true"
                    (onEventSelected)="onEventSelected($event)">
                </app-event-grid-item>
        
            </ng-container>

        </ng-container>
        
    </div>
</div>

<ng-template #loading>
    <div class="d-flex align-items-center text-secondary justify-content-center">
        <div class="spinner-border spinner-border-sm" role="status"></div>
        <div class="ms-3">Loading event listing...</div>
    </div>
</ng-template>