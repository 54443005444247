<ng-container *ngIf="player$ | async as player; else tempName">

  <ng-template #tipContent>
    <app-player-popover [id]="player.id" [showControls]="true"></app-player-popover>
  </ng-template>

  <div class="d-flex" [ngClass]="{
    'flex-row align-items-center' : showAvatar,
    'white-space-nowrap' : nowrap,
    'overflow-hidden' : ellipse
  }">

    <ng-container *ngIf="showAvatar">
      <div class="{{ avatarClass ? avatarClass : 'avatar-wrapper me-2' }}">
        <app-player-avatar [playerDocId]="player.id" [size]="avatarSize" [disablePopover]="disablePopover" [border]="false"></app-player-avatar>
      </div>
    </ng-container>

    <span class="player-link {{ player.id }}"
      [ngClass]="{ 'cursor-pointer' : !disableClick, 'text-truncate' : ellipse}"
      [stickyPopover]="tipContent"
      [openDelay]="500"
      [autoClose]="false"
      popoverClass="tolaria-player-popover"
      container="body"
      [disablePopover]="disablePopover"
      (click)="openPlayerDetails()"
      [innerHTML]="trueName ? name : playerName">
    </span>

  </div>

</ng-container>

<ng-template #tempName>
  <div class="display-name-string" *ngIf="name; else placeHolder" [innerHtml]="name"></div>
  <ng-template #placeHolder>
    <div class="placeholder">
      <div class="animated-background"></div>
    </div>
  </ng-template>
</ng-template>
