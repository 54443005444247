import { CommonModule } from '@angular/common';
import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { NgbActiveModal, NgbDate, NgbModal, NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { BehaviorSubject, Subject, takeUntil } from 'rxjs';
import { ImageCropperUploaderComponent } from 'src/app/components/app-structure/image-cropper-uploader/image-cropper-uploader.component';
import { UserService } from 'src/app/private/_shared/services/user.service';
import { ILeagueDocument, ILeagueManualPoint, ILeaguePoint, LeaguePointType } from 'tolaria-cloud-functions/src/_interfaces';
import { LeagueConfigService } from '../../services/league-config.service';
import { v4 as uuidv4 } from 'uuid'
import { StorageService } from 'src/app/services/storage.service';
import { ToastService } from 'src/app/services/toast.service';
import { Router } from '@angular/router';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { faCalendar } from '@fortawesome/free-regular-svg-icons';
import { TournamentPickerComponent } from 'src/app/private/play/tournament/components/tournament-picker/tournament-picker.component';
import { EventListingService, IEventTemplate } from 'src/app/private/play/events/services/event-listing.service';
import { LeagueAwardCustomPointFormComponent } from '../league-award-custom-point-form/league-award-custom-point-form.component';
import { PlayerDisplayNameComponent } from 'src/app/components';

@Component({
  selector: 'app-league-creation-form',
  templateUrl: './league-creation-form.component.html',
  styleUrls: ['./league-creation-form.component.css'],
  standalone: true,
  imports: [
    CommonModule,
    FormsModule,
    NgbModule,
    ImageCropperUploaderComponent,
    FontAwesomeModule,
    PlayerDisplayNameComponent,
]
})
export class LeagueCreationFormComponent implements OnInit, OnDestroy {
  @Input() set leagueData(doc: ILeagueDocument) {
    if (doc === undefined || doc === null) {
      // nothing
    }
    else {
      this.dateSelection = {
        fromDate: this.createNgbDateFromTimestamp(doc.timestampStart),
        toDate: this.createNgbDateFromTimestamp(doc.timestampEnd)
      }
      this.leagueDataPristine$.next(JSON.stringify(doc))
      this.leagueData$.next(doc)
    }
  }
  @Input() isEditor: boolean = false

  private saveTimer = null
  private destroyed$ = new Subject<boolean>()
  public changesMade$ = new BehaviorSubject<boolean>(false)
  public leagueDataPristine$ = new BehaviorSubject<string>(null)
  public leagueData$ = new BehaviorSubject<ILeagueDocument>(null)
  public showAddPoint: boolean = false
  public imageData$ = new BehaviorSubject<string>(null)
  public imageUploadBusy$ = new BehaviorSubject<boolean>(false)
  public hoveredDate: NgbDate | null = null
  public dateSelection = {
    fromDate: null,
    toDate: null,
  }
  public newPoint: ILeaguePoint = null
  public hideBannerSelection: boolean = true
  public tournamentList$ = new BehaviorSubject<IEventTemplate[]>([])
  public customPoint: ILeagueManualPoint = {
    playerDocId: null,
    points: null,
    comment: null,
  }

  constructor(
    private readonly modal: NgbActiveModal,
    private readonly user: UserService,
    private readonly leagueService: LeagueConfigService,
    private readonly eventListing: EventListingService,
    private readonly modalService: NgbModal,
    private readonly storage: StorageService,
    private readonly toast: ToastService,
    private readonly router: Router,
  ) { }

  ngOnInit() {
    console.log('LeagueCreationFormComponent:: init')
    if (this.isEditor === false) {
      let data: ILeagueDocument = {
        allowManualPoints: false,
        bannerUrl: null,
        createdByPlayerDocId: this.user.getPlayerId(),
        createdByUid: this.user.getUid(),
        createdTimestamp: null,
        description: '',
        docId: uuidv4(),
        eventDocIds: [],
        manualPoints: [],
        name: '',
        pointStructure: [],
        timestampEnd: null,
        timestampStart: null,
      }
      this.leagueData$.next(data)
    }
    this.leagueData$.pipe(takeUntil(this.destroyed$)).subscribe((doc) => {
      if (this.isEditor && doc !== undefined && doc !== null) {
        const pristine = this.leagueDataPristine$.getValue()
        if (pristine !== undefined && pristine !== null) {
          this.changesMade$.next(JSON.stringify(doc) !== pristine)
        }
        this.mapTournaments(doc)
      }
    })
    this.changesMade$.pipe(takeUntil(this.destroyed$)).subscribe(changed => {
      if (changed) {
        // check if save timer exist and clear it to prevent multiple writes within short time
        if (this.saveTimer) { 
          console.log('LeagueCreationFormComponent:: clearing existing write timer')
          clearTimeout(this.saveTimer)
        }
        console.log('LeagueCreationFormComponent:: setting write timer')
        this.saveTimer = setTimeout(() => {
          console.log('LeagueCreationFormComponent:: calling update')
          this.leagueService.update(this.leagueData$.getValue())
        }, 10000)
      }
    })
    this.tournamentList$.subscribe(i => console.log('LeagueCreationFormComponent:: tournamentList$ emitted ->', i))
  }

  ngOnDestroy(): void {
    this.destroyed$.next(true)
  }

  private createNgbDateFromTimestamp(timestamp: number): NgbDate {
    const date = new Date(timestamp * 1000)
    const year = date.getFullYear()
    const month = date.getMonth() + 1
    const day = date.getDate()
    return new NgbDate(year, month, day)
  }

  private async mapTournaments(doc: ILeagueDocument) {
    const tournaments = this.tournamentList$.getValue()
    const tournamentIds = tournaments.map(i => i.docId)
    const tournamentsToLoad = doc.eventDocIds.filter(i => !tournamentIds.includes(i))
    const tournamentsToRemove = tournamentIds.filter(i => !doc.eventDocIds.includes(i))
    for await (const id of tournamentsToLoad) {
      const doc = await this.eventListing.getEventById(id)
      if (doc !== null) {
        tournaments.push(doc)
      }
    }
    this.tournamentList$.next(tournaments.filter(i => !tournamentsToRemove.includes(i.docId)))
  }

  public onModelChanged($event: any): void {
    this.leagueData$.next(this.leagueData$.getValue())
  }

  public onCreatePress(): void {
    console.log('LeagueCreationFormComponent:: onCreatePress -> leageData', this.leagueData$.getValue())
    this.leagueService.create(this.leagueData$.getValue())
      .then(() => {
        this.modal.dismiss()
        setTimeout(() => {
          this.router.navigate(['/leagues', 'details', this.leagueData$.getValue().docId])
        }, 1000)
      })
      .catch((error) => {
        this.toast.show(error, { classname: 'error-toast', delay: 8000 })
      })
  }

  public onAddTournamentPress(): void {
    const modal = this.modalService.open(TournamentPickerComponent, {
        centered: true,
        animation: false,
      })
      modal.componentInstance.includeEndedTournaments = true
      modal.result.then((result: IEventTemplate)  => {
        if (result) {
          console.log('LeagueCreationFormComponent:: tournament selected ->', result)
          const leageData = this.leagueData$.getValue()
          if (leageData.eventDocIds.find(i => i === result.docId) === undefined) {
            leageData.eventDocIds.push(result.docId)
            this.leagueData$.next(leageData)
          }
        }
      })
  }

  public onCancelPress(): void {
    this.storage.deleteImage(`leagues/${this.leagueData$.getValue().docId}.png`)
    this.modal.dismiss()
  }

  public onTogglePointFormPress(): void {
    if (this.showAddPoint === false) {
      this.newPoint = {
        type: null,
        points: null,
        rankFrom: null,
        rankFromSuffix: null,
        rankTo: null,
        rankToSuffix: null,
        isRange: false,
        customExplaination: '',
      }
    }
    this.showAddPoint = !this.showAddPoint
  }

  public onAddPointPress(): void {
    let leagueData = this.leagueData$.getValue()
    leagueData.pointStructure.push(this.newPoint)
    this.leagueData$.next(leagueData)
    this.onTogglePointFormPress()
  }

  public onRemovePointPress(index: number): void {
    let leagueData = this.leagueData$.getValue()
    leagueData.pointStructure.splice(index, 1)
    this.leagueData$.next(leagueData)
  }

  public onAwardCustomPoint(point: ILeaguePoint): void {
    this.customPoint = {
      points: point.points,
      playerDocId: null,
      comment: point.customExplaination,
      meta: point,
    }
    const modal = this.modalService.open(LeagueAwardCustomPointFormComponent)
    modal.componentInstance.customPoint = this.customPoint
    modal.result.then(
      (result) => {
        if (result) {
          if (result.meta) {
            delete result.meta
          }
          this.leagueService.awardPoint(this.leagueData$.getValue(), result)
        }
      },
      () => {}
    )
  }

  public onRemoveCutomPoint(index: number): void {
    const data = this.leagueData$.getValue()
    data.manualPoints.splice(index, 1)
    this.leagueService.update(data)
  }

  public onRemoveTournamentPress(doc: IEventTemplate): void {
    let leagueData = this.leagueData$.getValue()
    leagueData.eventDocIds = leagueData.eventDocIds.filter(i => i !== doc.docId)
    this.leagueData$.next(leagueData)
  }

  public onImageLoaded(base64: string): void {
    this.imageData$.next(base64)
    this.imageUploadBusy$.next(true)
    this.storage
      .uploadImage(`leagues/${this.leagueData$.getValue().docId}.png`, base64, false)
      .then((url: string) => {
        let leagueData = this.leagueData$.getValue()
        leagueData.bannerUrl = url
        this.leagueData$.next(leagueData)
        this.imageUploadBusy$.next(false)
      })
  }

  public onDateSelection(date: NgbDate,  section: 'from' | 'to'): void {
    const leagueData = this.leagueData$.getValue()
    switch (section) {
      case 'from':
        this.dateSelection.fromDate = date
        leagueData.timestampStart = new Date(date.year, date.month - 1, date.day).getTime() / 1000
        break
      case 'to':
        this.dateSelection.toDate = date
        leagueData.timestampEnd = new Date(date.year, date.month - 1, date.day).getTime() / 1000
        break
    }
    this.leagueData$.next(leagueData)
  }

  public isHovered(date: NgbDate): boolean {
    return this.dateSelection.fromDate
      && !this.dateSelection.toDate
      && this.hoveredDate
      && date.after(this.dateSelection.fromDate)
      && date.before(this.hoveredDate);
  }
  public isInside(date: NgbDate): boolean {
    return this.dateSelection.toDate
      && date.after(this.dateSelection.fromDate)
      && date.before(this.dateSelection.toDate);
  }
  public isRange(date: NgbDate): boolean {
    return date.equals(this.dateSelection.fromDate)
      || (this.dateSelection.toDate
        && date.equals(this.dateSelection.toDate))
      || this.isInside(date)
      || this.isHovered(date);
  }

  public get showPointList(): boolean {
    return this.leagueData$.getValue().pointStructure.length > 0
  }

  public get pointTypes(): string[] {
    return Object.keys(LeaguePointType)
  }

  public get pointTypeTranslations() {
    return this.leagueService.translations
  }

  public get newLeagueFormDisabled(): boolean {
    // console.log('LeagueCreationFormComponent:: newLeageFormDisabled ->', this.leagueData$.getValue())
    if (this.leagueData$.getValue().name.length < 10) { return true }
    if (this.leagueData$.getValue().description.length < 10) { return true }
    if (this.leagueData$.getValue().timestampStart === null) { return true }
    if (this.leagueData$.getValue().timestampEnd === null) { return true }
    if (this.leagueData$.getValue().timestampStart >= this.leagueData$.getValue().timestampEnd) { return true }
    if (this.leagueData$.getValue().bannerUrl === null) { return true }
    return false
  }

  public get newPointFormDisabled(): boolean {
    if (this.newPoint.type === null) { return true }
    if (this.newPoint.points < 1) { return true }
    if (this.newPoint.type === LeaguePointType.RANK) {
      if (this.newPoint.isRange) {
        return this.newPoint.rankFrom < 1 || this.newPoint.rankTo <= this.newPoint.rankFrom
      }
      else {
        return this.newPoint.rankFrom < 1 || this.newPoint.rankTo < 1
      }
    }
    if (this.newPoint.type === LeaguePointType.CUSTOM) {
      return this.newPoint.customExplaination.length < 10
    }
    return false
  }

  public get hasManualPoints(): boolean {
    return this.leagueData$.getValue() === null
      ? false
      : this.leagueData$.getValue().manualPoints.length > 0 && this.isEditor
  }

  public get icon() {
    return {
      caledar: faCalendar,
    }
  }

}
