import { CommonModule } from '@angular/common';
import { ChangeDetectionStrategy, Component, Input, OnInit } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { BehaviorSubject } from 'rxjs';
import { EventGridItemComponent } from 'src/app/private/play/events/components/event-grid-item/event-grid-item.component';
import { EventListingPipe } from 'src/app/private/play/events/pipes/event-listing.pipe';
import { EventListingService, IEventFilter, IEventTemplate } from 'src/app/private/play/events/services/event-listing.service';

@Component({
  selector: 'app-tournament-picker',
  standalone: true,
  imports: [
    CommonModule,
    EventGridItemComponent,
    FormsModule,
    EventListingPipe,
  ],
  templateUrl: './tournament-picker.component.html',
  styleUrls: ['./tournament-picker.component.css'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class TournamentPickerComponent implements OnInit {
  @Input() includeEndedTournaments: boolean = false

  public filter: IEventFilter = {
    showPanel: false,
    search: '',
    organizer: {
      available: [],
      selected: []
    },
    formats: {
      available: [],
      selected: [],
    },
    show: [
      {
        text: 'Hosting',
        selected: true,
        property: 'isHosting'
      },
      {
        text: 'Attending',
        selected: true,
        property: 'isAttending'
      },
      {
        text: 'Invited',
        selected: true,
        property: 'isInvited'
      },
      {
        text: 'Waiting',
        selected: true,
        property: 'isWaiting'
      },
      {
        text: 'Show all',
        selected: true,
        property: 'all'
      },
    ],
    type: {
      inPerson: true,
      online: true,
    },
    length: {
      singleDay: true,
      multiDay: true,
    },
    date: {
      from: null,
      to: null,
    }
  }

  constructor(
    private readonly modal: NgbActiveModal,
    public readonly listing: EventListingService,
  ) { }

  ngOnInit(): void {
    if (this.includeEndedTournaments && !this.listing.endedEventsLoaded) {
      this.listing.loadEndedEvents()
    }
  }

  onCancel(): void {
    this.modal.dismiss()
  }

  onEventSelected(event: IEventTemplate): void {
    this.modal.close(event)
  }

}
