import { Component, Input, OnDestroy, OnInit, ViewEncapsulation, forwardRef } from '@angular/core'
import { BehaviorSubject, Subject } from 'rxjs'
import { takeUntil } from 'rxjs/operators'
import { CommonModule } from '@angular/common'
import { NgbTooltip } from '@ng-bootstrap/ng-bootstrap'
import { StickyPopoverDirective } from 'src/app/directives/sticky-popover.directive'
import { PlayerPopoverComponent } from 'src/app/private/_shared/player-popover.component'
import { PlayerListingService } from 'src/app/private/_shared/services/player-listing.service'
import { PlayerNameService } from 'src/app/services/players/player-name.service'
import { IPlayerMini } from 'tolaria-cloud-functions/src/_interfaces'

@Component({
  selector: 'app-player-avatar',
  templateUrl: './player-avatar.component.html',
  encapsulation: ViewEncapsulation.None,
  styleUrls: ['./player-avatar.component.css'],
  standalone: true,
  imports: [
    StickyPopoverDirective,
    CommonModule,
    NgbTooltip,
    forwardRef(() => PlayerPopoverComponent),
    PlayerPopoverComponent,
  ]
})
export class PlayerAvatarComponent implements OnDestroy, OnInit {
  @Input() disablePopover: boolean = true
  @Input() disableClick: boolean = false
  @Input() avatarUrl: string = 'assets/avatars/default.jpg'
  @Input() size: 'mini' | 'small' | 'medium' | 'chat' | 'large' | 'mega' | 'fill' | 'default' = 'default'
  @Input() round: boolean = true
  @Input() border: boolean = true
  @Input() radius: number = 0
  @Input() set playerUid(val: string) { 
    this.playerUID = val
    this.loadData()
  }
  @Input() set playerDocId(val: string) {
    this.playerDOCID = val
    this.loadData()
  }

  public playerUID: string
  public playerDOCID: string
  public isTemporaryPlayer: boolean = false

  public player$ = new BehaviorSubject<IPlayerMini>(null)
  private destroyed$ = new Subject<boolean>()

  constructor(
    private playerNames: PlayerNameService,
    private readonly playerList: PlayerListingService,
  ) { }

  ngOnInit(): void {
    this.playerNames.serviceReady$.pipe(takeUntil(this.destroyed$)).subscribe((ready) => ready ? this.loadData(): null)
  }

  ngOnDestroy(): void {
    this.destroyed$.next(true)
  }

  public openPlayerDetails(): void {
    if (this.disableClick === false) {
      this.playerList.showPofile(this.player$.getValue().id)
    }
  }

  public get today() {
    return new Date()
  }

  private loadData(): void {
    if (this.playerUID !== null && this.playerUID !== '' && this.playerUID !== undefined) {
      if (!this.playerUID.includes('temp__')) {
        const player = this.playerNames.getPlayerByUid(this.playerUID)
        this.player$.next(player)
        return
      }
      else {
        this.player$.next(null)
        this.avatarUrl = 'assets/avatars/non-tolarian.png'
      }
    }
    if (this.playerDOCID !== null && this.playerDOCID !== '' && this.playerDOCID !== undefined) {
      if (!this.playerDOCID.includes('temp__')) {
        const player = this.playerNames.getPlayerById(this.playerDOCID)
        this.player$.next(player)
        return
      }
      else {
        this.player$.next(null)
        this.avatarUrl = 'assets/avatars/non-tolarian.png'
      }
    }
  }

}
