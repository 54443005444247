import { takeUntil } from 'rxjs/operators';
import { BehaviorSubject, Subject, firstValueFrom } from 'rxjs';
import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { AuthService } from 'src/app/services';
import { AngularFirestore } from '@angular/fire/compat/firestore';
import { IEventDetails, IMatchData, IMatchRoomKnock } from 'tolaria-cloud-functions/src/_interfaces';
import { MatchRoomOpenService } from 'src/app/private/play/matches/services/match-room-open.service';
import { v4 as uuidv4 } from 'uuid'

export interface IMatchInfoMeta {
  createdByUid: string;
  isEventMatch: boolean;
  eventName: string;
  eventDocId: string;
  matchDocId: string;
  player1: {
    displayName: string,
    playerUid: string,
    playerDocId: string
  }
  player2: {
    displayName: string,
    playerUid: string,
    playerDocId: string
  }
  allowSpectators: boolean
}

@Component({
  selector: 'app-match-info-item',
  templateUrl: './match-info-item.component.html',
  styleUrls: ['./match-info-item.component.css']
})
export class MatchInfoItemComponent implements OnInit, OnDestroy {
  @Input() matchDocId: string;

  matchMeta$: BehaviorSubject<IMatchInfoMeta | null> = new BehaviorSubject<IMatchInfoMeta | null>(null)
  knocking$: BehaviorSubject<IMatchRoomKnock | null> = new BehaviorSubject<IMatchRoomKnock | null>(null)

  private destroyed$ = new Subject<boolean>()

  constructor(
    private readonly firestore: AngularFirestore,
    private readonly auth: AuthService,
    private readonly matchroom: MatchRoomOpenService,
  ) { }

  ngOnInit(): void {
    console.log('[MatchInfoItemComponent] -> ngOnInit -> matchDocId:', this.matchDocId)
    this.initKnockListener()
    this.initDocument()
  }

  ngOnDestroy(): void {
    this.destroyed$.next(true)
  }

  private async initDocument() {

    if (this.matchDocId === undefined || this.matchDocId === null) {
      return
    }

    let matchDoc = undefined
    let eventDoc = undefined

    const snap = await firstValueFrom(this.firestore.collection('matches').doc<IMatchData>(this.matchDocId).get())

    if (!snap.exists) {
      return
    }

    matchDoc = snap.data()

    if (matchDoc.eventDocId !== 'casual-match') {
      const eventDocumentSnap = await firstValueFrom(this.firestore.collection('events').doc<IEventDetails>(matchDoc.eventDocId).get())

      if (eventDocumentSnap.exists) {
        eventDoc = eventDocumentSnap.data()
      }
    }

    const matchMeta: IMatchInfoMeta = {
      createdByUid: matchDoc.createdByUid,
      isEventMatch: false,
      eventName: eventDoc === undefined && matchDoc.eventDocId === 'casual-match'
        ? 'Casual Match'
        : (eventDoc as IEventDetails).details.name,
      eventDocId: matchDoc.eventDocId,
      matchDocId: matchDoc.docId,
      player1: {
        displayName: matchDoc.player1.displayName,
        playerDocId: matchDoc.player1.playerDocId,
        playerUid: matchDoc.player1.playerUid
      },
      player2: {
        displayName: matchDoc.player2.displayName,
        playerDocId: matchDoc.player2.playerDocId,
        playerUid: matchDoc.player2.playerUid
      },
      allowSpectators: eventDoc === undefined && matchDoc.eventDocId === 'casual-match'
        ? true
        : (eventDoc as IEventDetails)?.details?.allowSpectators
    }

    this.matchMeta$.next(matchMeta)

  } 

  public onButtonPress(meta: IMatchInfoMeta): void {
    if (this.buttonText === 'KNOCK') {
      this.knock(meta)
    }
    if (this.buttonText === 'WATCH') {
      this.openMatchRoom(meta)
    }
  }

  private knock(meta: IMatchInfoMeta): void {
    const knock: IMatchRoomKnock = {
      docId: uuidv4(),
      knockingPlayerDocId: this.auth.user.playerId,
      allowedBy: [],
      deniedBy: [],
      matchDocId: meta.matchDocId,
    }
    this.firestore
      .collection('matchRoomKnocking')
      .doc(knock.docId)
      .set(knock)
      .then(() => console.log('MatchInfoItemComponent > knocking on the match room'))
      .catch((e) => console.log(e))
  }

  private openMatchRoom(meta: IMatchInfoMeta): void {
    this.matchroom.openMatchRoom(meta.matchDocId)
  }

  private initKnockListener() {
    const query = this.firestore.collection<IMatchRoomKnock>('matchRoomKnocking', ref => ref
      .where('knockingPlayerDocId', '==', this.auth.user.playerId)
      .where('matchDocId', '==', this.matchDocId)
      .limit(1))

    query
      .valueChanges().pipe(takeUntil(this.destroyed$))
      .subscribe(knocks => {
        console.log('MatchInfoItemComponent > Knocking on this match room emitted', knocks)
        if (knocks.length > 0) {
          this.knocking$.next(knocks[0])
        }
        else {
          this.knocking$.next(null)
        }
      })
  }

  public get disableButton(): boolean {
    const meta = this.matchMeta$.getValue()
    const knock = this.knocking$.getValue()
    if (meta === null) {
      // disable press
      return true
    }
    if (meta.allowSpectators === false && knock === null) {
      // allow button press as the action will be KNOCK
      return false
    }
    if (meta.allowSpectators === false && knock !== null && knock.allowedBy.length !== 2) {
      // disable press
      return true
    }

    // allow press
    return false
  }

  public get buttonText(): 'WATCH' | 'WAITING' | 'KNOCK' | 'LOADING' {
    const meta = this.matchMeta$.getValue()
    const knock = this.knocking$.getValue()
    if (meta === null) {
      return 'LOADING'
    }
    if (meta.allowSpectators === false && knock === null) {
      return 'KNOCK'
    }
    if (meta.allowSpectators === false && knock !== null && [...knock.allowedBy, ...knock.deniedBy].length < 2) {
      return 'WAITING'
    }
    return 'WATCH'
  }

  public get waitingForAnswer(): boolean {
    const meta = this.matchMeta$.getValue()
    const knock = this.knocking$.getValue()
    if (meta !== null && knock !== null) {
      if ([...knock.allowedBy, ...knock.deniedBy].length < 2) {
        return true
      }
    }
    return false
  }

  public get knockingDenied(): boolean {
    const meta = this.matchMeta$.getValue()
    const knock = this.knocking$.getValue()
    if (meta !== null && knock !== null) {
      if (knock.deniedBy.length === 2) {
        return true
      }
    }
    return false
  }

  public get watchingAllowed(): boolean {
    const meta = this.matchMeta$.getValue()
    const knock = this.knocking$.getValue()
    if (meta !== null && knock !== null) {
      if (knock.allowedBy.length === 2) {
        return true
      }
      if (meta.allowSpectators) {
        return true
      }
    }
    return false
  }

}
