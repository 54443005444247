<div class="modal-header d-flex justify-content-between">
  <span class="text-larger">Award custom point</span>
  <button class="btn-close" (click)="onClosePressed()"></button>
</div>
<div class="modal-body d-flex flex-column gap-3">
    <ng-container *ngIf="data$ | async as data">
    <div class="input-group">
      <span class="input-group-text">Comment</span>
      <input type="text" class="form-control" [(ngModel)]="data.comment">
    </div>
    <app-player-search title="Search player" [showTitle]="true" (output)="onPlayerSearchEmiited($event)"></app-player-search>
    <ng-container *ngIf="hasPlayer">
      <app-player-display-name [playerDocId]="data.playerDocId" [showAvatar]="true" avatarClass="rounded rounded-3 overflow-hidden me-3"></app-player-display-name>
    </ng-container>
  </ng-container>
</div>
<div class="modal-footer d-flex justify-content-end">
  <button class="btn btn-blank" (click)="onClosePressed()">Cancel</button>
  <button class="btn btn-outline-secondary" (click)="onSavePressed()" [disabled]="disableForm">Award player point</button>
</div>