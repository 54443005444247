import { BehaviorSubject } from 'rxjs'
import { Component, Input, forwardRef } from '@angular/core'
import { PlayerAvatarComponent } from '../player-avatar/player-avatar.component'
import { CommonModule } from '@angular/common'
import { StickyPopoverDirective } from 'src/app/directives/sticky-popover.directive'
import { PlayerPopoverComponent } from 'src/app/private/_shared/player-popover.component'
import { PlayerListingService } from 'src/app/private/_shared/services/player-listing.service'
import { PlayerNameService } from 'src/app/services/players/player-name.service'
import { IPlayerMini } from 'tolaria-cloud-functions/src/_interfaces'

@Component({
  selector: 'app-player-display-name',
  templateUrl: './player-display-name.component.html',
  styleUrls: ['./player-display-name.component.css'],
  standalone: true,
  imports: [
    CommonModule,
    StickyPopoverDirective,
    forwardRef(() => PlayerAvatarComponent),
    forwardRef(() => PlayerPopoverComponent),
  ]
})
export class PlayerDisplayNameComponent {
  @Input() disableClick: boolean = false
  @Input() disablePopover: boolean = false
  @Input() trueName: boolean
  @Input() showAvatar: boolean
  @Input() avatarClass: string = undefined
  @Input() avatarSize: string = 'default'
  @Input() position: string
  @Input() nowrap: boolean = true
  @Input() ellipse: boolean = false
  @Input() display: 'display' | 'full' | 'first' | 'last' | 'short' | 'initials' | 'nick' = 'display'
  @Input() name: string
  @Input()
  set playerUid(val: string) {
    if (!val.includes('*** BYE') && !val.includes('*** LOSS') && !val.includes('temp')) {
      this.player$.next(this.playerNames.getPlayerByUid(val))
    }
    else {
      this.player$.next(null)
    }
  }
  @Input()
  set playerDocId(val: string) {
    if (!val.includes('*** BYE') && !val.includes('*** LOSS') && !val.includes('temp')) {
      this.player$.next(this.playerNames.getPlayerById(val))
    }
    else {
      this.player$.next(null)
    }
  }

  player$ = new BehaviorSubject<IPlayerMini>(null)

  constructor(
    private readonly playerNames: PlayerNameService,
    private readonly playerList: PlayerListingService,
  ) { }

  public openPlayerDetails(): void {
    if (this.disableClick === false) {
      this.playerList.showPofile(this.player$.getValue().id)
    }
  }

  public get playerName(): string {
    if (this.player$.getValue() === null) {
      if (this.name) {

        
      }
      return 'Nameless Race'
    }
    
    let name = this.player$.getValue().name
    switch (this.display) {
      case 'display':
        return name.display
      case 'full':
        return `${name.first} ${name.last}`
      case 'first':
        return name.first
      case 'last':
        return name.last
      case 'initials':
        return `${name.first.substring(0, 1).toUpperCase()}${name.last.substring(0, 1).toUpperCase()}`
      case 'nick':
        return name.nick
      case 'short':
        return `${name.first} ${name.last.substring(0, 1).toUpperCase()}`
    }

  }

}
