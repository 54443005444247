import { CommonModule } from '@angular/common';
import { Component, Input, OnInit } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { BehaviorSubject } from 'rxjs';
import { PlayerDisplayNameComponent } from 'src/app/components';
import { PlayerSearchComponent } from 'src/app/private/_shared/player-search/player-search.component';
import { ILeagueManualPoint, IPlayerMini } from 'tolaria-cloud-functions/src/_interfaces';

@Component({
  selector: 'app-league-award-custom-point-form',
  templateUrl: './league-award-custom-point-form.component.html',
  styleUrls: ['./league-award-custom-point-form.component.css'],
  standalone: true,
  imports: [
    CommonModule,
    FormsModule,
    PlayerSearchComponent,
    PlayerDisplayNameComponent,
  ]
})
export class LeagueAwardCustomPointFormComponent {
  @Input() set customPoint(data: ILeagueManualPoint) {
    this.data$.next(data)
  }

  public data$ = new BehaviorSubject<ILeagueManualPoint>(null)

  constructor(
    private readonly modal: NgbActiveModal,
  ) { }

  public onClosePressed(): void {
    this.modal.dismiss()
  }

  public onSavePressed(): void {
    this.modal.close(this.data$.getValue())
  }

  public onPlayerSearchEmiited(player: IPlayerMini): void {
    const data = this.data$.getValue()
    data.playerDocId = player.id
    this.data$.next(data)
  }

  public get hasPlayer(): boolean {
    const data = this.data$.getValue()
    return data === null
      ? false
      : data.playerDocId !== undefined && data.playerDocId !== null
  }

  public get disableForm(): boolean {
    const data = this.data$.getValue()
    if (data === null) { return true }
    if (data.playerDocId === undefined || data.playerDocId === null || data.playerDocId === '') { return true }
    if (data.comment === undefined || data.comment === null || data.comment === '') { return true }
    return false
  }

}
