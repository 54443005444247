import { GlobalsService } from '../../../../../services/globals.service';
import { BehaviorSubject } from 'rxjs';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { IMatchData } from 'tolaria-cloud-functions/src/_interfaces'
import { Component, Input, OnInit } from '@angular/core'
import { PlayerNameService } from 'src/app/services/players/player-name.service';
import { faMinus, faPlus } from '@fortawesome/free-solid-svg-icons';

export type Score = '1 - 0' | '0 - 0' | '1 - 1' | '2 - 0' | '2 - 1' | 'none'
interface IStep {
  text: 'Step 1' | 'Step 2' | 'Step 3'
  active: boolean
}
@Component({
  selector: 'app-report-slip',
  templateUrl: './report-slip.component.html',
  styleUrls: ['./report-slip.component.css'],
})
export class ReportSlipComponent implements OnInit {
  @Input() set match(val: IMatchData) {
    this.match$.next(val)
    if (this.playerIsConfirming) {
      this.activeStep = 3
    }
  }
  @Input() isOrganizer: boolean = false

  public activeStep: number = 1
  public match$: BehaviorSubject<IMatchData> = new BehaviorSubject<IMatchData>(null)

  constructor(
    public globals: GlobalsService,
    private readonly modal: NgbActiveModal,
    private readonly players: PlayerNameService,
  ) {
    // debugging mobile device in chrome
    document.body.addEventListener('contextmenu', (event) => {
      event.preventDefault()
    })
    // add event listener that will remove the players online status if tab/window is closed
    window.addEventListener('beforeunload', async (e) => this.onDismiss())
  }

  ngOnInit(): void {
    console.log('[ReportSlipComponent] --> ngOnInit')
  }

  public onDismiss(): void {
    this.modal.dismiss()
  }

  public onReport(): void {
    this.modal.close(this.match$.getValue())
  }

  public onAcceptResult(accepted: boolean) {
    const match = this.match$.getValue()
    match.playerReporting.confirmedByOpponent = accepted
    match.playerReporting.rejectedByOpponent = !accepted
    this.modal.close(match)
  }

  public toStep(step: number): void {
    this.activeStep = step
  }

  public nextStep(): void {
    if (this.activeStep === 3) { return }
    this.activeStep++
  }

  public prevStep(): void {
    if (this.activeStep === 1) { return }
    this.activeStep--
  }

  public selectWinner(winner: 'player1' | 'player2' | 'draw'): void {
    // reset score
    this.resetScore()

    // get match
    const match = this.match$.getValue()

    // update player winner status
    match.player1.isWinner = winner === 'player1'
    match.player2.isWinner = winner === 'player2'

    // update draw status
    match.isDraw = winner === 'draw'

    // update match observer
    this.match$.next(match)

    // proceed
    this.toStep(2)
  }

  public resetScore(): void {
    const match = this.match$.getValue()
    match.player1.isWinner = false
    match.player1.wins = 0
    match.player1.losses = 0
    match.player1.draws = 0
    match.player2.isWinner = false
    match.player2.wins = 0
    match.player2.losses = 0
    match.player2.draws = 0
    this.match$.next(match)
  }

  public setScore(score: Score): void {
    console.log(`[ReportSlipComponent] --> Step 2: Updating result to ${score}`)
    const match = this.match$.getValue()
    switch (score) {
      case '0 - 0':
        match.player1.wins = 0
        match.player2.wins = 0
        match.player1.losses = 0
        match.player2.losses = 0
        break
      case '1 - 1':
        match.player1.wins = 1
        match.player2.wins = 1
        match.player1.losses = 1
        match.player2.losses = 1
        break
      case '1 - 0':
        match[this.winnerIs].wins = 1
        match[this.winnerIs].losses = 0
        match[this.loserIs].wins = 0
        match[this.loserIs].losses = 1
        break
      case '2 - 0':
        match[this.winnerIs].wins = 2
        match[this.winnerIs].losses = 0
        match[this.loserIs].wins = 0
        match[this.loserIs].losses = 2
        break
      case '2 - 1':
        match[this.winnerIs].wins = 2
        match[this.winnerIs].losses = 1
        match[this.loserIs].wins = 1
        match[this.loserIs].losses = 2
        break
    }
    this.match$.next(match)
    this.toStep(3)
  }

  public increaseDrawnGames(): void {
    const match = this.match$.getValue()
    match.player1.draws++
    match.player2.draws++
    this.match$.next(match)
  }
  public decreaseDrawnGames(): void {
    const match = this.match$.getValue()
    if (match.player1.draws === 0) {
      return
    }
    match.player1.draws--
    match.player2.draws--
    this.match$.next(match)
  }

  private _dropHandler: any
  private _dropProgressHandler: any
  public isDropping: boolean = false
  public dropProgress: number = 0

  public startDropping(): void {
    console.log('drop button pressed')
    this.isDropping = true
    this.dropProgress = 10
    this._dropProgressHandler = setInterval(() => {
      this.dropProgress += 4
    }, 100)
    this._dropHandler = setTimeout(() => {
      console.log('dropping')
      this.isDropping = false
      this._dropProgressHandler ? clearInterval(this._dropProgressHandler) : null
      const match = this.match$.getValue()
      const playerIs = match.player1.playerDocId === this.players.currentPlayersMini.id ? 'player1' : 'player2'
      match[playerIs].drop = true
      this.match$.next(match)
    }, 3000)
  }

  public stopDropping(): void {
    console.log('drop button released')
    this.isDropping = false
    this._dropHandler ? clearTimeout(this._dropHandler) : null
    this._dropProgressHandler ? clearInterval(this._dropProgressHandler) : null
  }

  public undoDropping(): void {
    const match = this.match$.getValue()
    const playerIs = match.player1.playerDocId === this.players.currentPlayersMini.id ? 'player1' : 'player2'
    match[playerIs].drop = false
    this.match$.next(match)
  }

  public get winnerIs(): 'draw' | 'player1' | 'player2' {
    const match = this.match$.getValue()
    return match.isDraw
      ? 'draw'
      : match.player1.isWinner
        ? 'player1'
        : 'player2'
  }

  public get loserIs(): 'draw' | 'player1' | 'player2' {
    const match = this.match$.getValue()
    return match.isDraw
      ? 'draw'
      : match.player1.isWinner
        ? 'player2'
        : 'player1'
  }

  public get winnerDocId(): string {
    if (this.match$.getValue().isDraw) {
      return 'draw'
    }
    else {
      return this.match$.getValue()[this.winnerIs].playerDocId
    }
  }

  public get winnerName(): string {
    if (this.match$.getValue().isDraw) {
      return 'draw'
    }
    else {
      return this.match$.getValue()[this.winnerIs].displayName
    }
  }


  public get stepOneDone(): boolean {
    if (this.match$.getValue() === null) { return false }
    const match = this.match$.getValue()
    return match.isDraw || match.player1.isWinner || match.player2.isWinner
  }

  public get stepTwoDone(): boolean {
    if (this.match$.getValue() === null) { return false }
    const match = this.match$.getValue()
    if (match.isDraw) {
      return match.player1.wins === match.player2.wins && match.player1.losses === match.player2.losses && match.player1.draws === match.player2.draws
    }
    if (match.player1.isWinner) {
      return match.player1.wins > match.player2.wins && match.player1.losses < match.player2.losses && match.player1.draws === match.player2.draws
    }
    if (match.player2.isWinner) {
      return match.player2.wins > match.player1.wins && match.player2.losses < match.player1.losses && match.player2.draws === match.player1.draws
    }
    return false
  }

  public get disableReport(): boolean {
    return !this.stepOneDone || !this.stepTwoDone
  }

  public get score(): Score {
    const match = this.match$.getValue()
    if (match.isDraw) {
      return match.player1.wins === 1 && match.player2.wins === 1 ? '1 - 1' : '0 - 0'
    }
    return match[this.winnerIs].wins === 2 && match[this.winnerIs].losses === 1
      ? '2 - 1'
      : match[this.winnerIs].wins === 2 && match[this.winnerIs].losses === 0
        ? '2 - 0'
        : match[this.winnerIs].wins === 1 && match[this.winnerIs].losses === 0
          ? '1 - 0'
          : 'none'
  }

  public get drawnGames(): string {
    const match = this.match$.getValue()
    if (match.player1.draws > 0) {
      return ` - ${match.player1.draws}`
    }
    return ''
  }

  public get playerIsConfirming(): boolean {
    const match = this.match$.getValue()
    if (match === null) {
      return false
    }
    if (this.isOrganizer) {
      return false
    }
    if (match.playerReporting && match.playerReporting.waitingForPlayerDocId.includes(this.players.currentPlayersMini.id)) {
      return true
    }
  }

  public get opponentDocId(): string {
    if (this.match$.getValue() === null) { return '' }
    const match = this.match$.getValue()
    const opponentIs = match.player1.playerDocId === this.players.currentPlayersMini.id ? 'player2' : 'player1'
    return match[opponentIs].playerDocId

  }

  public get matchLabels(): string {
    if (this.match$.getValue() === null) { return '' }
    const match = this.match$.getValue()
    switch (match.isType) {
      case 'batch':
        return `
          <span class="badge bg-secondary">BATCH ${match.roundNumber}</span>
          <span class="badge bg-warning">SEGMENT ${match.segmentNumber}</span>`
      case 'group':
        return `
          <span class="badge bg-secondary">GROUP ${match.groupName}</span>
          <span class="badge bg-warning">ROUND ${match.segmentNumber}</span>`
      case 'swiss':
        return `
          <span class="badge bg-secondary">ROUND ${match.roundNumber}</span>
          <span class="badge bg-warning">TABLE ${match.tableNumber}</span>`
    }
  }

  public get playerCanDrop(): boolean {
    if (this.match$.getValue() === null) { return false }
    return this.isOrganizer
      ? false
      : this.match$.getValue().isType === 'swiss'
  }

  public get playerDropped(): boolean {
    if (this.match$.getValue() === null) { return false }
    const match = this.match$.getValue()
    const playerIs = match.player1.playerDocId === this.players.currentPlayersMini.id ? 'player1' : 'player2'
    return match[playerIs].drop
  }

  public get showDraw(): boolean {
    if (this.match$.getValue() === null) { return false }
    return !['bracket', 'bracket-team', 'double-bracket'].includes(this.match$.getValue().isType)
  }

  public icon = {
    plus: faPlus,
    minus: faMinus,
  }


}
