import { GlobalsService } from 'src/app/services/globals.service';
import { Component, Input } from '@angular/core';
import { faHandSparkles } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { MatchRoomOpenService } from 'src/app/private/play/matches/services/match-room-open.service';
import { TimestampClockComponent } from 'src/app/components/app-structure/timestamp-clock/timestamp-clock.component';
import { MessageItem } from '../../../services/message-list.service';

@Component({
  selector: 'app-match-invitation',
  templateUrl: './match-invitation.component.html',
  styleUrls: ['./match-invitation.component.css'],
  standalone: true,
  imports: [
    FontAwesomeModule,
    TimestampClockComponent,
  ]
})
export class MatchInvitationComponent {
  @Input() msg: MessageItem

  faBattle = faHandSparkles

  constructor(
    private globals: GlobalsService,
    private readonly matchRoom: MatchRoomOpenService,
  ) {
    console.log(this.globals.isMobile)
  }

  openMatch() {
    console.log(this.msg)
    this.matchRoom.openMatchRoom(this.msg.message.matchDocId)
  }

  public get mobileDevice(): string {
    return this.globals.isMobile ? 'mobile' : ''
  }

}
