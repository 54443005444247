import { IDeckList } from '../decks.service';
import { NgbCalendar, NgbDate } from '@ng-bootstrap/ng-bootstrap';
import { ToastService } from '../toast.service';
import { map, shareReplay, take } from 'rxjs/operators';
import { IPlayerMeta } from '../player.service';
import { firstValueFrom, Observable } from 'rxjs';
import { Injectable } from '@angular/core';
import { AngularFirestore, AngularFirestoreCollection } from '@angular/fire/compat/firestore';
import * as firestore from 'firebase/firestore'
import { AngularFireFunctions } from '@angular/fire/compat/functions';
import {
  EventAnnouncementRecipientType, IBatchConfig, IBracketSize, IDeckSubmission, IEventAnnouncementData,
  IEventDetails, IEventGroup, IEventLog, IEventPlayerDetails, IEventStructureSettings, IEventTeam, IFormat,
  IInvitedPlayer, IMatchData, IMatchPlayer, IMessageDocument, IMessageGroupDocument, INewEventForm,
  IPromiseResponse, MatchType
} from 'tolaria-cloud-functions/src/_interfaces';
import { AuthService, GlobalsService } from 'src/app/services';
import { IPlayersToMention } from '../../components/events/event-lobby/organizer/deck-submission-config/deck-submission-config.component';
import { v4 as uuidv4 } from 'node_modules/uuid';
import { IEventTimeMeta } from '../../components/events/event-round-timer/event-round-timer.component';
import { ProductType } from 'tolaria-cloud-functions/src/_interfaces';
import { Swiss, SwissTeam } from './swiss';
import { SingleElimination, SingleEliminationTeams } from './bracket';
import { EventMatch } from './match';
import { PlayerTieBreakers } from './tiebreakers';
import { PlayerNameService } from '../players/player-name.service';
import { PlayerMetaService } from '../players/player-meta.service';

export interface IEventLinkMeta {
  eventDocId: string;
  eventName: string;
}
export interface IEventStatusUpdate {
  statusText?: string;
  statusCode?: number;
  statusTimestamp?: number;
  activeRound?: number;
  activeRoundStartedTimestamp?: number;
  activeRoundEndingTimestamp?: number;
  log?: string[] | firestore.FieldValue;
}
export interface IEventListItem {
  name: string;
  locationName: string;
  locationUrl: string;
  organizerUid: string;
  coOrganizers: Array<string>;
  isMultiDay: boolean;
  datetime: string;
  datetimeFrom: string;
  datetimeTo: string;
  datestampFrom: number;
  datestampTo: number;
  GMT_offset: string;
  type: string;
  format: string;
  rulesetName: string;
  rulesetUrl: string;
  reprintPolicyName: string;
  reprintPolicyUrl: string;
  structure: string;
  isPublic: boolean;
  isPubliclyVisible: boolean;
  isOnline: boolean;
  isAttending: boolean;
  isInvited: boolean;
  isOrganizing: boolean;
  isEnded: boolean;
  deckList: boolean;
  deckPhoto: boolean;
  docId: string;
  statusCode: number;
  statusText: string;
  registrationOpen: boolean;
  registrationOpensTimestamp: number;
  registrationClosesTimestamp: number;
  playerDocIds: Array<string>;
  invitedPlayers?: Array<IInvitedPlayer>;
  banner?: string;
  playerCap: number;
  playerCapReached: boolean;
  registrationFee: boolean;
  registrationFeeAmount: string;
  swissTeams: boolean;
}
export interface IRoundTimeStamps {
  current: number;
  roundStarted: number;
  roundEnding: number;
}
export interface IPlayerDeckPhoto {
  downloadUrl: string;
  fileName: string;
}
export interface IPlayerDeckSubmission {
  deckListDocId: string;
  deckVersionDocId: string;
  fileName: string;
}

export interface IEventFilter {
  format: Array<IEventFilterSelectList>;
  type: Array<IEventFilterSelectList>;
  status: Array<IEventFilterSelectList>;
  dateInterval: Array<IEventFilterSelectList>;
  online: Array<IEventFilterSelectList>;
  public: Array<IEventFilterSelectList>;
  you: Array<IEventFilterSelectList>;
  organizerString: string;
  nameString: string;
  checker: {
    formatIsFiltered: boolean;
    typeIsFiltered: boolean;
    statusIsFiltered: boolean;
    dateIsFiltered: boolean;
    onlineIsFiltered: boolean;
    publicIsFiltered: boolean;
    youIsFiltered: boolean;
  };
  dateFrom: string;
  dateTo: string;
  dateRangeString: string;
}
export interface IEventFilterSelectList {
  selected: boolean;
  name: string;
}

export interface IEventViewSettings {
  messages: boolean;
  details: boolean;
  organizer: boolean;
  matchRoomMessages: boolean;
}

export enum PUSHTYPES {
  ROUND_PAIRINGS_POSTED = 'roundPaired',
  ROUND_STARTED = 'roundStarted'
}

interface EventGetterOption {
  open?: boolean
  registrationFee?: boolean
}

@Injectable({
  providedIn: 'root'
})
export class EventService {

  public statusText = [
    'Open for player registration',         // 0
    'Waiting for pairings',                 // 1
    'Round paired, waiting to start',       // 2
    'Waiting for results',                  // 3
    'All match results reported',           // 4
    'Swiss finished',                       // 5
    'Playoffs: Waiting for organizer',      // 6
    'Playoffs',                             // 7
    'Ended',                                // 8
    '',                                     // 9
    'Waiting for organizer',                // 10
    'Manual pairings in progress',          // 11
    'Batch finished',                       // 12
    'Organizer reviews the new pairings',   // 13
    'Registration opens ',                  // 14 --> dependent on registrationOpensTimestamp to look nice
  ];

  public bracketSizes = [4, 8, 16, 32];
  private formatCollection: AngularFirestoreCollection<IFormat>;
  public formats$: Observable<IFormat[]>;
  public initialized = false;

  public eventsCache$: Observable<IEventDetails[]>;
  public events$: Observable<IEventDetails[]>;
  public eventDocuments: Array<IEventDetails> = [];
  public usersPlayerDoc: IPlayerMeta;

  public filter: IEventFilter = {
    format: [],
    type: [
      {
        name: 'Limited',
        selected: false
      },
      {
        name: 'Constructed',
        selected: false
      }
    ],
    status: [
      {
        name: 'Open for registration',
        selected: false
      },
      {
        name: 'In progress',
        selected: false
      },
      {
        name: 'Ended',
        selected: false
      }
    ],
    dateInterval: [
      {
        name: 'Next 2 Days',
        selected: false
      },
      {
        name: 'Next 7 Days',
        selected: false
      },
      {
        name: 'This Week',
        selected: false
      },
      {
        name: 'This Weekend',
        selected: false
      },
      {
        name: 'Next Week',
        selected: false
      },
      {
        name: 'Next Weekend',
        selected: false
      },
      {
        name: 'All upcoming',
        selected: false
      },
      {
        name: 'Last 7 Days',
        selected: false
      },
      {
        name: 'Last 30 Days',
        selected: false
      },
      {
        name: 'All Past',
        selected: false
      }
    ],
    online: [
      {
        name: 'In person',
        selected: false
      },
      {
        name: 'Online',
        selected: false
      }
    ],
    public: [
      {
        name: 'Public',
        selected: false
      },
      {
        name: 'Private',
        selected: false
      }
    ],
    you: [
      {
        name: 'Attending',
        selected: false
      },
      {
        name: 'Following',
        selected: false
      }
    ],
    checker: {
      formatIsFiltered: false,
      typeIsFiltered: false,
      statusIsFiltered: false,
      dateIsFiltered: false,
      onlineIsFiltered: false,
      youIsFiltered: false,
      publicIsFiltered: false
    },
    organizerString: '',
    nameString: '',
    dateFrom: '',
    dateTo: '',
    dateRangeString: ''
  };

  public eventViewSettings: IEventViewSettings = {
    messages: true,
    details: true,
    organizer: true,
    matchRoomMessages: true,
  };

  constructor(
    private globals: GlobalsService,
    private auth: AuthService,
    private afs: AngularFirestore,
    private fns: AngularFireFunctions,
    private readonly playerName: PlayerNameService,
    private readonly playerMeta: PlayerMetaService,
    private toastService: ToastService,
    private calendar: NgbCalendar,
  ) {

    this.eventsCache$ = this.afs.collection<IEventDetails>('events', ref => ref
      .orderBy('details.datetime', 'asc')
    ).valueChanges();

    this.events$ = this.eventsCache$.pipe(shareReplay(1));

    this.formatCollection = this.afs.collection<IFormat>('formats', ref => ref
      .orderBy('name', 'asc')
    );
    this.formats$ = this.formatCollection.valueChanges();

    // get the users player document
    this.auth.user$
      .pipe(take(1))
      .subscribe(async (user) => {
        this.usersPlayerDoc = await this.playerMeta.getPlayerMeta(user.playerId)
        // add filter property for HOSTING as user is organizer
        if (user.role === 'admin' || user.role === 'organizer') {
          this.filter.you.push({
            name: 'Hosting',
            selected: false
          });
        }
      });


    this.formats$
      .pipe(
        map(formats => {
          const formatList: Array<IEventFilterSelectList> = [];
          formats.forEach(f => {
            const format: IEventFilterSelectList = {
              selected: false,
              name: f.name
            };
            formatList.push(format);
          });
          return formatList;
        }),
        take(1)
      )
      .subscribe(formats => {
        this.filter.format = formats;
        // console.log(JSON.stringify(this.filter));
      });

    // this.testingStuff()
  }

  private testingStuff(): void {

    this.events$.pipe(take(1)).subscribe(async (events) => {

      const response = {
        eventsByUser: {},
        openEvents: [],
        closedEvents: [],
        totalEvents: 0,
      }

      for await (const event of events) {

        if (event.statusCode === 8) { response.closedEvents.push(event) }
        else { response.openEvents.push(event) }

        const creator = this.playerName.getPlayerByUid(event.createdByUid).name.display
        if (!response.eventsByUser[creator]) {
          response.eventsByUser[creator] = {
            numberOfEvents: 0,
            events: []
          }
        }

        response.eventsByUser[creator].numberOfEvents++
        response.eventsByUser[creator].events.push(event)
      }

      response.openEvents.sort((a, b) => b.statusTimestamp - a.statusTimestamp)

      console.log(response)
    })
  }

  public playerActions = ({
    updateDeckSubmission: (eventDocId: string, deckSubmission: IDeckSubmission, deckSubmissionBefore: IDeckSubmission) => {
      this.afs.collection('events').doc(eventDocId).collection('players').doc(this.auth.user.playerId).update({
        deckSubmission: deckSubmission
      })
        .then(() => this.toastService.show('Deck submission saved', { classname: 'succss-toast', delay: 2000 }))
        .catch((error) => this.toastService.show(error, { classname: 'error-toast', delay: 6000 }))

      // perform updates on the previously submitted deck list
      if (deckSubmissionBefore.deckListDocId !== null && deckSubmissionBefore.deckListDocId !== undefined && deckSubmissionBefore.deckListDocId !== '') {
        // define document ref
        let docRefBefore = this.afs.collection('decks').doc(deckSubmissionBefore.deckListDocId);
        // check if previous submitted deck was a version and update document ref
        if (deckSubmissionBefore.deckVersionDocId !== null && deckSubmissionBefore.deckVersionDocId !== undefined && deckSubmissionBefore.deckVersionDocId !== '') {
          docRefBefore = this.afs.collection('decks').doc(deckSubmissionBefore.deckListDocId).collection('versions').doc(deckSubmissionBefore.deckVersionDocId);
        }
        // update deck/version
        docRefBefore.update({ eventDocIds: firestore.arrayRemove(eventDocId) })
          .then(() => console.log(`deckBefore: ${deckSubmissionBefore.deckListDocId}:${deckSubmissionBefore.deckVersionDocId} UPDATED`))
          .catch((error) => console.log(`deckBefore: ${error}`));
      }

      // perform updates on the newly submitted deck list
      if (deckSubmission.deckListDocId !== null && deckSubmission.deckListDocId !== undefined && deckSubmission.deckListDocId !== '') {
        // define document ref
        let docRef = this.afs.collection('decks').doc(deckSubmission.deckListDocId);
        // check if previous submitted deck was a version and update document ref
        if (deckSubmission.deckVersionDocId !== null && deckSubmission.deckVersionDocId !== undefined && deckSubmission.deckVersionDocId !== '') {
          docRef = this.afs.collection('decks').doc(deckSubmission.deckListDocId).collection('versions').doc(deckSubmission.deckVersionDocId);
        }
        // update deck/version
        docRef.update({ eventDocIds: firestore.arrayUnion(eventDocId) })
          .then(() => console.log(`deckAfter: ${deckSubmission.deckListDocId}:${deckSubmission.deckVersionDocId} UPDATED`))
          .catch((error) => console.log(`deckAfter: ${error}`));
      }
    }
  })

  getDateString(date: NgbDate): string {
    return date.year + '.' + this.pad2(date.month) + '.' + this.pad2(date.day);
  }

  public clearFilters() {
    Object.keys(this.filter.format).forEach(k => this.filter.format[k].selected = false);
    Object.keys(this.filter.type).forEach(k => this.filter.type[k].selected = false);
    Object.keys(this.filter.status).forEach(k => this.filter.status[k].selected = false);
    Object.keys(this.filter.dateInterval).forEach(k => this.filter.dateInterval[k].selected = false);
    Object.keys(this.filter.online).forEach(k => this.filter.online[k].selected = false);
    Object.keys(this.filter.public).forEach(k => this.filter.public[k].selected = false);
    Object.keys(this.filter.you).forEach(k => this.filter.you[k].selected = false);
    Object.keys(this.filter.checker).forEach(k => this.filter.checker[k] = false);

    const today = this.calendar.getToday();
    const future = this.calendar.getNext(today, 'd', 180);
    this.filter.dateFrom = this.getDateString(today);
    this.filter.dateTo = this.getDateString(future);
    this.filter.dateRangeString = this.filter.dateFrom + ' < > ' + this.filter.dateTo;
    this.filter.nameString = '';
    this.filter.organizerString = '';
  }
  public setFormatIsFiltered() {
    this.filter.checker.formatIsFiltered = this.filter.format.filter(i => i.selected).length > 0;
  }
  public setTypeIsFiltered() {
    this.filter.checker.typeIsFiltered = this.filter.type.filter(i => i.selected).length > 0;
  }
  public setStatusIsFiltered() {
    this.filter.checker.statusIsFiltered = this.filter.status.filter(i => i.selected).length > 0;
  }
  public setDateIsFiltered() {
    this.filter.checker.dateIsFiltered = this.filter.dateTo !== '' && this.filter.dateFrom !== '';
  }
  public setOnlineIsFiltered() {
    this.filter.checker.onlineIsFiltered = this.filter.online.filter(i => i.selected).length > 0;
  }
  public setYouIsFiltered() {
    this.filter.checker.youIsFiltered = this.filter.you.filter(i => i.selected).length > 0;
  }
  public setPublicIsFiltered() {
    this.filter.checker.publicIsFiltered = this.filter.public.filter(i => i.selected).length > 0;
  }
  public toggleFilterDate(keyName: string, keyValue: boolean) {
    Object.keys(this.filter.dateInterval).forEach(k => this.filter.dateInterval[k].selected = false);
    this.filter.dateInterval.find(k => k.name === keyName).selected = keyValue;
  }
  public get noFilters() {
    return Object.keys(this.filter.checker).filter(f => this.filter.checker[f]).length === 0;
  }
  getEventList(): Observable<IEventListItem[]> {
    // const query = this.afs.collection('events');

    const eventItems$ = this.events$.pipe(
      map(events => {
        return events.map((event) => {
          return this.mapEventListItem(event);
        });
      })
    );
    return eventItems$
  }
  getEventListItem(eventDocId: string): Observable<IEventListItem> {
    const eventItems$ = this.events$.pipe(
      map(events => {
        const event = events.find(e => e.docId === eventDocId);
        return this.mapEventListItem(event);
      })
    );
    return eventItems$;
  }
  getEventById(eventDocId: string): Observable<IEventDetails> {
    return this.afs.collection('events').doc<IEventDetails>(eventDocId).valueChanges();
  }
  getEventByIdPromise(eventDocId: string): Promise<IEventDetails> {
    return new Promise((resolve) => {
      this.afs.collection('events').doc<IEventDetails>(eventDocId).get().toPromise().then(data => resolve(data.data()))
    })

  }
  getEventNameById(eventDocId: string): Observable<string> {
    return this.events$.pipe(
      map(events => {
        return events.find(e => e.docId === eventDocId).details.name;
      })
    );
  }
  getEventNameByIdPromise(eventDocId: string): Promise<string> {
    return new Promise((resolve) => {
      this.events$.pipe(
        take(1),
        map(events => {
          const event = events.find(e => e.docId === eventDocId)
          return event === undefined ? 'UNKNOWN EVENT' : event.details.name
        })
      ).subscribe(name => {
        resolve(name)
      })
    })
  }
  getEventTeamsByEventId(eventDocId: string): Observable<IEventTeam[]> {
    return this.afs.collection('events').doc<IEventDetails>(eventDocId).collection<IEventTeam>('teams', ref => ref.orderBy('name')).valueChanges()
  }
  getEventPlayersByEventId(eventDocId: string): Observable<IEventPlayerDetails[]> {
    return this.afs.collection('events').doc<IEventDetails>(eventDocId).collection<IEventPlayerDetails>('players', ref => ref.orderBy('name')).valueChanges();
  }
  async getEventPlayersByEventIdPromise(eventDocId: string): Promise<IEventPlayerDetails[]> {
    const colRef = this.afs.collection('events').doc<IEventDetails>(eventDocId).collection<IEventPlayerDetails>('players', ref => ref.orderBy('name'));
    return colRef.get().toPromise().then((data) => {
      const playerList: IEventPlayerDetails[] = [];
      data.docs.forEach((doc) => {
        playerList.push(doc.data());
      });
      return playerList;
    });
  }
  getEventsByCreator(playerUid: string, options: EventGetterOption): Promise<IEventDetails[]> {
    return new Promise((resolve) => {
      this.events$
        .pipe(
          map(events => {
            let list: IEventDetails[] = events.filter(e => e.createdByUid === playerUid)
            if (options.open) {
              list = list.filter(e => e.statusCode !== 8)
            }
            if (options.registrationFee) {
              list = list.filter(e => e.details.registrationFee && e.details.registrationFee.active)
            }
            return list
          }),
          take(1)
        )
        .subscribe(events => resolve(events))
    })
  }
  mapEventListItem(event: IEventDetails) {
    const eventData: IEventListItem = {
      docId: event.docId,
      name: event.details.name,
      locationName: event.details.location.name,
      locationUrl: event.details.location.url,
      organizerUid: event.createdByUid,
      isMultiDay: event.details.isMultiDay !== undefined
        ? event.details.isMultiDay
        : false,
      datetime: event.details.datetime,
      datetimeFrom: event.details.datetimeFrom,
      datetimeTo: event.details.datetimeTo,
      datestampFrom: event.details.datestampFrom,
      datestampTo: event.details.datestampTo,
      GMT_offset: event.details.GMT_offset !== undefined
        ? event.details.GMT_offset
        : '',
      type: event.details.type,
      format: event.details.format,
      rulesetName: event.details.ruleset.name,
      rulesetUrl: event.details.ruleset.url,
      reprintPolicyName: event.details.reprintPolicy.name,
      reprintPolicyUrl: event.details.reprintPolicy.url,
      structure: this.getStructureText(event.details.structure),
      isPublic: event.details.isPublic,
      isPubliclyVisible: event.details?.isPubliclyVisible,
      isOnline: event.details.isOnlineTournament,
      isAttending: event.playerDocIds.includes(this.auth.user.playerId)
        || event.createdByUid === this.auth.user.playerId
        || event?.coOrganizers?.includes(this.auth.user.playerId)
        || event?.coOrganizers?.includes(this.auth.user.uid),
      isInvited: event.details?.isPublic
        ? true
        : event.invitedPlayers.findIndex(p => p.playerDocId === this.auth.user.playerId) > -1
        || event.createdByUid === this.auth.user.uid
        || event?.coOrganizers?.includes(this.auth.user.playerId)
        || event?.coOrganizers?.includes(this.auth.user.uid),
      isOrganizing: event.createdByUid === this.auth.user.uid,
      coOrganizers: event?.coOrganizers
        ? event.coOrganizers
        : [],
      isEnded: event.statusCode === 8,
      statusCode: event.statusCode,
      statusText: event.statusText,
      registrationOpen: event.details.registrationOpen,
      registrationOpensTimestamp: event.details.registrationOpensTimestamp
        ? event.details.registrationOpensTimestamp
        : null,
      registrationClosesTimestamp: event.details.registrationClosesTimestamp
        ? event.details.registrationClosesTimestamp
        : null,
      playerDocIds: event.playerDocIds,
      invitedPlayers: event.invitedPlayers,
      deckList: event.details.deckList,
      deckPhoto: event.details.deckPhoto,
      playerCap: 0,
      playerCapReached: false,
      registrationFee: event.details?.registrationFee?.active,
      registrationFeeAmount: event.details?.registrationFee?.active
        ? event.details?.registrationFee?.amount + ' ' + event.details?.registrationFee?.currency.toUpperCase()
        : null,
      swissTeams: event.details.structure.swiss?.teams
        ? event.details.structure.swiss.teams
        : false
    };

    // backward compability before [ Date and Time Interval for Events #147 | https://github.com/Slanfan/MTG-Tolaria/issues/147 ]
    if (event.details?.dateStamp) {
      // eslint-disable-next-line @typescript-eslint/dot-notation
      eventData.datestampFrom = event.details['dateStamp'];
    }
    if (!event.details?.datetimeFrom) {
      // eslint-disable-next-line @typescript-eslint/dot-notation
      eventData.datetimeFrom = event.details['datetime'];
    }

    // set player cap setting
    if (event.details?.hasAttendeeCap && event.details.attendeeCap > 0) {
      eventData.playerCap = event.details.attendeeCap;
      if (event.playerDocIds.length === event.details.attendeeCap) {
        eventData.playerCapReached = true;
      }
    }

    if (event?.bannerUrl) {
      eventData.banner = event.bannerUrl;
    }
    else {
      eventData.banner = 'assets/banners/' + eventData.format.toLocaleLowerCase().replace(/ /g, '-') + '.default.jpg';
    }

    return eventData;
  }
  createEvent(eventDetails: INewEventForm, type: string): Promise<IPromiseResponse> {
    switch (type) {
      case 'swiss':
        break;
      case 'bracket':
        break;
      case 'group':
        break;
      case 'round-robin':
        break;
      case 'batch':
        break;
    }

    // eventDetails.dateStamp = Date.parse(eventDetails.datetime);

    const guid = uuidv4();
    const eventData: IEventDetails = {
      docId: guid,
      details: eventDetails,
      createdByUid: this.auth.user.uid,
      coOrganizers: [],
      activeRound: 0,
      statusCode: 0,
      statusText: 'Open for player registration',
      startingTable: eventDetails.startingTable,
      isOnlineTournament: eventDetails.isOnlineTournament,
      roundTimer: eventDetails.roundTimer,
      playerDocIds: [],
      invitedPlayers: [],
      log: [],
      isArchived: false,
      isDeleted: false,
    };

    // check if registration should be closed at creation
    if (!eventDetails.registrationOpen) {
      eventData.statusCode = 14;
      eventData.statusText = this.statusText[14];
    }
    // console.log(eventData);

    return new Promise(async (resolve, reject) => {
      // write the event document
      await this.afs.firestore
        .collection('events')
        .doc(guid)
        .set(eventData)
        .then(() => console.log('event document created'))
        .catch((error) => {
          console.log('Error', error);
          resolve({
            status: false,
            text: error
          });
        });

      console.log('Success');

      // create message group
      const messageGroupDocId = 'eventChatFor[' + guid + ']';
      // create event message group
      const messageGroup: IMessageGroupDocument = {
        docId: messageGroupDocId,
        name: 'EVENT: ' + eventData.details.name,
        createdByUid: eventData.createdByUid,
        createdDate: firestore.Timestamp.now().seconds,
        latestMessage: firestore.Timestamp.now().seconds,
        latestMessagePreview: '',
        playerDocIds: [
          this.auth.user.playerId
        ],
        isSingle: false
      };
      // write the message group document
      await this.afs
        .collection('messageGroups')
        .doc(messageGroupDocId)
        .set(messageGroup)
        .then(() => console.log('event message group created'))
        .catch((error) => {
          console.log('Error', error);
          resolve({
            status: false,
            text: error
          });
        });


      // send an initial message to the group
      console.log('about to send a message');
      let initMessage = '';
      initMessage += '<h3>Welcome to the message group for the event - ' + eventData.details.name + '</h3>';
      initMessage += '<p>Be kind to one another and stay tuned for announcements from the organizer here.</p>';
      const message: IMessageDocument = {
        content: {
          matchDoc: null,
        },
        matchChat: false,
        matchDocId: '', // only available if the message is connected to a match chat
        mentionedPlayerDocIds: [],
        message: initMessage,
        messageGroupDocId,
        playerDocId: this.auth.user.playerId,
        playerUid: this.auth.user.uid,
        spectatorMode: false, // only available if the message is connected to a match chat
        timestamp: firestore.Timestamp.now().seconds,
        type: 'chat-message',
        whisperMode: null,
        archived: false,
      };
      await this.afs
        .collection('messages')
        .add(message)
        .then(() => console.log('message sent'))
        .catch((error) => {
          console.log('Error', error);
          resolve({
            status: false,
            text: error
          });
        });

      resolve({
        status: true,
        text: 'successfuly added a new event document with id ' + guid
      });

    });
  }
  updateEventData(eventDetails: IEventDetails): any {
    return new Promise((resolve, reject) => {
      this.afs
        .collection('events')
        .doc(eventDetails.docId)
        .set(eventDetails)
        .then(() => {
          resolve({
            status: true,
            text: 'successfuly updated event data'
          });
        })
        .catch((error) => {
          console.log('Error', error);
          reject({
            status: false,
            text: error
          });
        });
    });
  }
  addInvitedPlayer(eventDocId: string, event: IEventDetails, emailAddress: string, sendEmail: boolean, sendMessage: boolean, customMessage: string): any {
    return new Promise((resolve, reject) => {
      console.log('starting the invitation process');
      // create the document data
      const invitedPlayer: IInvitedPlayer = {
        email: emailAddress,
        accepted: false,
        declined: false,
        isRegistered: false,
        playerUid: '',
        playerDocId: '',
        sendMessage,
        sendEmail,
        eventDocId: event.docId
      };
      // check if email belongs to a registered player
      console.log('calling player service');
      let playerMini = this.playerName.getPlayerByEmail(emailAddress)
      if (playerMini === null) {
        // set this tolaria message to false as the user is NOT a registered user.
        invitedPlayer.sendMessage = false;
      }
      else {
        // update document data
        invitedPlayer.playerDocId = playerMini.id;
        invitedPlayer.playerUid = playerMini.uid;
        invitedPlayer.isRegistered = true;
        // define a specific message document id to be used when sending the Tolaria message
        const guid = uuidv4();
        invitedPlayer.messageDocId = guid;
      }

      // save data to firebase
      console.log('calling firebase to update the invited players array', invitedPlayer);
      this.afs.collection('events').doc(eventDocId).update({
        invitedPlayers: firestore.arrayUnion(invitedPlayer)
      })
        .then(async () => {
          console.log('returned from firebase, checking if messages should be sent out');

          // send EMAIL
          if (invitedPlayer.sendEmail) {
            const organizer = this.playerName.getPlayerByUid(event.createdByUid)
            console.log('send email by callable firebase.functions');
            // call firebase function
            const callable = this.fns.httpsCallable('send-eventInvitation');
            await callable({
              event,
              invitedPlayer,
              organizer: {
                name: organizer.name.display,
                message: customMessage
              }
            })
              .toPromise().then((res: IPromiseResponse) => {
                console.log('response from firebase.functions', res);
              })
              .catch((error: any) => {
                reject({
                  status: false,
                  text: error
                });
              });
          }

          // send Tolaria MESSAGE
          if (invitedPlayer.sendMessage) {

            // add the player to the message group doc id
            this.afs
              .collection('messageGroups')
              .doc(`eventChatFor[${eventDocId}]`)
              .update({
                playerDocIds: firestore.arrayUnion(invitedPlayer.playerDocId)
              })
              .catch((error) => {
                console.log(error);
              });


            // resolve
            resolve({
              status: true,
              text: 'success',
              sendTolariaMessage: true,
              messageDocId: invitedPlayer.messageDocId,
              invitedPlayer
            });
          }

          resolve({
            status: true,
            text: 'success',
            sendTolariaMessage: false
          });
        })
        .catch((error: any) => {
          reject({
            status: false,
            text: error
          });
        });

    });
  }
  answerInvitation(accepted: boolean, invitedPlayer: IInvitedPlayer, eventDocId: string, playerDocId: string): Promise<IPromiseResponse> {
    console.log(`Lets answer the event invitation for event with id: ${eventDocId}. Player with id ${playerDocId} accepted: ${accepted}`);
    return new Promise(async (resolve) => {
      // log activity
      // get name if not present already
      let player = this.playerName.getPlayerById(playerDocId)
      const responseText = accepted ? 'accepted' : 'declined';
      const timestamp = firestore.Timestamp.now().seconds;
      const logText: IEventLog = {
        timestamp,
        type: 'invitation',
        text: `${player.name.display} ${responseText} the event invitation`,
        metadata: {
          playerDocId,
          playerName: player.name.display,
          answer: responseText,
        }
      };

      const colRef = this.afs.firestore.collection('events').doc(eventDocId);
      const newInvitedPlayer = JSON.parse(JSON.stringify(invitedPlayer));
      newInvitedPlayer.accepted = accepted;
      newInvitedPlayer.declined = !accepted;

      // first remove the invited player
      colRef
        .update({
          invitedPlayers: firestore.arrayRemove(invitedPlayer),
        })
        .then(() => {
          const batch = this.afs.firestore.batch()

          batch.update(this.afs.collection('events').doc(eventDocId).ref, {
            invitedPlayer: firestore.arrayUnion(newInvitedPlayer)
          })
          batch.set(this.afs.collection('events').doc(eventDocId).collection('log').doc(`log-${uuidv4()}`).ref, logText)
          batch.commit()
            .then(() => {
              // add the player to the event if the player accepted
              if (accepted) {
                this.addPlayerToEvent(eventDocId, this.auth.user.uid, playerDocId, null);
              }
              resolve({
                status: true,
                text: 'Player successfully responded to event invitation'
              });
            })
            .catch((error) => {
              resolve({
                status: false,
                text: error
              });
            });
        })
        .catch((error) => {
          resolve({
            status: false,
            text: error
          });
        });
    });
  }
  addPlayerToEvent(eventDocId: string, playerUid: string, playerDocId: string, name: string, group: string = null): Promise<IPromiseResponse> {
    return new Promise(async (resolve, reject) => {

      // get name if not present already
      if (name === null) {
        name = this.playerName.getPlayerById(playerDocId).name.display

      }
      // get user adding player
      const organizer = this.playerName.getPlayerById(this.auth.user.playerId).name.display

      // log activity
      const timestamp = firestore.Timestamp.now().seconds;
      const logText: IEventLog = {
        type: 'attending',
        timestamp,
        text: `${name} added to the event by user ${organizer}`,
        metadata: {
          playerDocId,
          playerName: name,
          addedByDocId: this.auth.user.playerId,
          addedByName: organizer,
        }
      }

      // create a batch for writing
      const batch = this.afs.firestore.batch()
      const eventDocUri = this.afs.collection('events').doc(eventDocId)

      // add the player document id to the event document
      batch.update(eventDocUri.ref, {
        playerDocIds: firestore.arrayUnion(playerDocId)
      })

      // add the log entry
      batch.set(eventDocUri.collection('log').doc(`log-${uuidv4()}`).ref, logText)

      // add the player to the message group for the event
      if (playerDocId.substring(0, 6) !== 'temp__') {
        batch.update(this.afs.collection('messageGroups').doc(`eventChatFor[${eventDocId}]`).ref, {
          playerDocIds: firestore.arrayUnion(playerDocId)
        })
      }

      // add the player to the event players collection
      const eventPlayer = await this.createPlayerData(playerUid, playerDocId, name, group).then((newPlayerData: IEventPlayerDetails) => newPlayerData)
      batch.set(this.afs.collection('events').doc(eventDocId).collection('players').doc(playerDocId).ref, eventPlayer)

      // commit the batch of writes
      batch.commit()
        .then(() => {
          this.toastService.show('Player attended', { classname: 'success-toast' })
          resolve({
            status: true,
            text: 'player list successfully updated'
          })
        })
        .catch((error) => {
          this.toastService.show(error, { classname: 'error-toast', delay: 10000 })
          resolve({
            status: false,
            text: error,
          })
        })
    });
  }
  /**
   * Add multiple non-Tolaria players to an event
   *
   * @param eventDocId document number of the event
   * @param playerNames string with player names to be added
   *
   * @returns boolean
   */
  addMultiplePlayersToEvent(eventDocId: string, playerNames: string[], group: string = null): Promise<IPromiseResponse> {
    return new Promise(async (resolve) => {

      // batch processing variables
      const batchWrites: firebase.default.firestore.WriteBatch[] = []
      let currentWriteIndex = 0
      let currentBatchIndex = 0

      // get user adding player
      console.log('...getting event organizer')
      const organizer = this.playerName.getPlayerById(this.auth.user.playerId).name.display

      // local method to icrement batch variables
      const __incrementWriteCounter = () => {
        console.log('...incremeting write index')
        if ((currentWriteIndex % 500) === 0 && (currentWriteIndex / 500) === (currentBatchIndex + 1)) {
          console.log('...incremeting batch index')
          batchWrites.push(this.afs.firestore.batch())
          currentBatchIndex++
        }
        currentWriteIndex++
      }

      // create first batch
      console.log('...creating the first batch for writes to firestore')
      batchWrites.push(this.afs.firestore.batch())

      // loop through names to create documents
      console.log('...starting player loop')
      for await (const name of playerNames) {

        // player variables
        const playerDocId = 'temp__' + name.toLowerCase()
        const playerUid = 'temp__' + name.toLowerCase()

        // log activity
        const timestamp = firestore.Timestamp.now().seconds;
        const logText: IEventLog = {
          timestamp,
          type: 'attending',
          text: `${name} added to the event by user ${organizer}`,
          metadata: {
            playerDocId,
            playerName: name,
            addedByDocId: this.auth.user.playerId,
            addedByName: organizer,
          }
        }

        // add player to event document and add log message
        __incrementWriteCounter()
        console.log('...adding player to event and write log')
        const eventRef = this.afs.collection('events').doc(eventDocId).ref
        batchWrites[currentBatchIndex].update(eventRef, {
          playerDocIds: firestore.arrayUnion(playerDocId),
        })
        batchWrites[currentBatchIndex].set(this.afs.collection('events').doc(eventDocId).collection('log').doc(`log-${uuidv4()}`).ref, logText)

        // add player to the event sub collection of players
        __incrementWriteCounter()
        console.log('...adding player to event players collection')
        const playersRef = this.afs.collection('events').doc(eventDocId).collection('players').doc(playerDocId).ref
        const playerDoc = await this.createPlayerData(playerUid, playerDocId, name, group)
        console.log(playerDoc)
        batchWrites[currentBatchIndex].set(playersRef, playerDoc)

        // app playerDocId to the event messageGroup unless it's a temp player
        if (playerDocId.substring(0, 6) !== 'temp__') {
          __incrementWriteCounter()
          console.log('...adding player to message group for event')
          const messageGroupRef = this.afs.collection('messageGroups').doc('eventChatFor[' + eventDocId + ']').ref
          batchWrites[currentBatchIndex].update(messageGroupRef, {
            playerDocIds: firestore.arrayUnion(playerDocId)
          })
        }

      }

      // perform writes
      console.log('...writing event player documetns as well as event updates to firebase')
      for await (const [index, batch] of batchWrites.entries()) {
        batch
          .commit()
          .then(() => console.log(`...writing of batch #${index + 1} successful`))
          .catch((e) => {
            console.log(e)
            resolve({
              status: false,
              text: e,
            })
          })
      }


      resolve({
        status: true,
        text: 'All players added to the event'
      })



    })
  }
  removePlayerFromEvent(eventDocId: string, playerDocId: string, playerName: string = null): Promise<IPromiseResponse> {
    return new Promise(async (resolve, reject) => {

      // log activity
      // get name if not present already
      const name = playerName === null
        ? this.playerName.getPlayerById(playerDocId).name.display
        : playerName
      // get user adding player
      const organizer = this.playerName.getPlayerById(this.auth.user.playerId).name.display
      const timestamp = firestore.Timestamp.now().seconds;
      const logText: IEventLog = {
        timestamp,
        type: 'attending',
        text: `${name} removed from the event by ${organizer}`,
        metadata: {
          playerDocId,
          playerName: name,
          addedByDocId: this.auth.user.playerId,
          addedByName: organizer,
        }
      }
      // log: firestore.arrayUnion(logText)

      // get player document
      const playerSnap = await this.afs.collection('events').doc(eventDocId).collection('players').doc<IEventPlayerDetails>(playerDocId).get().toPromise();
      if (!playerSnap.exists) {
        console.log('no player document found, skipping');
        this.toastService.show(`Player not found, please try again`, { classname: 'error-toast', delay: 6000 });
        resolve({
          status: false,
          text: 'no player document found, skipping'
        });
      }
      const playerDoc = playerSnap.data() as IEventPlayerDetails;

      // remove player doc id from eventDoc
      await this.afs
        .collection('events')
        .doc(eventDocId)
        .update({
          playerDocIds: firestore.arrayRemove(playerDocId),
        })
        .then(() => {
          console.log('player removed from eventDoc > playerDocIds');
        })
        .catch((error) => {
          console.log(error);
          resolve({
            status: false,
            text: error
          });
        });

      // add log entry
      await this.afs
        .collection('events')
        .doc(eventDocId)
        .collection('log')
        .doc(`log-${uuidv4()}`)
        .set(logText)
        .then(() => {
          console.log('log added to event log');
        })
        .catch((error) => {
          console.log(error);
          resolve({
            status: false,
            text: error
          });
        });


      // remove player from messageGroupDpc
      await this.afs
        .collection('messageGroups')
        .doc('eventChatFor[' + eventDocId + ']')
        .update({
          playerDocIds: firestore.arrayRemove(playerDocId)
        })
        .then(() => {
          console.log('player removed from messageGroupdDoc > playerDocIds');
        })
        .catch((error) => {
          console.log(error);
          resolve({
            status: false,
            text: error
          });
        });

      // check if player has submitted a deck, and if so, remove the connection to this event
      if (playerDoc.deckSubmission.deckListDocId !== null && playerDoc.deckSubmission.deckListDocId !== undefined && playerDoc.deckSubmission.deckListDocId !== '') {
        console.log('deck list registered, unlinking');
        await this.afs.collection('decks').doc(playerDoc.deckSubmission.deckListDocId).update({
          eventDocIds: firestore.arrayRemove(eventDocId)
        });
      }
      else {
        console.log('deck list NOT registered, nothing to update')
      }

      // delete the player document
      await this.afs.collection('events').doc(eventDocId).collection('players').doc(playerDocId).delete()
        .then(() => {
          console.log('playerDoc removed from eventDoc > playerCollection');
        })
        .catch((error) => {
          console.log(error);
          resolve({
            status: false,
            text: error
          });
        });

      console.log('resolving with status: ', true);
      resolve({
        status: true,
        text: 'player list successfully updated'
      });

    });
  }
  dropPlayerFromEvent(eventDocId: string, playerDocId: string): Promise<IPromiseResponse> {
    return new Promise((resolve, reject) => {
      this.afs
        .collection('events')
        .doc(eventDocId)
        .collection('players')
        .doc(playerDocId)
        .update({
          dropped: true
        })
        .then(() => {
          resolve({
            status: true,
            text: 'player successfully dropped'
          });
        })
        .catch((error) => {
          reject({
            status: false,
            text: error
          });
        });
    });
  }
  dropPlayerFromEventAndDeleteMatches(eventDocId: string, playerDocId: string, eventData: IEventDetails): Promise<IPromiseResponse> {
    return new Promise(async (resolve) => {

      // #1 Drop the player
      console.log(`dropping player`);
      const dropped = await this.dropPlayerFromEvent(eventDocId, playerDocId);
      if (dropped.status) {
        console.log(dropped.text);
      }
      else {
        resolve({
          status: false,
          text: dropped.text
        });
      }
      // #2 Delete all connected matches and update the opponents "opponentsDocIds" list
      const deleted = await this.deletePlayerMatchesForEvent(eventDocId, playerDocId);
      if (deleted.status) {
        console.log(deleted.text);
      }
      else {
        resolve({
          status: false,
          text: deleted.text
        });
      }
      // #3 Recalculate stats
      const calculated = await this.calculateStats(eventData, false);
      if (calculated.status) {
        console.log(calculated.text);
      }
      else {
        resolve({
          status: false,
          text: calculated.text
        });
      }
      // #4 resolve
      resolve({
        status: true,
        text: 'Player dropped, all matches deleted and stats re-calculated'
      });

    });
  }
  /**
   * Mark all matches in an event for a specific player as inactive
   *
   * @param eventDocId  the event document id
   * @param playerDocId the player document id
   * @returns <IPromiseResponse>
   */
  deletePlayerMatchesForEvent(eventDocId: string, playerDocId: string): Promise<IPromiseResponse> {
    return new Promise((resolve) => {
      const colref = this.afs.collection<IMatchData>('matches', ref => ref
        .where('eventDocId', '==', eventDocId)
        .where('playerDocIds', 'array-contains', playerDocId));
      colref.get().subscribe(async (snap) => {
        if (!snap.empty) {
          const batch = this.afs.firestore.batch()
          let counter = 0
          for await (const doc of snap.docs) {
            if (doc.exists) {
              counter++
              batch.update(doc.ref, {
                deleted: true
              })
            }
          }
          batch.commit()
            .then(() => {
              resolve({
                status: true,
                text: `${counter} match documents inactivated`,
              })
            })
            .catch((error) => {
              console.log(error)
              resolve({
                status: false,
                text: error
              })
            })
        }
        else {
          resolve({
            status: false,
            text: 'No match documents found'
          });
        }
      });
    });
  }
  /**
   *
   * @param eventData event data model
   * @param eventDocId event document id
   * @param droppingPlayerDocId player document id of player being dropped
   * @param addingPlayerDocId player document id of player being added
   * @param tolariaPlayer is the player being added a tolarian?
   * @returns defaultpromise response
   */
  exchangePlayerWithPlayerInEvent(
    eventData: IEventDetails,
    eventDocId: string,
    droppingPlayerDocId: string,
    addingPlayerDocId: string,
    tolariaPlayer: boolean
  ): Promise<IPromiseResponse> {
    return new Promise(async (resolve) => {
      console.log(`about to drop ${droppingPlayerDocId} and add ${addingPlayerDocId}`);
      // define the player properties
      let newPlayerName = '';
      let newPlayerDocId = '';
      let newPlayerUid = '';

      if (tolariaPlayer) {
        // get player meta
        console.log(`fetching player meta`);
        const playerMini = this.playerName.getPlayerById(addingPlayerDocId)
        newPlayerName = playerMini.name.display
        newPlayerDocId = playerMini.id
        newPlayerUid = playerMini.uid
      }
      else {
        newPlayerName = addingPlayerDocId;
        newPlayerDocId = 'temp__' + addingPlayerDocId;
        newPlayerUid = 'temp__' + addingPlayerDocId;
      }

      // #1 drop the dropping player
      console.log(`dropping player ${droppingPlayerDocId}`);
      const dropped = await this.dropPlayerFromEvent(eventDocId, droppingPlayerDocId);
      if (dropped.status) {
        console.log(dropped.text);
      }
      else {
        console.log(`error dropping player --> `, dropped.text)
        resolve({
          status: false,
          text: dropped.text
        });
      }

      // #2 add the new player
      console.log(`adding player ${newPlayerDocId}`);
      const addPlayer = await this.addPlayerToEvent(eventDocId, newPlayerUid, newPlayerDocId, newPlayerName);
      if (addPlayer.status) {
        console.log(addPlayer.text);
      }
      else {
        console.log(`error adding new player --> `, dropped.text)
        resolve({
          status: false,
          text: addPlayer.text
        });
      }

      // #3 update all matches with the new playerX.playerDocId, playerX.playerUid, playerX.name, playerDocIds, and playerFilterValue. Also, reset the result
      console.log(`update all matches with new player`);
      const colRef = this.afs.collection<IMatchData>('matches', ref => ref
        .where('eventDocId', '==', eventDocId)
        .where('playerDocIds', 'array-contains', droppingPlayerDocId));

      const snap = await firstValueFrom(colRef.get())
      if (snap.empty) {
        resolve({
          status: false,
          text: 'No matches found. Nothing to update'
        });
      }

      for await (const doc of snap.docs) {
        const matchData = doc.data() as IMatchData;
        const droppingPlayerIs = droppingPlayerDocId === matchData.player1.playerDocId ? 'player1' : 'player2';
        const opponentIs = droppingPlayerDocId === matchData.player1.playerDocId ? 'player2' : 'player1';
        // update the main properties
        matchData[droppingPlayerIs].displayName = newPlayerName;
        matchData[droppingPlayerIs].playerDocId = newPlayerDocId;
        matchData[droppingPlayerIs].playerUid = newPlayerUid;
        matchData.playerFilterValue = matchData.player1.displayName + ' ' + matchData.player2.displayName + ' ' + matchData.tableNumber + ' ' + matchData.segmentNumber;
        matchData.playerDocIds = [matchData[opponentIs].playerDocId, newPlayerDocId];
        // reset the result as long as its not a BYE or LOSS match
        if (!matchData.isLossMatch && !matchData.isByeMatch) {
          matchData.isByeMatch = false;
          matchData.isDraw = false;
          matchData.isLossMatch = false;
          matchData.isReported = false;
          matchData.player1.draws = 0;
          matchData.player1.isWinner = false;
          matchData.player1.drop = false;
          matchData.player1.lifePoints = [20],
            matchData.player1.losses = 0;
          matchData.player1.matchPoints = 0;
          matchData.player1.wins = 0;
          matchData.player2.draws = 0;
          matchData.player2.isWinner = false;
          matchData.player2.drop = false;
          matchData.player2.lifePoints = [20],
            matchData.player2.losses = 0;
          matchData.player2.matchPoints = 0;
          matchData.player2.wins = 0;
        }
        // update the document
        await doc.ref.update(matchData);
      }


      // colRef.get().subscribe(async (snap) => {
      //   if (snap.docs.length > 0) {
      //     for await (const doc of snap.docs) {
      //       const matchData = doc.data() as IMatchData;
      //       const droppingPlayerIs = droppingPlayerDocId === matchData.player1.playerDocId ? 'player1' : 'player2';
      //       const opponentIs = droppingPlayerDocId === matchData.player1.playerDocId ? 'player2' : 'player1';
      //       // update the main properties
      //       matchData[droppingPlayerIs].displayName = newPlayerName;
      //       matchData[droppingPlayerIs].playerDocId = newPlayerDocId;
      //       matchData[droppingPlayerIs].playerUid = newPlayerUid;
      //       matchData.playerFilterValue = matchData.player1.displayName + ' ' + matchData.player2.displayName + ' ' + matchData.tableNumber + ' ' + matchData.segmentNumber;
      //       matchData.playerDocIds = [matchData[opponentIs].playerDocId, newPlayerDocId];
      //       // reset the result as long as its not a BYE or LOSS match
      //       if (!matchData.isLossMatch && !matchData.isByeMatch) {
      //         matchData.isByeMatch = false;
      //         matchData.isDraw = false;
      //         matchData.isLossMatch = false;
      //         matchData.isReported = false;
      //         matchData.player1.draws = 0;
      //         matchData.player1.isWinner = false;
      //         matchData.player1.drop = false;
      //         matchData.player1.lifePoints = [20],
      //           matchData.player1.losses = 0;
      //         matchData.player1.matchPoints = 0;
      //         matchData.player1.wins = 0;
      //         matchData.player2.draws = 0;
      //         matchData.player2.isWinner = false;
      //         matchData.player2.drop = false;
      //         matchData.player2.lifePoints = [20],
      //           matchData.player2.losses = 0;
      //         matchData.player2.matchPoints = 0;
      //         matchData.player2.wins = 0;
      //       }
      //       // update the document
      //       await doc.ref.update(matchData);
      //     }
      //   }
      //   else {
      //     resolve({
      //       status: false,
      //       text: 'No matches found. Nothing to update'
      //     });
      //   }
      // });

      // #4 Recalculate stats
      console.log(`re-calculate stats`);
      const calculated = await this.calculateStats(eventData, false);
      if (calculated.status) {
        console.log(calculated.text);
      }
      else {
        resolve({
          status: false,
          text: calculated.text
        });
      }

      // #5 Done
      console.log(`done`);
      resolve({
        status: true,
        text: 'Player dropped and new player added, all matches resetted and updated with new player. Stats re-calculated'
      });

    })
  }
  undropPlayerFromEvent(eventDocId: string, playerDocId: string): any {
    return new Promise((resolve, reject) => {
      this.afs
        .collection('events')
        .doc(eventDocId)
        .collection('players')
        .doc(playerDocId)
        .update({
          dropped: false
        })
        .then(() => {
          resolve({
            status: true,
            text: 'player successfully undropped'
          });
        })
        .catch((error) => {
          reject({
            status: false,
            text: error
          });
        });
    });
  }
  eventUpdateStatus(eventData: IEventDetails, statusCode: number, newRound: boolean = false) {
    // set app busy and show info text
    this.globals.isBusy.status = true;
    this.globals.isBusy.message = 'Updating event status...';

    return new Promise(async (resolve, reject) => {

      if (statusCode === 0) {
        // remove all rounds except mockup round
        // remove all byes for players
        // remove all matches for players
      }

      // log activity
      const userName = this.playerName.currentPlayersMini.name.display
      const timestamp = firestore.Timestamp.now().seconds;
      const logText: IEventLog = {
        timestamp,
        type: 'status-update',
        text: 'Status changed to (' + statusCode + ') ' + this.statusText[statusCode] + ' for round ' + eventData.activeRound + ' by ' + userName,
        metadata: {
          playerDocId: this.auth.user.playerId,
          playerUid: this.auth.user.uid,
          playerName: userName,
        }
      };

      // store active round
      let activeRound = eventData.activeRound;
      // check if new round is about to start
      if (newRound) { activeRound = eventData.activeRound + 1; }
      // update firebase
      const updateObj: IEventStatusUpdate = {
        statusText: this.statusText[statusCode],
        statusCode,
        statusTimestamp: timestamp,
        activeRound,
        // log: firestore.arrayUnion(logText)
      };
      this.updateFirebase(eventData, updateObj, logText)
        .then((response: IPromiseResponse) => {
          this.globals.isBusy.message = response.text;
          this.globals.isBusy.status = false;
          resolve(response);
        })
        .catch((err) => {
          console.log(err);
          this.globals.isBusy.message = err;
          this.globals.isBusy.status = false;
          resolve({
            status: false,
            text: err
          });
        });
    });
  }
  updateFirebase(eventData: IEventDetails, updateObj: IEventStatusUpdate, log: IEventLog) {
    return new Promise((resolve, reject) => {
      // const timestamp = firestore.Timestamp.now().seconds;
      // const colRef = this.afs.collection('events').doc(eventData.docId);

      // if (updateObj.statusCode && updateObj.statusCode === 3) {
      //   const roundSeconds = eventData.details.roundTimer * 60;
      //   updateObj.activeRoundStartedTimestamp = timestamp;
      //   updateObj.activeRoundEndingTimestamp = timestamp + roundSeconds;
      // }

      // colRef.update(updateObj)
      //   .then(() => {
      //     resolve({
      //       status: true,
      //       text: 'event status successfully updated'
      //     });
      //   })
      //   .catch(error => {
      //     reject({
      //       status: false,
      //       text: error
      //     });
      //   });

      const batchWrite = this.afs.firestore.batch()
      const timestamp = firestore.Timestamp.now().seconds;

      batchWrite.update(this.afs.collection('events').doc(eventData.docId).ref, updateObj)
      batchWrite.set(this.afs.collection('events').doc(eventData.docId).collection('log').doc(`log-${uuidv4()}`).ref, log)

      batchWrite.commit()
        .then(() => {
          resolve({
            status: true,
            text: 'event status successfully updated'
          })
        })
        .catch(error => {
          reject({
            status: false,
            text: error
          })
        })


    })
  }
  async eventStart(eventData: IEventDetails) {
    this.globals.isBusy.status = true;
    this.globals.isBusy.message = 'Starting the event, please wait';
    // SWISS
    if (eventData.details.structure.isSwiss) {
      // set round one as the active round
      eventData.activeRound = 1;
      // calculate the default number of swiss rounds
      const defaultRoundsToPlay = this.getDefaultSwissRounds(eventData.playerDocIds.length);
      await this.afs.collection('events').doc(eventData.docId)
        .update({
          'details.structure.swiss.roundsToPlay': defaultRoundsToPlay
        });
      // call the function to update the event status
      await this.eventUpdateStatus(eventData, 1).then((response: IPromiseResponse) => {
        if (!response.status) {
          console.log(response.text);
        }
        setTimeout(() => { this.globals.isBusy.status = false; }, 1000);
      });
    }
    // GROUP
    if (eventData.details.structure.isGroup) {
      // call the function to update the event status
      console.log('... calling firebase');
      this.eventUpdateStatus(eventData, 10)
        .then((response: IPromiseResponse) => {
          console.log('... returned with response', response);
          if (!response.status) {
            console.log(response.text);
          }
          setTimeout(() => {
            this.globals.isBusy.status = false;
            console.log('... closing busy loader');
          }, 1000);
        });
    }
    // ROUND ROBIN
    if (eventData.details.structure.isRoundRobin) {
      // set round one as the active round
      eventData.activeRound = 1;
      // create all the needed matches
      await this.eventPairRoundRobin(eventData);
    }
    // BATCH
    if (eventData.details.structure.isBatch) {
      // set round one as the active round
      eventData.activeRound = 1; // will be used as batch number
      // call the function to update the event status
      await this.eventUpdateStatus(eventData, 1).then((response: IPromiseResponse) => {
        if (!response.status) {
          console.log(response.text);
        }
        setTimeout(() => { this.globals.isBusy.status = false; }, 1000);
      });
    }
  }

  private async pairGroupsRandom(event: IEventDetails, groups: IEventGroup[], allowByes: boolean) {

    const matchesPerPlayer = event.details.structure.group.matchesPerGroup

    const __shufflePlayers = (arr: any) => {
      let i, j, temp
      for (i = arr.length - 1; i > 0; i--) {
        j = Math.floor(Math.random() * (i + 1))
        temp = arr[i]
        arr[i] = arr[j]
        arr[j] = temp
      }
      return arr
    }
    const __getOpponent = async (player: IEventPlayerDetails, players: Array<IEventPlayerDetails>, matchesPerPlayer: number) => {
      const shuffledPlayerList: Array<IEventPlayerDetails> = __shufflePlayers(players)
      const opponent = shuffledPlayerList.filter(p => p.playerDocId !== player.playerDocId).find(p => !p.opponentsDocIds.includes(player.playerDocId) && p.opponentsDocIds.length < matchesPerPlayer)
      if (opponent) {
        shuffledPlayerList.find(p => p.playerDocId === opponent.playerDocId)?.opponentsDocIds.push(player.playerDocId)
        console.log('...found an opponent: ', JSON.stringify(opponent.name))
        return { opponent, availablePlayers: shuffledPlayerList }
      }
      else {
        return { opponent: null, availablePlayers: shuffledPlayerList }
      }
    }
    const __getPairings = async (players: Array<IEventPlayerDetails>, matchesPerPlayer: number) => {
      for await (const player of players) {
        let availablePlayers = JSON.parse(JSON.stringify(players))
        let success = true
        console.log(`...trying to create matches for player: ${player.name} (${player.opponentsDocIds.length})`)
        while (player.opponentsDocIds.length < matchesPerPlayer && success) {
          console.log('...fetching next opponent')
          const res = await __getOpponent(player, availablePlayers, matchesPerPlayer)
          if (res.opponent !== null) {
            player.opponentsDocIds.push(res.opponent.playerDocId)
            players.find(p => p.playerDocId === res.opponent.playerDocId)?.opponentsDocIds.push(player.playerDocId)
            availablePlayers = res.availablePlayers
          }
          else {
            success = false
          }
          console.log('...opponentCount: ', player.opponentsDocIds.length)
        }
      }

      return players

    }
    const __createPairings = async (playerList: Array<IEventPlayerDetails>, matchesPerPlayer: number, allowByes: boolean) => {
      let pairedPlayers: Array<IEventPlayerDetails> = []
      const maxAttempts = 100
      for (let attempt = 1; attempt <= maxAttempts; attempt++) {
        console.info(`PAIRING ATTEMPT: Starting attempt ${attempt}`)
        const players = JSON.parse(JSON.stringify(playerList))
        pairedPlayers = await __getPairings(players, matchesPerPlayer)
        if (allowByes && pairedPlayers.filter(p => p.opponentsDocIds.length < (matchesPerPlayer - 1)).length === 0) {
          pairedPlayers.filter(p => p.opponentsDocIds.length < matchesPerPlayer).forEach((player) => {
            player.opponentsDocIds.push('*** BYE ***')
          })
          console.info(`PAIRING ATTEMPT: Attempt ${attempt} successfull`)
          return pairedPlayers
        }
        else if (!allowByes && pairedPlayers.filter(p => p.opponentsDocIds.length !== matchesPerPlayer).length === 0) {
          console.info(`PAIRING ATTEMPT: Attempt ${attempt} successfull`)
          return pairedPlayers
        }
        else {
          console.error(`PAIRING ATTEMPT: Attempt ${attempt} failed!!!`)
          pairedPlayers = []
          continue
        }
      }
    }

    for await (const group of groups) {
      console.info(`*** Start pairings for group: ${group.name} ******************************`)
      const pairedPlayers = await __createPairings(group.players, matchesPerPlayer, allowByes)
      group.pairingSuccessful = pairedPlayers.length > 0
      group.players = pairedPlayers
    }

    // if some groups failed, return false
    if (groups.filter(g => !g.pairingSuccessful).length > 0) {
      console.error('...some groups failed :(')
      return []
    }
    else {
      // create matches and return them
      const matchDocsCreated: IMatchData[] = []
      for await (const group of groups) {
        console.log(`Create match documents for ${group.name}`)
        for await (const player of group.players) {
          console.log(`...creating match docs for ${player.name}`)
          for await (const opponentDocId of player.opponentsDocIds) {
            // check if match is already created
            if (matchDocsCreated.find(doc => doc.playerDocIds.includes(player.playerDocId) && doc.playerDocIds.includes(opponentDocId))) {
              console.log('...match already present, skip to next')
              continue
            }
            else {
              // create the match document
              const match = new EventMatch(
                event.docId,
                this.auth.user.uid,
                1,
                null,
                null,
                'group',
                player,
                opponentDocId.includes('*** ') ? null : group.players.find(i => i.playerDocId === opponentDocId),
                opponentDocId === '*** BYE ***'
                  ? 'Bye'
                  : opponentDocId === '*** LOSS ***'
                    ? 'Loss'
                    : null,
                uuidv4()
              )
              match.document.groupName = group.name
              matchDocsCreated.push(match.document)
            }
          }
        }
      }
      return matchDocsCreated
    }

  }
  private async pairGroupsRoundRobin(event: IEventDetails, groups: IEventGroup[]) {
    console.log('... group matches will be created, ROUND ROBIN structure active')
    const matches: Array<IMatchData> = []
    // loop through all the groups and players to create matches
    for await (const group of groups) {
      console.log('... starting to create matches for group: ' + group.name)
      // loop through all players within the group to create the needed matches
      for await (const player of group.players) {
        console.log(`... starting to create matches for player ${player.name}`)
        // filter players
        const opponentList = group.players.filter(p => p.playerDocId !== player.playerDocId)
        opponentList.forEach((opponent) => {
          console.log(`... checking if ${player.name} has been paired against ${opponent.name}`)
          if (!matches.find(m => m.playerDocIds.includes(player.playerDocId) && m.playerDocIds.includes(opponent.playerDocId))) {
            console.log(`... players are not paired, create a match`)
            const match = new EventMatch(event.docId, event.createdByUid, 1, null, null, 'group', player, opponent, null, uuidv4())
            match.document.groupName = group.name
            matches.push(match.document)
          }
        })
      }
    }
    return matches
  }

  eventPairGroup(eventData: IEventDetails, groups: Array<IEventGroup>, roundRobin: boolean, allowByes: boolean) {

    return new Promise(async (resolve) => {

      let matches: IMatchData[] = []

      if (!roundRobin) {
        matches = await this.pairGroupsRandom(eventData, groups, allowByes)
      }
      else {
        matches = await this.pairGroupsRoundRobin(eventData, groups)
      }

      if (matches.length === 0) {
        // handle error
        resolve({
          status: false,
          text: 'Error generating group matches, please try again or change the configuration and the try again.'
        })
      }
      else {

        // create firestore batch writer
        const batchWrites: firebase.default.firestore.WriteBatch[] = []
        let batchWriteIndex = 0
        let writeCount = 0

        // create local method to update counter and index of the batch writer
        const __incrementWriteCounter = () => {
          writeCount++
          if ((writeCount % 500) === 0 && (writeCount / 500) === (batchWriteIndex + 1)) {
            batchWrites.push(this.afs.firestore.batch())
            batchWriteIndex++
          }
        }

        // add first batch
        batchWrites.push(this.afs.firestore.batch())

        // create match document writes
        const matchColRef = this.afs.collection('matches')
        for (const match of matches) {
          __incrementWriteCounter()
          batchWrites[batchWriteIndex].set(matchColRef.doc(match.docId).ref, match)
        }

        // create player document writes
        const playerColRef = this.afs.collection('events').doc(eventData.docId).collection('players')
        for (const group of groups) {
          for (const player of group.players) {
            __incrementWriteCounter()
            batchWrites[batchWriteIndex].update(playerColRef.doc(player.playerDocId).ref, {
              playedInGroup: group.name
            })
          }
        }

        // perform updates
        for await (const [index, batch] of batchWrites.entries()) {
          batch
            .commit()
            .then(() => console.log(`...batchWrite #${index + 1} successful`))
            .catch((e) => {
              console.log(e)
              return
            })
        }

        resolve({
          status: true,
          text: 'Group stage matches generated successfully!'
        })

      }

    })
  }
  async eventResetGroupEvent(eventData: IEventDetails) {
    this.globals.isBusy.status = true;
    this.globals.isBusy.showMessage = true;

    // create firestore batch writer
    const batchWrites: firebase.default.firestore.WriteBatch[] = []
    let batchWriteIndex = 0
    let writeCount = 0

    // create local method to update counter and index of the batch writer
    const __incrementWriteCounter = () => {
      writeCount++
      if ((writeCount % 500) === 0 && (writeCount / 500) === (batchWriteIndex + 1)) {
        batchWrites.push(this.afs.firestore.batch())
        batchWriteIndex++
      }
    }

    // add first batch
    batchWrites.push(this.afs.firestore.batch())

    // create player updates
    const playerColRef = this.afs.collection('events').doc(eventData.docId).collection('players')
    for (const player of eventData.playerDocIds) {
      __incrementWriteCounter()
      batchWrites[batchWriteIndex].update(playerColRef.doc(player).ref, {
        playedInGroup: null,
        opponentDocIds: []
      })
    }

    // create all match delete
    const matchSnap = await firstValueFrom(this.afs.collection<IMatchData>('matches', ref => ref.where('eventDocId', '==', eventData.docId)).get())
    matchSnap.docs.forEach(i => {
      __incrementWriteCounter()
      batchWrites[batchWriteIndex].delete(i.ref)
    })

    // perform updates
    for await (const [index, batch] of batchWrites.entries()) {
      batch
        .commit()
        .then(() => console.log(`...batchWrite #${index + 1} successful`))
        .catch((e) => {
          console.log(e)
          this.toastService.show(e, { classname: 'error-toast', delay: 6000 })
          return
        })
    }


    // update status to 0 again
    this.globals.isBusy.message = 'Updating event status';
    this.eventUpdateStatus(eventData, 0);

  }
  eventPairBatch(eventData: IEventDetails): Promise<IPromiseResponse> {


    return new Promise(async (resolve) => {

      // store batch config
      const batch: IBatchConfig = eventData.details.structure.batch?.batches.find(b => b.roundNumber === eventData.activeRound);

      // get matches
      const matches = await this.__pairBatch(eventData, batch)

      // create firestore batch writer
      const batchWrites: firebase.default.firestore.WriteBatch[] = []
      let batchWriteIndex = 0
      let writeCount = 0

      // create local method to update counter and index of the batch writer
      const __incrementWriteCounter = () => {
        writeCount++
        if ((writeCount % 500) === 0 && (writeCount / 500) === (batchWriteIndex + 1)) {
          batchWrites.push(this.afs.firestore.batch())
          batchWriteIndex++
        }
      }


      // add the first batch
      batchWrites.push(this.afs.firestore.batch())


      // loop through matches and create the transactions for writing
      for await (const match of matches) {

        __incrementWriteCounter()
        const matchDocRef = this.afs.collection('matches').doc(match.docId).ref
        batchWrites[batchWriteIndex].set(matchDocRef, match)

        if (match.player1.playerDocId !== '*** BYE ***' && match.player1.playerDocId !== '*** LOSS ***') {
          __incrementWriteCounter()
          const player1DocRef = this.afs.collection('events').doc(eventData.docId).collection('players').doc(match.player1.playerDocId).ref
          batchWrites[batchWriteIndex].update(player1DocRef, {
            'opponentsDocIds': firestore.arrayUnion(match.player2.playerDocId)
          })
        }
        if (match.player2.playerDocId !== '*** BYE ***' && match.player2.playerDocId !== '*** LOSS ***') {
          __incrementWriteCounter()
          const player2DocRef = this.afs.collection('events').doc(eventData.docId).collection('players').doc(match.player2.playerDocId).ref
          batchWrites[batchWriteIndex].update(player2DocRef, {
            'opponentsDocIds': firestore.arrayUnion(match.player1.playerDocId)
          })
        }

      }


      // perform writes
      console.log('...writing matches and player updates to firebase')
      for await (const [index, batch] of batchWrites.entries()) {
        batch
          .commit()
          .then(() => console.log(`...batchWrite #${index + 1} successful`))
          .catch((e) => {
            console.log(e)
            resolve({
              status: false,
              text: e,
            })
          })
      }


      resolve({
        status: true,
        text: 'All matches created for batch round'
      })
    })


  }
  eventDeleteActiveBatch(eventData: IEventDetails) {
    return new Promise((resolve, reject) => {
      // delete all matches from the matches collection referring to the event and active round
      this.deleteAllMatches(eventData.docId, eventData.activeRound)
        .then((response: IPromiseResponse) => {
          if (response.status) {
            this.globals.isBusy.message = 'All match documents deleted, deleting batch...';
            const batchIndex = eventData.details.structure.batch.batches.findIndex(b => b.roundNumber === eventData.activeRound);
            eventData.details.structure.batch.batches.splice(batchIndex, 1);
            eventData.activeRound =
              eventData.details.structure.batch.batches.length < 1 ? 1 : eventData.details.structure.batch.batches.length;
            this.updateEventData(eventData).then((innerRes: IPromiseResponse) => {
              if (innerRes.status) {
                resolve({
                  status: true,
                  text: 'Deletion of Batch and all matches successfull.'
                });
              }
              else {
                reject({
                  status: false,
                  text: 'Could not delete batch'
                });
              }
            });
          }
          else {
            console.log(response.text);
            reject({
              status: false,
              text: 'Could not delete all match documents'
            });
          }
        });
    });
  }
  eventPairRoundRobin(eventData: IEventDetails) {

    return new Promise(async (resolve) => {

      // get players
      const playerDocSnap = await firstValueFrom(this.afs.collection('events').doc(eventData.docId).collection<IEventPlayerDetails>('players').get())
      if (playerDocSnap.empty) {
        // handle error
        this.toastService.show('Error when trying to generate Round Robin matches, please try again!', { classname: 'error-toast' })
        return
      }

      // get all active players
      const players = playerDocSnap.docs.map(i => i.data()).filter(i => !i.dropped)
      const opponents = JSON.parse(JSON.stringify(players))

      // create firestore batch writer
      const batchWrites: firebase.default.firestore.WriteBatch[] = []
      let batchWriteIndex = 0
      let writeCount = 0

      // create local method to update counter and index of the batch writer
      const __incrementWriteCounter = () => {
        writeCount++
        if ((writeCount % 500) === 0 && (writeCount / 500) === (batchWriteIndex + 1)) {
          batchWrites.push(this.afs.firestore.batch())
          batchWriteIndex++
        }
      }

      // add first batch
      batchWrites.push(this.afs.firestore.batch())

      // create matches
      const matchColRef = this.afs.collection('matches')
      for await (const player of players) {
        // remove the player from the list
        opponents.shift()
        // pair player against all opponents
        for await (const opponent of opponents) {
          const match = new EventMatch(
            eventData.docId,
            this.auth.user.uid,
            1,
            null,
            null,
            'round-robin',
            player,
            opponent,
            null,
            uuidv4(),
          )
          __incrementWriteCounter()
          batchWrites[batchWriteIndex].set(matchColRef.doc(match.document.docId).ref, match.document)
        }
      }


      // perform updates
      for await (const [index, batch] of batchWrites.entries()) {
        batch
          .commit()
          .then(() => console.log(`...batchWrite #${index + 1} successful`))
          .catch((e) => {
            console.log(e)
            return
          })
      }


      // update event
      await this.eventUpdateStatus(eventData, 3).then((res) => res)

      this.toastService.show('Round Robin matches successfully created, event has started!', { classname: 'success-toast' })

    })
  }
  eventPairSwissRound(eventData: IEventDetails) {
    // console.log('pairing swiss round', eventData);
    // this.globals.isBusy.status = true;
    // this.globals.isBusy.message = 'Generating pairings, please wait';
    // const callable = this.fns.httpsCallable('pairSwissRound');
    // callable({ event: eventData })
    //   .toPromise()
    //   .then((res: IPromiseResponse) => {
    //     if (res.status) {
    //       this.globals.isBusy.message = 'Pairings generated successfully';
    //       console.log(res);
    //       this.eventUpdateStatus(eventData, 13).then((response: IPromiseResponse) => {
    //         if (!response.status) {
    //           console.log(response.text);
    //         }
    //         setTimeout(() => { this.globals.isBusy.status = false; }, 1000);
    //       });
    //     }
    //     else {
    //       this.globals.isBusy.message = 'Pairings failed';
    //       setTimeout(() => { this.globals.isBusy.status = false; }, 1000);
    //       // log error
    //       const timestamp = firestore.Timestamp.now().seconds
    //       const colRef = this.afs.collection('events').doc(eventData.docId).collection('log').doc(`log-${uuidv4()}`)
    //       const logText: IEventLog = {
    //         timestamp,
    //         type: 'pairing',
    //         text: this.globals.isBusy.message
    //       }
    //       colRef.set(logText)
    //     }
    //   })
    //   .catch(error => {
    //     console.log(error);
    //     this.globals.isBusy.message = 'Pairings failed';
    //     setTimeout(() => { this.globals.isBusy.status = false; }, 1000);
    //     // log error
    //     const timestamp = firestore.Timestamp.now().seconds
    //     const colRef = this.afs.collection('events').doc(eventData.docId).collection('log').doc(`log-${uuidv4()}`)
    //     const logText: IEventLog = {
    //       timestamp: firestore.Timestamp.now().seconds,
    //       type: 'pairing',
    //       text: this.globals.isBusy.message,
    //       error
    //     }
    //     colRef.set(logText)
    //   });
  }
  eventSendPushAfterUpdate(event: IEventDetails, pushType: PUSHTYPES) {
    if (event.sendPush !== undefined && event.sendPush === false) {
      console.log('Push notifications turned of for event, not sending any notifications')
      return
    }
    // push types
    // 1) roundPaired
    // 2) roundStarted
    let callableFunction: string
    switch (pushType) {
      case PUSHTYPES.ROUND_PAIRINGS_POSTED:
        callableFunction = 'push-onPairingsPublished'
        break
      case PUSHTYPES.ROUND_STARTED:
        callableFunction = 'push-onRoundStarted'
        break
    }
    const sendPush = this.fns.httpsCallable(callableFunction);
    sendPush({ event }).toPromise().then((res: IPromiseResponse) => {
      if (res.status) {
        this.toastService.show(
          res.text,
          { classname: 'success-toast', delay: 3000 }
        );
      }
      else {
        this.toastService.show(
          res.text,
          { classname: 'error-toast', delay: 5000 }
        );
      }
    });
  }
  eventUnpairSwissRound(eventData: IEventDetails) {
    return new Promise((resolve, reject) => {
      // delete all matches from the matches collection referring to the event and active round
      this.deleteAllMatches(eventData.docId, eventData.activeRound)
        .then((response: IPromiseResponse) => {
          if (response.status) {
            resolve({
              status: true,
              text: 'All match documents deleted, changing status...'
            });
          }
          else {
            console.log(response.text);
            reject({
              status: false,
              text: 'Could not delete all match documents'
            });
          }
        });
    });
  }
  eventStartBatchRound(eventData: IEventDetails) {
    this.globals.isBusy.status = true;
    this.globals.isBusy.message = 'Starting round, please wait';
    // call the function to update the event status
    this.eventUpdateStatus(eventData, 3)
      .then((response: IPromiseResponse) => {
        if (!response.status) {
          console.log(response.text);
        }
        setTimeout(() => { this.globals.isBusy.status = false; }, 1000);
      });
  }
  endBatchRound(eventData: IEventDetails) {
    return new Promise((resolve, reject) => {
      // call function to calculate stats in backend
      this.eventUpdateStatus(eventData, 12)
        .then((response: IPromiseResponse) => {
          // returnig from update function
          resolve({
            status: false,
            text: response.text
          });
        });
    });
  }
  eventStartSwissRound(eventData: IEventDetails) {
    this.globals.isBusy.status = true;
    this.globals.isBusy.message = 'Starting round, please wait';
    this.eventSendPushAfterUpdate(eventData, PUSHTYPES.ROUND_STARTED);
    // call the function to update the event status
    this.eventUpdateStatus(eventData, 3)
      .then((response: IPromiseResponse) => {
        if (!response.status) {
          console.log(response.text);
        }
        setTimeout(() => { this.globals.isBusy.status = false; }, 1000);
      });
  }
  endSwissRound(eventData: IEventDetails) {
    // update global app status
    this.globals.isBusy.status = true
    return new Promise(async (resolve) => {
      const statsCalc = await this.calculateStats(eventData)
      if (statsCalc.status) {
        // check if it's the final round
        if (eventData.activeRound === eventData.details.structure.swiss.roundsToPlay) {
          // call the function to update the event status
          this.eventUpdateStatus(eventData, 5)
            .then((response: IPromiseResponse) => {
              // returnig from update function
              if (response.status) {
                console.log(response.text)
                this.globals.isBusy.message = 'Stats calculated successfully, ending the final round in the swiss'
                setTimeout(() => {
                  this.globals.isBusy.status = false
                }, 1000)
                // returning the success response
                resolve({
                  status: true,
                  text: 'Stats calculated successfully, ending the final round in the swiss',
                })
              }
              else {
                this.globals.isBusy.message = response.text
                setTimeout(() => {
                  this.globals.isBusy.status = false
                }, 5000)
                // returning the error response
                resolve({
                  status: false,
                  text: response.text,
                })
              }
            })
        }
        else {
          // call the function to update the event status
          this.eventUpdateStatus(eventData, 1, true)
            .then((response: IPromiseResponse) => {
              // returnig from update function
              if (response.status) {
                console.log(response.text)
                this.globals.isBusy.message = 'Stats calculated successfully, ending round'
                setTimeout(() => {
                  this.globals.isBusy.status = false
                }, 1000)
                resolve({
                  status: true,
                  text: 'Stats calculated successfully, ending round',
                })
              }
              else {
                this.globals.isBusy.message = response.text
                setTimeout(() => {
                  this.globals.isBusy.status = false
                }, 5000)
                resolve({
                  status: false,
                  text: response.text,
                })
              }
            })
        }
      }
      else {
        this.globals.isBusy.message = 'Stats calculation failed'
        setTimeout(() => {
          this.globals.isBusy.status = false
        }, 5000)
        // error response returned, returnig back the result
        resolve({
          status: false,
          text: 'Stats calculation failed',
        })
      }
    })
  }
  // in flow functions
  // EVENT FUNCTIONS IN FLOW ORDER
  newEventDocument(): IEventDetails {
    const guid = uuidv4();
    const doc: IEventDetails = {
      docId: guid,
      details: this.newEventData(),
      createdByUid: this.auth.user.uid,
      coOrganizers: [],
      activeRound: 0,
      statusCode: 0,
      statusText: 'Open for player registration',
      startingTable: 1,
      isOnlineTournament: false,
      roundTimer: 50,
      playerDocIds: [],
      invitedPlayers: [],
      log: [],
      isArchived: false,
      isDeleted: false,
    };

    return doc
  }
  newEventData(): INewEventForm {
    console.log('new event data creation', this.auth.user.uid);
    return {
      name: '',
      type: '',
      format: '',
      ruleset: {
        name: '',
        url: ''
      },
      reprintPolicy: {
        name: '',
        url: ''
      },
      isMultiDay: false,
      datestampFrom: null,
      datestampTo: null,
      datetime: '',
      datetimeFrom: '',
      datetimeTo: '',
      GMT_offset: this.usersPlayerDoc ? this.usersPlayerDoc.UTC : '+00:00',
      description: '',
      location: {
        name: '',
        address: '',
        extra: '',
        postalCode: '',
        postalArea: '',
        url: '',
        longitude: '',
        latitude: '',
        geocode: '',
      },
      isPublic: true,
      isPubliclyVisible: true,
      isPasswordProtected: false,
      password: '',
      hasAttendeeCap: false,
      attendeeCap: null,
      isOnlineTournament: false,
      allowSpectators: true,
      deckPhoto: false,
      deckList: false,
      deckInfoIsPublic: false,
      dropPlayersWithoutSubmittedDeckOnStart: false,
      registrationOpen: true,
      registrationOpensTimestamp: new Date().getTime(),
      registrationClosesTimestamp: null,
      registrationFee: {
        active: false,
        tolariaPayment: true,
        forcePaymentWhenAttending: true,
        charityExtra: true,
        amount: null,
        currency: this.auth.user?.stripe?.default_currency ? this.auth.user.stripe.default_currency : null,
        productId: null,
        productType: ProductType.REGISTRATION_FEE,
      },
      roundTimer: 50,
      startingTable: 1,
      structure: {
        isRoundRobin: false,
        isSwiss: true,
        isBracket: false,
        isBatch: false,
        isGroup: false,
        swiss: {
          hideStandingsUntilPosted: false,
          hasBracketAfterSwiss: false,
          bracketSize: null,
          roundsToPlay: null
        },
        bracket: {
          singleElimination: false,
          doubleElimination: false
        },
        group: {
          matchesPerGroup: null,
          playersPerGroup: null,
          playersAdvancingPerGroup: null,
          hasBracketAfterGroupStage: false,
          bracketSize: null,
          numberOfGroups: null,
        },
        batch: {
          hasBracketAfterBatch: false,
          bracketSize: null,
          batches: []
        }
      }
    };
  }
  reportMatchResult(matchDoc: IMatchData) {
    return new Promise(async (resolve, reject) => {

      // set retported timestamp
      matchDoc.timestampReported = firestore.Timestamp.now().seconds

      // set match as reported
      matchDoc.isReported = true

      // make sure that the playerDocIds are updated and correct
      matchDoc.playerDocIds = [
        matchDoc.player1.playerDocId,
        matchDoc.player2.playerDocId
      ]

      // store data in the cloud
      await this.afs.collection('matches').doc(matchDoc.docId).set(matchDoc)
        .then(() => true)
        .catch((error) => {
          reject({
            status: false,
            text: error,
          })
        })

      // check if match is bracket and move winner forward to next match
      if (matchDoc.isType === 'bracket' && matchDoc.feedsMatchDocId !== null) {

        // create a batch
        const batch = this.afs.firestore.batch()

        // create a reference to the next bracket match document
        const nextMatchDocRef = this.afs.collection('matches').doc<IMatchData>(matchDoc.feedsMatchDocId)

        // get the next bracket match
        const nextMatch = (await firstValueFrom(nextMatchDocRef.get())).data()

        // get the winner
        const winner = matchDoc.player1.isWinner ? matchDoc.player1 : matchDoc.player2

        // update match document
        nextMatch[matchDoc.feedsMatchPlayer].displayName = winner.displayName
        nextMatch[matchDoc.feedsMatchPlayer].playerDocId = winner.playerDocId
        nextMatch[matchDoc.feedsMatchPlayer].playerUid = winner.playerUid
        nextMatch[matchDoc.feedsMatchPlayer].seed = winner.seed
        nextMatch[matchDoc.feedsMatchPlayer].rank = winner.rank

        // check if both players are added
        if (nextMatch.player1.playerDocId !== '' && nextMatch.player2.playerDocId !== '') {
          nextMatch.playerDocIds = [nextMatch.player1.playerDocId, nextMatch.player2.playerDocId]
        }

        // add update to batch
        batch.set(nextMatchDocRef.ref, nextMatch)


        // check if team match and if all matches in the bracket has been reported
        // as an update to the bracket matrix is needed in that case
        if (matchDoc.teamIds && matchDoc.teamIds.length > 0) {

          // get all reported matches for the team in the same bracket round
          const matches = await firstValueFrom(this.afs.collection<IMatchData>('matches', ref => ref
            .where('eventDocId', '==', matchDoc.eventDocId)
            .where('isType', '==', 'bracket')
            .where('roundNumber', '==', matchDoc.roundNumber)
            .where('isReported', '==', true)
            .where('teamIds', 'array-contains', matchDoc.teamIds[0])
          ).get())

          // check if all 3 matches has been reported and if that is the case, continue to update bracket matrix
          if (!matches.empty && matches.docs.length === 3) {

            // assign winning team
            const winningTeam = matchDoc.teamIds.map(team => {
              return {
                teamId: team,
                seed: matchDoc.player1.teamId === team ? matchDoc.player1.seed : matchDoc.player2.seed,
                wins: matches.docs.map(i => i.data()).filter(i => i.player1.teamId === team && i.player1.isWinner || i.player2.teamId === team && i.player2.isWinner).length,
              }
            }).sort((a, b) => b.wins - a.wins)[0]

            // fetch event document
            const eventDocRef = this.afs.collection<IEventDetails>('events').doc(matchDoc.eventDocId)
            const eventDoc = (await firstValueFrom(eventDocRef.get())).data()

            // update bracket matrix
            const bracketMatrix = eventDoc.details.structure.bracketMatrix
            bracketMatrix.forEach(i => {
              const feedsMatch = i.matches.find(x => x.matchDocId === matchDoc.bracketMatrixPathToVictory)
              if (feedsMatch) {
                feedsMatch.teams[matchDoc.bracketMatrixPathToVictorySlot] = {
                  id: winningTeam.teamId,
                  seed: winningTeam.seed,
                }
              }
            })

            // event document
            batch.update(eventDocRef.ref, {
              'details.structure.bracketMatrix': bracketMatrix
            })

            // update all the seat matches with the players from the winning team as this has been
            // update previously with the incorrect information.
            for (const match of matches.docs.map(i => i.data())) {
              const teamPlayer = match.player1.teamId === winningTeam.teamId ? match.player1 : match.player2
              const nextBracketMatchRef = this.afs.collection<IMatchData>('matches').doc(match.feedsMatchDocId)
              const nextBracketMatch = (await firstValueFrom(nextBracketMatchRef.get())).data()

              // update match document
              nextBracketMatch[matchDoc.feedsMatchPlayer].displayName = teamPlayer.displayName
              nextBracketMatch[matchDoc.feedsMatchPlayer].playerDocId = teamPlayer.playerDocId
              nextBracketMatch[matchDoc.feedsMatchPlayer].playerUid = teamPlayer.playerUid
              nextBracketMatch[matchDoc.feedsMatchPlayer].seed = teamPlayer.seed
              nextBracketMatch[matchDoc.feedsMatchPlayer].rank = teamPlayer.rank
              nextBracketMatch[matchDoc.feedsMatchPlayer].teamId = teamPlayer.teamId

              // check if both players are added
              if (nextBracketMatch.player1.playerDocId !== '' && nextBracketMatch.player2.playerDocId !== '') {
                nextBracketMatch.playerDocIds = [nextBracketMatch.player1.playerDocId, nextBracketMatch.player2.playerDocId]
                nextBracketMatch.teamIds = [nextBracketMatch.player1.teamId, nextBracketMatch.player2.teamId]
              }


              // add match document update to the batch
              batch.set(nextBracketMatchRef.ref, nextBracketMatch)

            }
          }
        }


        // commit the batch
        batch.commit()
          .then(() => console.log('path to victory and bracket matrix updated'))
          .catch((error) => console.log(error))

      }

      // update event status if match is an event match
      if (matchDoc.eventDocId !== 'casual-match') {

        // check if players are reporting
        const reportedByPlayers = this.auth.user.playerId === matchDoc.player1.playerDocId || this.auth.user.playerId === matchDoc.player2.playerDocId

        // get the players name
        const reporterName = reportedByPlayers
          ? matchDoc.player1.playerDocId === this.auth.user.playerId
            ? matchDoc.player1.displayName
            : matchDoc.player2.displayName
          : this.playerName.getPlayerByUid(this.auth.user.uid)

        // determine the winner
        const winner = matchDoc.player1.isWinner
          ? matchDoc.player1
          : matchDoc.player2
        const loser = matchDoc.player1.isWinner
          ? matchDoc.player2
          : matchDoc.player1

        const timestamp = firestore.Timestamp.now().seconds
        const log: IEventLog = {
          timestamp,
          type: 'match-update',
          text: reportedByPlayers
            ? `Round ${matchDoc.roundNumber} -> Match reported by players. ${winner.displayName} wins (W:${winner.wins} L:${winner.losses} D:${winner.draws}) vs ${loser.displayName}.
              Initiated by ${reporterName}, confirmed by both player.`
            : `Round ${matchDoc.roundNumber} -> Match reported by ${reporterName}. ${winner.displayName} wins (W:${winner.wins} L:${winner.losses} D:${winner.draws}) vs ${loser.displayName}`,
          metadata: {
            matchDocId: matchDoc.docId,
            winnerIs: matchDoc.player1.isWinner ? 'player1' : 'player2',
            winnerName: winner.displayName,
            winnerDocId: winner.playerDocId,
            opponentName: loser.displayName,
            opponentDocId: loser.playerDocId,
            opponentIs: !matchDoc.player1.isWinner ? 'player1' : 'player2',
            wins: winner.wins,
            losses: winner.losses,
            draws: winner.draws,
            round: matchDoc.roundNumber,
          }
        }

        await this.afs.collection('events').doc(matchDoc.eventDocId).collection('log').doc(`log-${uuidv4()}`)
          .set(log)
          .catch((error) => {
            console.log(error)
            resolve({
              status: true,
              text: 'Match result saved successfully, but could not log this to the event log'
            })
          })

      }

      // resolve with success
      resolve({
        status: true,
        text: 'Match result saved successfully'
      })
    })
  }
  resetMatchResult(matchDoc: IMatchData) {
    // store data in the cloud
    this.afs.collection('matches').doc(matchDoc.docId).set(matchDoc)
      .then(() => {
        if (matchDoc.isType === 'bracket') {
          // clear feeded match data for feeded player slot
          let updateFields;
          if (matchDoc.feedsMatchPlayer === 'player1') {
            updateFields = {
              'player1.displayName': '',
              'player1.docId': '',
              'player1.seed': null,
              'player1.rank': null
            };
          }
          else {
            updateFields = {
              'player2.displayName': '',
              'player2.docId': '',
              'player2.seed': null,
              'player2.rank': null
            };
          }
          this.afs.collection('matches').doc(matchDoc.feedsMatchDocId).update(updateFields);
        }
      });
  }
  async calculateStats(eventData: IEventDetails, runInBackground: boolean = false): Promise<IPromiseResponse> {

    return new Promise(async (resolve) => {

      // get player documents
      const snapPlayerDocs = await firstValueFrom(this.afs.collection('events').doc(eventData.docId).collection<IEventPlayerDetails>('players').get())
      if (snapPlayerDocs.empty) {
        console.log(`eventService > pairSwiss ---> no event player documents found`)
        return {
          status: false,
          text: 'no event player documents found',
        }
      }
      const playerDocuments = snapPlayerDocs.docs.map(i => i.data())

      // get matches
      const snapMatchDocs = await firstValueFrom(this.afs.collection<IMatchData>('matches', ref => ref.where('eventDocId', '==', eventData.docId)).get())
      if (snapMatchDocs.empty) {
        console.log(`eventService > pairSwiss ---> no match documents found (must be first round)`)
      }
      const matchDocuments = snapMatchDocs.docs.map(i => i.data())


      const playersData = this.__getExtendedPlayers(playerDocuments, matchDocuments, eventData)

      console.log(playersData)

      const playersWithBreakers = PlayerTieBreakers.compute(playersData)

      console.log(playersWithBreakers)

      const playersSorted = PlayerTieBreakers.sort(playersWithBreakers)

      const playersRanked = PlayerTieBreakers.rank(playersSorted)


      // create firestore batch writer
      const batchWrites: firebase.default.firestore.WriteBatch[] = []
      let batchWriteIndex = 0
      let writeCount = 0

      // create local method to update counter and index of the batch writer
      const __incrementWriteCounter = () => {
        writeCount++
        if ((writeCount % 500) === 0 && (writeCount / 500) === (batchWriteIndex + 1)) {
          batchWrites.push(this.afs.firestore.batch())
          batchWriteIndex++
        }
      }


      // perform update
      batchWrites.push(this.afs.firestore.batch())
      for (const player of playersRanked) {
        __incrementWriteCounter()
        const updateRef = this.afs.collection('events').doc(eventData.docId).collection('players').doc(player.playerDocId).ref
        batchWrites[batchWriteIndex].update(updateRef, player)
      }


      // perform writes
      console.log('...player updates to firebase')
      for await (const [index, batch] of batchWrites.entries()) {
        batch
          .commit()
          .then(() => console.log(`...batchWrite #${index + 1} successful`))
          .catch((e) => {
            console.log(e)
            resolve({
              status: false,
              text: e,
            })
          })
      }

      resolve({
        status: true,
        text: 'Stats re-calculated successfully'
      })

    })

  }
  addPlayoffBracket(eventData: IEventDetails) {
    this.afs.collection('events').doc(eventData.docId)
      .update({
        'details.structure.swiss.hasBracketAfterSwiss': true,
        'details.structure.group.hasBracketAfterGroupStage': true,
        'details.structure.batch.hasBracketAfterBatch': true
      })
      .then(response => {
        console.log(response);
      })
      .catch(error => {
        console.log(error);
      });
  }
  async removePlayoffBracket(eventData: IEventDetails) {

    await this.afs.collection('events').doc(eventData.docId)
      .update({
        'details.structure.swiss.hasBracketAfterSwiss': false,
        'details.structure.group.hasBracketAfterGroupStage': false,
        'details.structure.batch.hasBracketAfterBatch': false
      })
      .then(response => {
        console.log(response);
      })
      .catch(error => {
        console.log(error);
      });
  }
  addSwissRound(eventData: IEventDetails) {
    return new Promise((resolve, reject) => {
      const newRoundsToPlay = eventData.details.structure.swiss.roundsToPlay + 1;
      this.afs.collection('events').doc(eventData.docId)
        .update({
          'details.structure.swiss.roundsToPlay': newRoundsToPlay
        })
        .then(() => {
          if (eventData.statusCode === 5) {
            this.eventUpdateStatus(eventData, 1, true)
              .catch((error) => {
                console.log(error);
                resolve({
                  status: false,
                  text: error
                });
              });
          }
          resolve({
            status: true,
            text: 'New round successfully added to you event.'
          });
        })
        .catch((error) => {
          console.log(error);
          resolve({
            status: false,
            text: error
          });
        });
    });
  }
  removeSwissRound(eventData: IEventDetails) {
    return new Promise((resolve, reject) => {
      const newRoundsToPlay = eventData.details.structure.swiss.roundsToPlay - 1;
      this.afs.collection('events').doc(eventData.docId)
        .update({
          'details.structure.swiss.roundsToPlay': newRoundsToPlay
        })
        .then(() => {
          resolve({
            status: true,
            text: 'Round successfully removed from the event.'
          });
        })
        .catch((error) => {
          console.log(error);
          resolve({
            status: false,
            text: error
          });
        });
    });
  }
  reactivatePreviousRound(eventData: IEventDetails) {
    return new Promise((resolve, reject) => {
      // get all matches and remove them
      this.deleteAllMatches(eventData.docId, eventData.activeRound)
        .then((response: IPromiseResponse) => {
          // check if error
          if (!response.status) {
            console.log(response);
            resolve({
              status: false,
              text: response.text
            });
          }
          // update event status
          const newActiveRound = eventData.activeRound - 1;
          this.eventUpdateStatus(eventData, 4, false)
            .then((res: IPromiseResponse) => {
              // update active round
              this.afs.collection('events').doc(eventData.docId)
                .update({
                  activeRound: newActiveRound
                })
                .then(() => {
                  if (res.status) {
                    resolve({
                      status: true,
                      text: 'Successfully deleted all matches and reactivated previous round.'
                    });
                  }
                  else {
                    console.log(res);
                    resolve({
                      status: false,
                      text: res.text
                    });
                  }
                })
                .catch(error => {
                  console.log(error);
                  resolve({
                    status: false,
                    text: error
                  });
                });
            })
            .catch(error => {
              console.log(error);
              resolve({
                status: false,
                text: error
              });
            });
        });
    });
  }
  deleteAllBracketMatches(eventDocId: string) {
    return new Promise(async (resolve, reject) => {
      const batch = this.afs.firestore.batch()
      const matches = await firstValueFrom(this.afs.collection<IMatchData>('matches', ref => ref.where('eventDocId', '==', eventDocId).where('isType', '==', 'bracket')).get())
      for (const matchDoc of matches.docs) {
        batch.delete(matchDoc.ref)
      }
      batch.commit()
        .then(() => {
          resolve({
            status: true,
            text: 'All match documents deleted, changing status...'
          })
        })
        .catch(error => {
          console.log(error);
          reject({
            status: false,
            text: 'Could not delete all match documents'
          })
        })
    })
  }
  deleteAllMatches(eventDocId: string, roundNumber: number) {
    return new Promise(async (resolve, reject) => {

      const batches: any = []
      let transactionCounter = 0
      let activeBatchIndex = 0

      const __incrementTransactionCounter = () => {
        transactionCounter++
        if ((transactionCounter % 500) === 0 && (transactionCounter / 500) === (activeBatchIndex + 1)) {
          batches.push(this.afs.firestore.batch())
          activeBatchIndex++
        }
      }



      batches.push(this.afs.firestore.batch())

      const matchCollection = this.afs.collection<IMatchData>('matches', ref => ref
        .where('eventDocId', '==', eventDocId)
        .where('roundNumber', '==', roundNumber))

      const matches = await firstValueFrom(matchCollection.get())

      for (const matchDoc of matches.docs) {


        // get the match doc
        const matchData = matchDoc.data()

        // update players
        if (matchData.player1.playerDocId !== '' && matchData.player2.playerDocId !== '') {
          const playerRef1 = this.afs.collection('events').doc(eventDocId).collection('players').doc(matchData.player1.playerDocId).ref
          const playerRef2 = this.afs.collection('events').doc(eventDocId).collection('players').doc(matchData.player1.playerDocId).ref
          if (matchData.isByeMatch) {
            // update player1 status
            // increment transaction counter
            __incrementTransactionCounter()
            batches[activeBatchIndex].update(playerRef1, {
              haveByeMatch: false
            })
          }
          // remove opponent for player1
          __incrementTransactionCounter()
          batches[activeBatchIndex].update(playerRef1, {
            opponentsDocIds: firestore.arrayRemove(matchData.player2.playerDocId)
          })
          // remove opponent for player2
          __incrementTransactionCounter()
          batches[activeBatchIndex].update(playerRef2, {
            opponentsDocIds: firestore.arrayRemove(matchData.player1.playerDocId)
          })
        }

        // delete match document
        __incrementTransactionCounter()
        batches[activeBatchIndex].delete(matchDoc.ref)

      }

      // delete all matches from the matches collection referring to the event and active round
      for await (const [index, batch] of batches.entries()) {
        console.log(`...handling batch #${index + 1}`)
        batch.commit()
          .then(() => {
            console.log(`All transaction handled successfully for batch #${index + 1}`)
          })
          .catch(error => {
            console.log(error);
            reject({
              status: false,
              text: `Could NOT handle all transactions in batch #${index + 1}`
            })
          })
      }

      resolve({
        status: true,
        text: 'All matches deleted successfully'
      })
    })
  }
  createSwissEliminationBracket(players: Array<IEventPlayerDetails>, eventDetails: IEventDetails, eventDocId: string) {
    this.globals.isBusy.status = true
    this.globals.isBusy.showMessage = true
    this.globals.isBusy.message = 'Generating Single Elimination Bracket'
    console.log('Generating Single Elimination Bracket')
    console.log({ players, eventDetails, eventDocId })

    return new Promise(async (resolve) => {

      // create batch
      const batch = this.afs.firestore.batch()

      // get bracket data
      const bracketData = SingleElimination(players)

      // add update to event document
      batch.update(this.afs.collection('events').doc(eventDetails.docId).ref, {
        'details.structure.bracketMatrix': bracketData.bracketMatrix
      })

      // create matches
      for (const match of bracketData.matches.filter(i => !i.byeMatch)) {
        // create real match documents
        const matchDoc = new EventMatch(
          eventDetails.docId,
          this.auth.user.uid,
          match.round,
          null,
          null,
          'bracket',
          match.playerOne,
          match.playerTwo,
          null,
          match.matchId
        ).document

        matchDoc.feedsMatchDocId = match.feedsMatchDocId
        matchDoc.feedsMatchPlayer = match.feedsPlayerSlot

        // add to batch
        batch.set(this.afs.collection('matches').doc(match.matchId).ref, matchDoc)
      }

      // commit
      batch.commit()
        .then(() => {

          // update status
          console.log('...update status')
          this.eventUpdateStatus(eventDetails, 7)
            .then((response: IPromiseResponse) => {
              this.globals.isBusy.message = response.text
              setTimeout(() => { this.globals.isBusy.status = false; }, 1000)
              resolve({
                status: true,
                text: 'All done!'
              })
            })

        })
        .catch((error) => {
          this.globals.isBusy.message = 'Batch update failed -> ' + error
          setTimeout(() => { this.globals.isBusy.status = false; }, 5000)
          resolve({
            status: false,
            text: 'Batch update failed -> ' + error,
          })
        })


    })

  }
  /**
   * Create a bracket matrix and all matches needed to continue with a playoff
   * Three matches per non-bye bracket section will be created as well as the
   * bracket matrix used to display the bracket in the template.
   * Teams play by seats A/B/C
   *
   * @param teams list of all the teams that should be matched up
   * @param eventDocId the event document id
   */
  createSwissTeamEliminationBracket(teams: IEventTeam[], eventDetails: IEventDetails, eventDocId: string) {
    this.globals.isBusy.status = true
    this.globals.isBusy.showMessage = true
    this.globals.isBusy.message = 'Generating Single Elimination Bracket for teams'
    console.log('Generating Single Elimination Bracket for teams')
    console.log({ teams, eventDocId })

    return new Promise(async (resolve) => {

      const seats: IMatchData['teamSeat'][] = ['a', 'b', 'c']

      // get bracket data
      const bracketData = SingleEliminationTeams(teams)
      console.log(bracketData)

      // get all players of event
      const playersCollection = await firstValueFrom(this.afs.collection('events').doc(eventDocId).collection<IEventPlayerDetails>('players').get())
      const players = playersCollection.docs.map(i => i.data())

      const matches: IMatchData[] = []
      for (const round of bracketData.bracketMatrix) {
        for (const match of round.matches) {
          // for each bracket match, create all the seat matches
          for (const seat of seats) {
            const newMatch = new EventMatch(
              eventDocId,
              this.auth.user.uid,
              round.round,
              null,
              null,
              'bracket',
              match.teams.teamOne === null
                ? null
                : players.find(p => p.playerDocId === teams.find(i => i.id === match.teams.teamOne.id).player[seat]),
              match.teams.teamTwo === null
                ? null
                : players.find(p => p.playerDocId === teams.find(i => i.id === match.teams.teamTwo.id).player[seat]),
              null).document

            // add seat
            newMatch.teamSeat = seat

            // add team id's and seed if able
            if (match.teams.teamOne === null || match.teams.teamTwo === null) {
              newMatch.teamIds = []
            }
            else {
              newMatch.teamIds = [match.teams.teamOne.id, match.teams.teamTwo.id]
              newMatch.player1.seed = match.teams.teamOne.seed
              newMatch.player2.seed = match.teams.teamTwo.seed
              newMatch.player1.teamId = teams.find(i => i.playerDocIds.includes(newMatch.player1.playerDocId)).id
              newMatch.player2.teamId = teams.find(i => i.playerDocIds.includes(newMatch.player2.playerDocId)).id
            }

            // add bracket matrix references
            newMatch.bracketMatrixMatchDocId = match.matchDocId
            newMatch.bracketMatrixPathToVictory = match.feedsMatchDocId
            newMatch.bracketMatrixPathToVictorySlot = match.feedsPlayerSlot


            matches.push(newMatch)
          }
        }
      }


      // // for each bracket match, create all the seat matches
      // const seats: IMatchData['teamSeat'][] = ['a', 'b', 'c']
      // const matches: IMatchData[] = []
      // for (const match of bracketData.matches) {
      //   seats.forEach(seat => {
      //     const newMatch = new EventMatch(
      //       eventDocId,
      //       this.auth.user.uid,
      //       match.round,
      //       null,
      //       null,
      //       'bracket-team',
      //       null,
      //       null,
      //       null).document
      //     newMatch.teamSeat = seat
      //     newMatch.teamIds = []
      //     matches.push(newMatch)
      //   })
      // }

      // add path to victory
      const tmpRounds = [...new Set(bracketData.matches.map(i => i.round))]
      tmpRounds.pop()
      for (const seat of seats) {
        for (const round of tmpRounds) {
          const tmpMatches = matches.filter(r => r.roundNumber === round && r.teamSeat === seat)
          for (const [i, val] of tmpMatches.entries()) {
            const pathToVictoryIndex = (i - (i % 2)) / 2
            const pathToVictoryMatch = matches.filter(m => m.roundNumber === round + 1 && m.teamSeat === seat)[pathToVictoryIndex].docId
            const pathToVictorySlot = i % 2 === 0 ? 'player1' : 'player2'
            val.feedsMatchDocId = pathToVictoryMatch
            val.feedsMatchPlayer = pathToVictorySlot
          }
        }
      }

      // create batch for writing
      const bacth = this.afs.firestore.batch()

      // add each match document to the batch
      matches.forEach(m => bacth.set(this.afs.collection('matches').doc(m.docId).ref, m))

      // add the event swiss bracket matrix update
      bacth.update(this.afs.collection('events').doc(eventDocId).ref, {
        'details.structure.bracketMatrix': bracketData.bracketMatrix
      })

      // commit batch
      bacth.commit()
        .then(() => {

          // update status
          console.log('...update status')
          this.eventUpdateStatus(eventDetails, 7)
            .then((response: IPromiseResponse) => {
              this.globals.isBusy.message = response.text
              setTimeout(() => { this.globals.isBusy.status = false; }, 1000)
              resolve({
                status: true,
                text: 'All done!'
              })
            })

        })
        .catch((error) => {
          this.globals.isBusy.message = 'Batch update failed -> ' + error
          setTimeout(() => { this.globals.isBusy.status = false; }, 5000)
          resolve({
            status: false,
            text: 'Batch update failed -> ' + error,
          })
        })

    })


  }
  getMatchData(matchDocId: string): any {
    return new Promise((resolve, reject) => {
      const docRef = this.afs.collection('matches').doc<IMatchData>(matchDocId);
      docRef.snapshotChanges().pipe(take(1)).subscribe((doc) => {
        resolve({
          status: true,
          text: '',
          data: doc.payload.data()
        });
      });
    });
  }
  // VARIOUS FUNCTIONS
  getCurrentStandings(eventDocId: string): any {
    console.log('getCurrentStandings', eventDocId);
    return new Promise((resolve, reject) => {
      console.log('eventDocId', eventDocId);
      const playerCollection: AngularFirestoreCollection<IEventPlayerDetails> = this.afs
        .collection('events')
        .doc(eventDocId)
        .collection<IEventPlayerDetails>('players', ref => ref
          .orderBy('matchPoints', 'desc')
          .orderBy('opponentMatchWinPercentage', 'desc')
          .orderBy('gameWinPercentage', 'desc')
          .orderBy('opponentGameWinPercentage', 'desc')
        );

      playerCollection.valueChanges().pipe(take(1)).subscribe((standings) => {
        console.log('...standings >', standings);
        resolve({
          status: true,
          text: 'Fetched players successfully',
          data: standings
        });
      });
    });
  }
  getStructureText(structure: IEventStructureSettings): string {
    if (structure.isSwiss) {
      if (structure.swiss.hasBracketAfterSwiss) {
        return 'Swiss & Playoffs';
      }
      return 'Swiss';
    }
    else if (structure.isBracket) {
      if (structure.bracket.singleElimination) {
        return 'Single Elimination Bracket';
      }
      return 'Double Elimination Bracket';
    }
    else if (structure.isGroup) {
      if (structure.group.hasBracketAfterGroupStage) {
        return 'Group stage & Playoffs';
      }
      return 'Group play';
    }
    else if (structure.isBatch) {
      if (structure.batch.hasBracketAfterBatch) {
        return 'Batch & Playoffs';
      }
      return 'Batch';
    }
    else if (structure.isRoundRobin) {
      return 'Round Robin';
    }
  }
  pad2(n: number) { return n < 10 ? '0' + n : n; }
  getDefaultSwissRounds(attendeeCount: number): number {
    const dataSheet = {
      2: 1,
      4: 2,
      8: 3,
      16: 4,
      32: 5,
      64: 6,
      128: 7,
      226: 8,
      409: 9,
      1000000: 10
    };

    for (const i in dataSheet) {
      // eslint-disable-next-line radix
      if (attendeeCount <= parseInt(i)) {
        // eslint-disable-next-line radix
        return dataSheet[parseInt(i)];
      }
    }
  }
  getPercentage(value: number): string {
    const newValue = value * 100;
    return newValue.toFixed(2);
  }
  getBracketSizes(): Observable<IBracketSize[]> {
    const bracketSizesCollection: AngularFirestoreCollection<IBracketSize> = this.afs
      .collection('bracketSizes', ref => ref
        .orderBy('size', 'asc')
      );
    return bracketSizesCollection.valueChanges();
  }
  private createPlayerData(playerUid: string, playerDocId: string, name: string, playedInGroup: string): Promise<IEventPlayerDetails> {
    return new Promise((resolve, reject) => {
      // return object
      if (name === null) {
        resolve({
          playerUid,
          playerDocId,
          name: this.playerName.getPlayerById(playerDocId).name.display,
          dropped: false,
          disqualified: false,
          haveByeMatch: false,
          wins: 0,
          losses: 0,
          draws: 0,
          matchPoints: 0,
          gamePoints: 0,
          matchWinPercentage: 0,
          gameWinPercentage: 0,
          opponentMatchWinPercentage: 0,
          opponentGameWinPercentage: 0,
          adj_gp: 0,
          adj_gwp: 0,
          adj_mp: 0,
          adj_mwp: 0,
          opponentsDocIds: [],
          deckSubmission: {
            deckPhoto: false,
            deckList: false,
            deckListDocId: null,
            deckVersionDocId: null,
          },
          playedInGroup
        });
      }
      else {
        resolve({
          playerUid,
          playerDocId,
          name,
          dropped: false,
          disqualified: false,
          haveByeMatch: false,
          wins: 0,
          losses: 0,
          draws: 0,
          matchPoints: 0,
          gamePoints: 0,
          matchWinPercentage: 0,
          gameWinPercentage: 0,
          opponentMatchWinPercentage: 0,
          opponentGameWinPercentage: 0,
          adj_gp: 0,
          adj_gwp: 0,
          adj_mp: 0,
          adj_mwp: 0,
          opponentsDocIds: [],
          deckSubmission: {
            deckPhoto: false,
            deckList: false,
            deckListDocId: null,
            deckVersionDocId: null,
          },
          playedInGroup
        });
      }
    });
  }
  deleteEvent(eventDocId: string) {
    return new Promise((resolve, reject) => {
      const eventRef = this.afs.collection('events').doc(eventDocId);
      eventRef
        .delete()
        .then(() => {
          resolve({
            status: true,
            text: 'Event successfully deleted'
          });
        })
        .catch((error) => {
          reject({
            status: false,
            text: error
          });
        });
    });
  }
  getEventRoundTimestamps(eventDocId: string): IRoundTimeStamps {
    const d = new Date(0);
    const event = this.eventDocuments.find(e => e.docId === eventDocId);
    // const roundEnding = d.setUTCSeconds(event.activeRoundEndingTimestamp * 1000);
    // const roundStarted = d.setUTCSeconds(event.activeRoundStartedTimestamp * 1000);
    // const current = d.setUTCSeconds(firestore.Timestamp.now().seconds);
    const roundEnding = event.activeRoundEndingTimestamp;
    const roundStarted = event.activeRoundStartedTimestamp;
    const current = firestore.Timestamp.now().seconds;
    return {
      current,
      roundEnding,
      roundStarted
    };
  }
  getSinglePlayerDocument(eventDocId: string, playerDocId: string): Observable<IEventPlayerDetails> {
    return this.afs
      .collection('events')
      .doc(eventDocId)
      .collection('players')
      .doc<IEventPlayerDetails>(playerDocId)
      .valueChanges();
  }
  async updatePlayerDeckPhoto(
    eventDocId: string,
    playerDocId: string,
    deckList: IDeckList
  ) {

    this.afs.collection('events').doc(eventDocId).collection('players').doc(playerDocId)
      .set({
        deckSubmission: {
          deckListDocId: deckList.docId,
          deckPhotoUrl: deckList.deckPhotoUrl,
          deckPhoto: true,
          deckName: deckList.name,
          deckDescription: deckList.description
        }
      }, { merge: true })
      .then(() => {
        console.log('[ eventService : updatePlayerDeckPhoto ] Player deck list information saved on event-player-document');
      })
      .catch((err) => {
        console.log(err);
      });
  }
  async updatePlayerDeckList(
    eventDocId: string,
    playerDocId: string,
    deckList: IDeckList
  ) {
    this.afs.collection('events').doc(eventDocId).collection('players').doc(playerDocId)
      .set({
        deckSubmission: {
          deckListDocId: deckList.docId,
          deckList: true,
          deckName: deckList.name,
          deckDescription: deckList.description
        }
      }, { merge: true })
      .then(() => {
        console.log('[ eventService : updatePlayerDeckList ] Player deck list information saved on event-player-document');
      })
      .catch((err) => {
        console.log(err);
      });
  }
  downloadDeckPhotos(eventDocId: string, eventName: string) {
    this.globals.isBusy.status = true
    this.globals.isBusy.showMessage = true
    this.globals.isBusy.message = 'Gather all deck photo urls to be zipped, please wait'

    return new Promise(async (resolve) => {

      const callable = this.fns.httpsCallable('callable-downloadEventDeckPhotos')
      await callable({ eventDocId }).toPromise()
        .then((res: any) => {
          console.log('response from firebase.functions', res)
          if (!res.downloadUrl) resolve({ status: false, text: 'no download url present' })

          window.open(res.downloadUrl, '_blank')

          this.globals.isBusy.status = false
          this.globals.isBusy.showMessage = false

          resolve({ status: true, text: 'Success' })
        })
        .catch((error: any) => {
          this.globals.isBusy.status = false
          this.globals.isBusy.showMessage = false

          console.log(error)
          resolve({ status: false, text: error })
        })

    })




    // // get all player names and their deck list urls
    // const players$ = this.afs.collection('events').doc(eventDocId).collection<IEventPlayerDetails>('players').valueChanges();
    // players$.pipe(
    //   map(players => {
    //     const playersDecks: Array<IPlayerDeckSubmission> = [];
    //     players.forEach(player => {
    //       // only add the url if deck photo is submitted
    //       //  && player.deckSubmission.deckPhotoUrl !== '' && player.deckSubmission.deckPhotoUrl !== undefined
    //       if (player.deckSubmission.deckPhoto) {
    //         const fileName = player.name.replace(/ /g, '-').replace(/\(/g, '').replace(/\)/g, '').toLowerCase();
    //         playersDecks.push({
    //           deckListDocId: player.deckSubmission.deckListDocId,
    //           deckVersionDocId: player.deckSubmission.deckVersionDocId,
    //           fileName
    //         });
    //       }
    //     });
    //     return playersDecks;
    //   }),
    //   take(1)
    // ).subscribe(async (playersDecks) => {
    //   for await (const playerDeck of playersDecks) {
    //     await this.storageService.downloadDeckPhoto(playerDeck)
    //   }

    //   // set app in busy mode
    //   this.globals.isBusy.status = false;
    //   this.globals.isBusy.showMessage = false;
    //   this.globals.isBusy.message = 'Done!';
    // });
  }
  dropPlayersWithoutSubmittedDeck(eventDocId: string, deckList: boolean, deckPhoto: boolean): Promise<IPromiseResponse> {
    return new Promise((resolve, reject) => {
      // get all player names and their deck list urls
      const players$ = this.afs.collection('events').doc(eventDocId).collection<IEventPlayerDetails>('players').valueChanges();
      players$.pipe(
        take(1)
      ).subscribe(async (players: Array<IEventPlayerDetails>) => {
        // drop players without submitted deck
        for await (const player of players) {
          if (
            !player.deckSubmission.deckList && deckList ||
            !player.deckSubmission.deckPhoto && deckPhoto
          ) {
            this.afs.collection('events').doc(eventDocId).collection('players').doc(player.playerDocId)
              .update({
                dropped: true
              })
              .catch(err => {
                console.log(err);
              });
          }
        }
        resolve({
          status: true,
          text: 'successfully dropped all playes without submitted deck'
        });
      });
    });
  }
  getPlayersWithoutDeckSubmitted(eventDocId: string, deckList: boolean, deckPhoto: boolean): Promise<IPromiseResponse> {
    return new Promise((resolve, reject) => {
      // get all player names and their deck list urls
      this.afs.collection('events').doc(eventDocId).collection<IEventPlayerDetails>('players').get().toPromise()
        .then(playersSnap => {
          const docs = playersSnap.docs;
          const players: IEventPlayerDetails[] = [];
          docs.forEach(d => players.push(d.data() as IEventPlayerDetails));
          return players;
        })
        .then(async (players: IEventPlayerDetails[]) => {

          // players without submitted deck
          players.sort((a, b) => (a.name > b.name) ? 1 : ((b.name > a.name) ? -1 : 0));

          const playerListMissingPhoto: Array<IPlayersToMention> = [];
          const playerListMissingList: Array<IPlayersToMention> = [];

          for await (const player of players) {
            if (!player.dropped && !player.deckSubmission.deckList && deckList) {
              playerListMissingList.push({
                playerDocId: player.playerDocId,
                playerAtText: player.name
              });
            }
            if (!player.dropped && !player.deckSubmission.deckPhoto && deckPhoto) {
              playerListMissingPhoto.push({
                playerDocId: player.playerDocId,
                playerAtText: player.name
              });
            }
          }

          const data = {
            missingPhoto: playerListMissingPhoto,
            missingList: playerListMissingList
          };
          console.log(data);

          resolve({
            status: true,
            text: 'successfully dropped all playes without submitted deck',
            data
          });
        })
        .catch((err) => console.log(err));
    });
  }
  dropPlayersWitCheckInFalse(eventDocId: string) {
    this.globals.isBusy.status = true;
    this.globals.isBusy.message = 'Dropping all the no-show players';
    this.globals.isBusy.showMessage = true;

    return new Promise((resolve, reject) => {
      const playersRef = this.afs.collection('events').doc(eventDocId).collection<IEventPlayerDetails>('players').valueChanges();
      playersRef.pipe(
        map(players => {
          return players.filter(p => p.hasCheckedIn === undefined || p.hasCheckedIn === false);
        }),
        take(1)
      ).subscribe(async (players) => {
        for await (const player of players) {
          this.afs
            .collection('events')
            .doc(eventDocId)
            .collection<IEventPlayerDetails>('players')
            .doc(player.playerDocId)
            .update({
              dropped: true
            })
            .catch(err => {
              console.log(err);

              this.globals.isBusy.message = 'Something went wrong!';
              this.globals.isBusy.status = false;

              resolve({
                status: false,
                text: err
              });
            });
        }

        this.globals.isBusy.message = 'All no-show players have been dropped';
        this.globals.isBusy.status = false;

        resolve({
          status: true,
          text: 'All no-show players have been dropped'
        });
      });
    });
  }
  checkMeIn(eventDocId: string) {
    const playerDocId = this.auth.user.playerId;
    this.afs.collection('events').doc(eventDocId).collection('players').doc(playerDocId).update({
      hasCheckedIn: true
    });
  }
  checkInPlayer(eventDocId: string, playerDocId: string) {
    return new Promise((resolve, reject) => {
      this.afs.collection('events').doc(eventDocId).collection('players').doc(playerDocId)
        .update({
          hasCheckedIn: true
        })
        .then(() => {
          resolve(true)
        })
        .catch((error) => {
          reject(true)
        })
    })
  }
  checkOutPlayer(eventDocId: string, playerDocId: string) {
    this.afs.collection('events').doc(eventDocId).collection('players').doc(playerDocId).update({
      hasCheckedIn: false
    });
  }
  getEventTimeMeta(eventDocId: string): Observable<IEventTimeMeta> {
    return this.events$.pipe(
      map(events => {
        const event = events.find(e => e.docId === eventDocId);
        if (event !== undefined) {
          const current = firestore.Timestamp.now().seconds;
          const meta: IEventTimeMeta = {
            roundIsActive: event.statusCode === 3,
            timestampNow: current,
            timestampStart: event.activeRoundStartedTimestamp,
            timestampEnd: event.activeRoundEndingTimestamp,
            roundClock: event.activeRoundEndingTimestamp - current
          };
          return meta;
        }
      })
    );
  }
  getOrganizerIdsForEventWithId(eventDocId: string): Promise<string[]> {
    return new Promise((resolve, reject) => {
      this.events$.pipe(
        map((events) => {
          // get the event with given id
          return events.find(e => e.docId === eventDocId);
        }),
        take(1)
      ).subscribe((event) => {
        // check if event exists
        if (event) {
          const organizers: Array<string> = event.coOrganizers;
          organizers.push(event.createdByUid);
          resolve(organizers);
        }
        // if not, return an empty array
        else {
          resolve([]);
        }
      });
    });
  }
  addCoOrganizerToEvent(eventDocId: string, organizerUid: string): Promise<boolean> {
    return new Promise((resolve, reject) => {
      // log activity
      let organizer = this.playerName.getPlayerByUid(organizerUid)
      let name = organizer.name.display
      
      const timestamp = firestore.Timestamp.now().seconds;
      const logText: IEventLog = {
        type: 'other',
        timestamp,
        text: `${name} added as a co-organizer`,
        metadata: {
          uid: organizerUid,
          name: name,
          addedByDocId: this.auth.user.playerId,
        }
      }

      // firestore.arrayUnion(logText)

      const batch = this.afs.firestore.batch()

      batch.update(this.afs.collection('events').doc(eventDocId).ref, {
        coOrganizers: firestore.arrayUnion(organizerUid)
      })

      batch.set(this.afs.collection('events').doc(eventDocId).collection('log').doc(`log-${uuidv4()}`).ref, logText)

      batch.commit()
        .then((res) => {
          console.log(res);
          this.toastService.show(`${name} is now an co-organizer for the event`, { classname: 'success-toast', delay: 2000 });
          resolve(true);
        })
        .catch((err) => {
          console.log(err);
          this.toastService.show(err, { classname: 'error-toast', delay: 10000 });
          resolve(false);
        });

    });

  }
  removeCoOrganizerToEvent(eventDocId: string, organizerUid: string): Promise<boolean> {
    return new Promise((resolve, reject) => {

      const organizer = this.playerName.getPlayerByUid(organizerUid)

      const timestamp = firestore.Timestamp.now().seconds
      const logText: IEventLog = {
        type: 'other',
        timestamp,
        text: `Player with uid: ${organizerUid} (${organizer.name.display}) removed as co-organizer`,
        metadata: {
          uid: organizerUid,
          name: organizer.name.display,
          removedByDocId: this.auth.user.playerId,
        }
      }

      const batch = this.afs.firestore.batch()

      batch.update(this.afs.collection('events').doc(eventDocId).ref, {
        coOrganizers: firestore.arrayRemove(organizerUid),
      })
      batch.set(this.afs.collection('events').doc(eventDocId).collection('log').doc(`log-${uuidv4()}`).ref, logText)

      batch.commit()
        .then((res) => {
          console.log(res);
          this.toastService.show(`${organizer.name.display} is no longer an co-organizer for the event`, { classname: 'success-toast', delay: 2000 });
          resolve(true);
        })
        .catch((err) => {
          console.log(err);
          this.toastService.show(err, { classname: 'error-toast', delay: 10000 });
          resolve(false);
        });

    });
  }
  async performMoreActionsOnAddingPlayer(eventDoc: IEventDetails, player: IPlayerMeta | string, moreAction: string, group: string) {
    console.log({ eventDoc, player, moreAction, group, });
    // create empty player object
    const matchPlayerObject: IMatchPlayer = {
      playerDocId: typeof player === 'string' ? 'temp__' + player.toLowerCase() : player.docId,
      playerUid: typeof player === 'string' ? 'temp__' + player.toLowerCase() : player.uid,
      displayName: typeof player === 'string' ? player : player.name,
      isWinner: false,
      wins: 0,
      draws: 0,
      losses: 0,
      drop: false,
      matchPoints: 0,
      lifePoints: [20]
    };
    this.globals.isBusy.status = true;
    this.globals.isBusy.showMessage = true;
    this.globals.isBusy.message = 'Creating needed documents';
    switch (moreAction) {
      case 'add':
        // nothing
        break;
      case 'add-unpaired':
        // check if batch event
        if (eventDoc.details.structure.isBatch) {
          // add player to the UNPAIRED array for each segment of current batch and set event to manual pairing mode
          const batchIndex = eventDoc.details.structure.batch.batches.findIndex(b => b.roundNumber === eventDoc.activeRound);
          for (let segment = 1; segment <= eventDoc.details.structure.batch.batches[batchIndex].numberOfMatches; segment++) {
            matchPlayerObject.segmentNumber = segment;
            this.globals.isBusy.message = 'Adding player to unpaired list for segment ' + segment;
            await this.addPlayerToUnpairedArray(eventDoc, matchPlayerObject, segment);
          }
        }
        else {
          // add player to the UNPAIRED array for current round and set event to manual pairing mode
          this.globals.isBusy.message = 'Adding player to unpaired list.';
          await this.addPlayerToUnpairedArray(eventDoc, matchPlayerObject);

        }
        // set status to manual pairing
        this.globals.isBusy.message = 'Updating event status to manual pairing mode.';
        await this.eventUpdateStatus(eventDoc, 11);
        break;
      case 'add-loss':
        // check if batch event
        if (eventDoc.details.structure.isBatch) {
          // create a LOSS match for the player for each segment in the current round
          const batchIndex = eventDoc.details.structure.batch.batches.findIndex(b => b.roundNumber === eventDoc.activeRound);
          for (let segment = 1; segment <= eventDoc.details.structure.batch.batches[batchIndex].numberOfMatches; segment++) {
            await this.addMatchToEvent(eventDoc, matchPlayerObject, 'loss', segment);
          }
        }
        else {
          // create a LOSS match for the player in the current round
          await this.addMatchToEvent(eventDoc, matchPlayerObject, 'loss');
        }
        break;
      case 'add-bye':
        // check if batch event
        if (eventDoc.details.structure.isBatch) {
          // create a BYE match for the player for each segment in the current round
          const batchIndex = eventDoc.details.structure.batch.batches.findIndex(b => b.roundNumber === eventDoc.activeRound);
          for (let segment = 1; segment <= eventDoc.details.structure.batch.batches[batchIndex].numberOfMatches; segment++) {
            await this.addMatchToEvent(eventDoc, matchPlayerObject, 'bye', segment);
          }
        }
        else {
          // create a BYE match for the player in the current round
          await this.addMatchToEvent(eventDoc, matchPlayerObject, 'bye');
        }
        break;
      case 'add-pair-all-other':
        // create a new match for each player
        this.addPairAgainstAllOthers(eventDoc, matchPlayerObject);
        break;
      case 'add-into-group':
        this.addPairAgainstAllOthersInGroup(eventDoc, matchPlayerObject, group);
        break;
    }
    setTimeout(() => {
      this.globals.isBusy.showMessage = true;
      this.globals.isBusy.status = false;
    }, 1000);
  }
  private async addPlayerToUnpairedArray(event: IEventDetails, player: IMatchPlayer, segmentNumber: number = null) {
    // add player to unpaired lsit
    this.globals.isBusy.message = event.details.structure.isBatch ? 'Adding player to unpaired list. (segment: ' + segmentNumber + ')' : 'Adding player to unpaired list.';
    await this.afs.collection('events').doc(event.docId)
      .update({
        unpairedPlayers: firestore.arrayUnion(player)
      })
      .catch((error) => {
        console.log(error);
      });
    // add an empty match object for the current round and segment
    // create empyt player object
    this.globals.isBusy.message = event.details.structure.isBatch
      ? 'Creating and storing the needed match document. (segment: ' + segmentNumber + ')'
      : 'Creating and storing the needed match document.';
    const emptyPlayer: IMatchPlayer = {
      playerDocId: '',
      playerUid: '',
      displayName: '',
      isWinner: false,
      wins: 0,
      draws: 0,
      losses: 0,
      drop: false,
      matchPoints: 0,
      lifePoints: [20],
      segmentNumber
    };
    const guid = uuidv4();
    const emptyMatchObject: IMatchData = {
      docId: guid,
      eventDocId: event.docId,
      createdByUid: event.createdByUid,
      feedsMatchDocId: '',
      feedsMatchPlayer: '',
      winnerGoToMatchDoc: '',
      winnerGoToPlayerSlot: '',
      loserGoToMatchDoc: '',
      loserGoToPlayerSlot: '',
      isDraw: false,
      isReported: false,
      isByeMatch: false,
      isLossMatch: false,
      isType: this.getEventTypeText(event.details.structure),
      player1: emptyPlayer,
      player2: emptyPlayer,
      playerFilterValue: '',
      roundNumber: event.activeRound,
      segmentNumber,
      segmentType: 'manual',
      tableNumber: 0,
      playerDocIds: [],
      groupName: '',
      timestampCreated: firestore.Timestamp.now().seconds,
      timestampReported: null,
      deleted: false,
      reportSlipOpenedBy: null,
    };
    await this.afs.collection('matches').doc(guid).set(emptyMatchObject);
  }
  private async addMatchToEvent(event: IEventDetails, player: IMatchPlayer, type: string, segmentNumber: number = null, group: string = null, opponent?: IMatchPlayer) {
    // constant variables
    const guid = uuidv4();
    const byePlayer: IMatchPlayer = {
      playerDocId: '*** BYE ***',
      playerUid: '*** BYE ***',
      displayName: '*** BYE ***',
      isWinner: false,
      wins: 0,
      draws: 0,
      losses: 2,
      drop: false,
      matchPoints: 0,
      lifePoints: [20],
      segmentNumber
    };
    const lossPlayer: IMatchPlayer = {
      playerDocId: '*** LOSS ***',
      playerUid: '*** LOSS ***',
      displayName: '*** LOSS ***',
      isWinner: true,
      wins: 2,
      draws: 0,
      losses: 0,
      drop: false,
      matchPoints: 0,
      lifePoints: [20],
      segmentNumber
    };

    // update player stats depending on match type
    if (type === 'loss' && opponent === undefined) {
      player.isWinner = false;
      player.wins = 0;
      player.losses = 2;
      opponent = lossPlayer;
    }
    if (type === 'bye' && opponent === undefined) {
      player.isWinner = true;
      player.wins = 2;
      player.losses = 0;
      opponent = byePlayer;
    }

    const emptyMatchObject: IMatchData = {
      docId: guid,
      eventDocId: event.docId,
      createdByUid: event.createdByUid,
      feedsMatchDocId: '',
      feedsMatchPlayer: '',
      winnerGoToMatchDoc: '',
      winnerGoToPlayerSlot: '',
      loserGoToMatchDoc: '',
      loserGoToPlayerSlot: '',
      isDraw: false,
      isReported: false,
      isByeMatch: false,
      isLossMatch: false,
      isType: this.getEventTypeText(event.details.structure),
      player1: player,
      player2: opponent,
      playerFilterValue: player.displayName + ' ' + opponent.displayName,
      roundNumber: event.activeRound,
      segmentNumber,
      segmentType: 'manual',
      tableNumber: 0,
      playerDocIds: [
        player.playerDocId,
        opponent.playerDocId
      ],
      groupName: group,
      timestampCreated: firestore.Timestamp.now().seconds,
      timestampReported: null,
      deleted: false,
      reportSlipOpenedBy: null,
    };

    // update match object
    if (type === 'loss') {
      emptyMatchObject.isLossMatch = true;
      emptyMatchObject.isReported = true;
    }
    if (type === 'bye') {
      emptyMatchObject.isByeMatch = true;
      emptyMatchObject.isReported = true;
    }

    this.globals.isBusy.message = event.details.structure.isBatch ? 'Adding ' + type + ' match to event (segment: ' + segmentNumber + ').' : 'Adding ' + type + ' match to event.';
    await this.afs.collection('matches').doc(guid).set(emptyMatchObject);
    console.log('new match created', emptyMatchObject)
  }
  private async addPairAgainstAllOthers(event: IEventDetails, player: IMatchPlayer) {
    // get all players
    const playerRef = this.afs
      .collection('events')
      .doc(event.docId)
      .collection<IEventPlayerDetails>('players');

    playerRef.get().toPromise().then((docs) => {
      docs.forEach((doc) => {
        const playerDoc = doc.data() as IEventPlayerDetails
        if (playerDoc.playerDocId !== player.playerDocId && !playerDoc.dropped) {
          // crate a match
          const opponent: IMatchPlayer = {
            playerDocId: playerDoc.playerDocId,
            playerUid: playerDoc.playerUid,
            displayName: playerDoc.name,
            isWinner: false,
            wins: 0,
            draws: 0,
            losses: 0,
            drop: false,
            matchPoints: 0,
            lifePoints: [20]
          };
          this.addMatchToEvent(event, player, 'normal', null, null, opponent);
        }
      });
    });

  }
  private async addPairAgainstAllOthersInGroup(event: IEventDetails, player: IMatchPlayer, group: string) {
    console.log({
      group,
      event,
      player,
    });
    // get all players
    const playerRef = this.afs
      .collection('events')
      .doc(event.docId)
      .collection<IEventPlayerDetails>('players');

    playerRef.valueChanges().pipe(take(1)).subscribe((players) => {
      players.filter(p => p.playedInGroup === group && !p.dropped).forEach((playerDoc) => {
        if (playerDoc.playerDocId !== player.playerDocId) {
          // crate a match
          const opponent: IMatchPlayer = {
            playerDocId: playerDoc.playerDocId,
            playerUid: playerDoc.playerUid,
            displayName: playerDoc.name,
            isWinner: false,
            wins: 0,
            draws: 0,
            losses: 0,
            drop: false,
            matchPoints: 0,
            lifePoints: [20]
          };
          this.addMatchToEvent(event, player, 'normal', null, group, opponent);
        }
      });
    });

  }
  private getEventTypeText(eventStructure: IEventStructureSettings): MatchType {
    if (eventStructure.isBatch) { return 'batch'; }
    if (eventStructure.isBracket) { return 'bracket'; }
    if (eventStructure.isGroup) { return 'group'; }
    if (eventStructure.isRoundRobin) { return 'round-robin'; }
    if (eventStructure.isSwiss) { return 'swiss'; }
  }
  async removePlayerFromUnpairedList(eventDocId: string, unpairedPlayer: IMatchPlayer) {
    this.afs.collection('events').doc(eventDocId)
      .update({
        unpairedPlayers: firestore.arrayRemove(unpairedPlayer),
      })
      .catch((error) => {
        console.log(error);
      });
  }
  async addOpponentToPlayersOpponentList(eventDocId: string, playerDocId: string, opponentDocId: string) {
    this.afs.collection('events').doc(eventDocId).collection('players').doc(playerDocId)
      .update({
        opponentDocIds: firestore.arrayUnion(opponentDocId)
      })
      .catch((error) => {
        console.log(error);
      });
  }
  async removeOpponentFromPlayersOpponentList(eventDocId: string, playerDocId: string, opponentDocId: string) {
    this.afs.collection('events').doc(eventDocId).collection('players').doc(playerDocId)
      .update({
        opponentDocIds: firestore.arrayRemove(opponentDocId)
      })
      .catch((error) => {
        console.log(error);
      });
  }
  startCheckIn(eventDocId: string) {
    this.afs.collection('events').doc(eventDocId).update({
      checkInHasStarted: true,
      checkInByPlayer: false,
    });
  }
  closeCheckIn(eventDocId: string) {
    this.afs.collection('events').doc(eventDocId).update({
      checkInHasStarted: false
    });
  }
  toggleManualCheckIn(eventDocId: string, state: boolean) {
    this.afs.collection('events').doc(eventDocId).update({
      checkInByPlayer: state
    });
  }
  async addBatchToEvent(event: IEventDetails, batchConfig: IBatchConfig) {
    // set app busy and show info text
    this.globals.isBusy.status = true;
    this.globals.isBusy.message = 'Generating batch...';

    // set batch to new round
    batchConfig.roundNumber = event.details.structure.batch.batches.length + 1;
    // activate new batch
    event.activeRound = batchConfig.roundNumber;
    // add batch to the event
    event.details.structure.batch.batches.push(batchConfig);
    // update event data
    this.updateEventData(event)
      .then((response: IPromiseResponse) => {
        this.globals.isBusy.message = response.text;
        if (!response.status) {
          setTimeout(() => {
            this.globals.isBusy.status = false;
          }, 3000);
        }
        else {
          // Pair the added batch
          this.eventPairBatch(event)
            .then((res: IPromiseResponse) => {
              // update loader text
              this.globals.isBusy.message = res.text;
              // if response is OK, the update event status
              if (res.status) {
                // call update status
                this.eventUpdateStatus(event, 2).then((res2: IPromiseResponse) => {
                  // update loader text
                  this.globals.isBusy.message = res2.text;
                  // hide loader modal
                  setTimeout(() => {
                    this.globals.isBusy.status = false;
                    return true;
                  }, res2.status ? 1000 : 5000);
                });
              }
              else {
                // remove batch to the event
                event.details.structure.batch.batches.pop();
                // set batch to new round
                batchConfig.roundNumber = event.details.structure.batch.batches.length + 1;
                // activate new batch
                event.activeRound = batchConfig.roundNumber;
                // call update status
                this.eventUpdateStatus(event, 4).then((res2: IPromiseResponse) => {
                  // update loader text
                  this.globals.isBusy.message = res2.text;
                  // hide loader modal
                  setTimeout(() => {
                    this.globals.isBusy.status = false;
                    return true;
                  }, res2.status ? 1000 : 5000);
                });
              }
            });
        }
      });


  }
  public getEventNames(): Promise<IEventLinkMeta[]> {
    const colRef = this.afs.collection<IEventDetails>('events');
    return colRef.get().pipe(
      map(snap => {
        const eventList: IEventLinkMeta[] = [];
        snap.docs.forEach((event) => {
          const tmpEvent = event.data();
          const data: IEventLinkMeta = {
            eventDocId: tmpEvent.docId,
            eventName: tmpEvent.details.name
          };
          eventList.push(data);
        });

        return eventList;
      })
    ).toPromise();
  }
  public removeInvitatedPlayer(invitedPlayer: IInvitedPlayer): void {
    this.afs.collection('events').doc(invitedPlayer.eventDocId)
      .update({
        invitedPlayers: firestore.arrayRemove(invitedPlayer)
      })
      .then(() => {
        this.toastService.show('Invitation removed', { classname: 'success-toast', delay: 2000 })
      })
      .catch((error) => {
        this.toastService.show(`Something went wrong... ${error}`, { classname: 'error-toast', delay: 6000 })
      })
  }
  public async sendEmailEventAnnouncement(eventDocId: string, recipients: EventAnnouncementRecipientType, message: string) {
    const callable = this.fns.httpsCallable<IEventAnnouncementData, any>('email-eventAnnouncement')
    await callable({ eventDocId, recipients, message }).toPromise()
      .then((res: any) => {
        console.log('response from firebase.functions', res)
        if (res.status) this.toastService.show(res.text, { classname: 'success-toast', delay: 2000 })
        else this.toastService.show(res.text, { classname: 'error-toast', delay: 6000 })
      })
      .catch((error: any) => {
        console.log(error)
        this.toastService.show(error, { classname: 'error-toast', delay: 6000 })
      })
  }
  public enrollTeam(eventDocId: string, team: IEventTeam): Promise<boolean> {

    return new Promise(async (resolve) => {

      const batch = this.afs.firestore.batch()

      const teamDocRed = this.afs.collection('events').doc(eventDocId).collection('teams').doc(team.id).ref
      batch.set(teamDocRed, team)

      const eventDocRef = this.afs.collection('events').doc(eventDocId).ref
      batch.update(eventDocRef, {
        teamIds: firestore.arrayUnion(team.id)
      })

      for await (const playerDocId of team.playerDocIds) {
        // check if local player
        if (playerDocId.includes('temp__')) {
          const localPlayer = team.localPlayers.find(p => p.id === playerDocId)
          const playerData = await this.createPlayerData(playerDocId, playerDocId, localPlayer.name, null)
          const playerRef = this.afs.collection('events').doc(eventDocId).collection('players').doc(playerDocId).ref
          playerData.teamId = team.id
          batch.set(playerRef, playerData)
          batch.update(eventDocRef, {
            playerDocIds: firestore.arrayUnion(playerDocId)
          })
        }
        // tolaria player
        else {
          let playerMini = this.playerName.getPlayerById(playerDocId)
          const playerData = await this.createPlayerData(playerMini.uid, playerMini.id, `${playerMini.name.first} ${playerMini.name.last}`, null)
          const playerRef = this.afs.collection('events').doc(eventDocId).collection('players').doc(playerMini.id).ref
          playerData.teamId = team.id
          batch.set(playerRef, playerData)
          batch.update(eventDocRef, {
            playerDocIds: firestore.arrayUnion(playerMini.id)
          })
        }
      }

      batch.commit()
        .then(() => {
          console.log('All documents created and event updated')
          resolve(true)
        })
        .catch((error) => {
          console.log(error)
          resolve(false)
        })
    })



  }
  public saveTeam(eventDocId: string, team: IEventTeam, playersToAdd: string[], playersToRemove: string[]): Promise<boolean> {

    return new Promise(async (resolve) => {
      const eventDocRef = this.afs.collection('events').doc(eventDocId).ref
      const teamDocRed = this.afs.collection('events').doc(eventDocId).collection('teams').doc(team.id).ref
      const batch = this.afs.firestore.batch()


      batch.set(teamDocRed, team)


      for await (const playerDocId of playersToAdd) {
        let playerMini = this.playerName.getPlayerById(playerDocId)
        const playerData = await this.createPlayerData(playerMini.uid, playerMini.id, `${playerMini.name.first} ${playerMini.name.last}`, null)
        const playerRef = this.afs.collection('events').doc(eventDocId).collection('players').doc(playerMini.id).ref
        playerData.teamId = team.id
        batch.set(playerRef, playerData)
        batch.update(eventDocRef, {
          playerDocIds: firestore.arrayUnion(playerDocId)
        })
      }

      for await (const playerDocId of playersToRemove) {
        const playerRef = this.afs.collection('events').doc(eventDocId).collection('players').doc(playerDocId).ref
        batch.delete(playerRef)
        batch.update(eventDocRef, {
          playerDocIds: firestore.arrayRemove(playerDocId)
        })
      }


      batch.commit()
        .then(() => {
          console.log('All documents created and event updated')
          resolve(true)
        })
        .catch((error) => {
          console.log(error)
          resolve(false)
        })
    })



  }
  public unattendTeam(eventDocId: string, team: IEventTeam): Promise<boolean> {
    return new Promise(async (resolve) => {

      const batch = this.afs.firestore.batch()

      // update event document --> remove team and players from arrays
      const eventDocRef = this.afs.collection('events').doc(eventDocId).ref
      batch.update(eventDocRef, {
        teamIds: firestore.arrayRemove(team.id),
      })

      // delete the team document
      const teamDocRed = this.afs.collection('events').doc(eventDocId).collection('teams').doc(team.id).ref
      batch.delete(teamDocRed)

      // delete the player documents
      for (const playerDocId of team.playerDocIds) {
        const playerRef = this.afs.collection('events').doc(eventDocId).collection('players').doc(playerDocId).ref
        batch.delete(playerRef)
        batch.update(eventDocRef, {
          playerDocIds: firestore.arrayRemove(playerDocId)
        })
      }


      // perform updates
      batch.commit()
        .then(() => {
          console.log('All documents deleted and event updated')
          resolve(true)
        })
        .catch((error) => {
          console.log(error)
          resolve(false)
        })



    })
  }
  public async pairSwiss(eventDocId: string) {

    // get event document
    const snapEventDoc = await firstValueFrom(this.afs.collection('events').doc<IEventDetails>(eventDocId).get())
    if (!snapEventDoc.exists) {
      console.log(`eventService > pairSwiss ---> event document does not exist`)
      return false
    }
    const eventDocument = snapEventDoc.data()


    // get player documents
    const snapPlayerDocs = await firstValueFrom(this.afs.collection('events').doc(eventDocId).collection<IEventPlayerDetails>('players').get())
    if (snapPlayerDocs.empty) {
      console.log(`eventService > pairSwiss ---> no event player documents found`)
      return false
    }
    const playerDocuments = snapPlayerDocs.docs.map(i => i.data())

    // get matches
    const snapMatchDocs = await firstValueFrom(this.afs.collection<IMatchData>('matches', ref => ref.where('eventDocId', '==', eventDocId)).get())
    if (snapMatchDocs.empty) {
      console.log(`eventService > pairSwiss ---> no match documents found (must be first round)`)
    }
    const matchDocuments = snapMatchDocs.docs.map(i => i.data())

    // extend players with up to date stats
    const updatedPlayers = this.__getExtendedPlayers(playerDocuments, matchDocuments, eventDocument)


    // create pairings
    let pairings = Swiss(updatedPlayers, eventDocument.activeRound, eventDocument.activeRound === eventDocument.details.structure.swiss.roundsToPlay)

    // check if pairings was returned
    if (pairings.length !== Math.ceil(playerDocuments.filter(i => !i.dropped).length / 2)) {

      console.log(`eventService > pairSwiss ---> failed to create pairings!`)

      if (eventDocument.activeRound === eventDocument.details.structure.swiss.roundsToPlay) {

        console.log(`eventService > pairSwiss ---> trying to pair based on match points instead of standings`)
        this.toastService.show('Pairings based on standings failed, pairings based on match points used instead!', { classname: 'error-toast', delay: 4000 })

        pairings = Swiss(updatedPlayers, eventDocument.activeRound, false)

        if (pairings.length !== Math.ceil(playerDocuments.filter(i => !i.dropped).length / 2)) {
          console.log(`eventService > pairSwiss ---> failed to create pairings yet again!`)
          this.toastService.show('Failed to create pairings, please try again!', { classname: 'error-toast', delay: 4000 })
          return
        }

      }

      return

    }


    // create firestore batch writer
    const batchWrites: firebase.default.firestore.WriteBatch[] = []
    let batchWriteIndex = 0
    let writeCount = 0

    // create local method to update counter and index of the batch writer
    const __incrementWriteCounter = () => {
      writeCount++
      if ((writeCount % 500) === 0 && (writeCount / 500) === (batchWriteIndex + 1)) {
        batchWrites.push(this.afs.firestore.batch())
        batchWriteIndex++
      }
    }

    // add first batch
    batchWrites.push(this.afs.firestore.batch())


    // loop through pairings and create matches
    const matches: IMatchData[] = []
    for await (const match of pairings) {

      // get players
      const player1 = updatedPlayers.find(t => t.playerDocId === match.player1)
      const player2 = updatedPlayers.find(t => t.playerDocId === match.player2)

      // create the match document
      const matchData = new EventMatch(
        eventDocId,
        eventDocument.createdByUid,
        eventDocument.activeRound,
        null,
        match.match,
        'swiss',
        player1,
        player2,
        player2 === null || player2 === undefined ? 'Bye' : null,
      )
      matches.push(matchData.document)

      // update player documents if paired up or down
      if (player2 !== null && player2 !== undefined && player1.matchPoints !== player2.matchPoints) {
        __incrementWriteCounter()
        const updateRef1 = this.afs.collection('events').doc(eventDocId).collection('players').doc(player1.playerDocId).ref
        batchWrites[batchWriteIndex].update(updateRef1, {
          pairedUpDown: true
        })
        __incrementWriteCounter()
        const updateRef2 = this.afs.collection('events').doc(eventDocId).collection('players').doc(player2.playerDocId).ref
        batchWrites[batchWriteIndex].update(updateRef2, {
          pairedUpDown: true
        })
      }

      // update player with bye flag if needed
      if (player2 === null || player2 === undefined) {
        __incrementWriteCounter()
        const updateRef = this.afs.collection('events').doc(eventDocId).collection('players').doc(player1.playerDocId).ref
        batchWrites[batchWriteIndex].update(updateRef, {
          haveByeMatch: true,
          receivedBye: true
        })
      }

    }

    const timestampCreated = firestore.Timestamp.now().seconds
    let tableNumber = eventDocument.details.startingTable
    for await (const match of matches.sort((a, b) => {
      if (a.playerDocIds.includes('*** BYE ***') || a.playerDocIds.includes('*** LOSS ***')) {
        return 1
      }
      else if (b.playerDocIds.includes('*** BYE ***') || b.playerDocIds.includes('*** LOSS ***')) {
        return -1
      }
      else {
        return Math.min(a.player1.seed, a.player2.seed) - Math.min(b.player1.seed, b.player2.seed)
      }
    })) {
      match.timestampCreated = timestampCreated
      match.tableNumber = tableNumber
      tableNumber++
    }

    console.log({
      matches,
      updatedPlayers,
    })

    for await (const match of matches) {
      __incrementWriteCounter()
      const setRef = this.afs.collection('matches').doc(match.docId).ref
      batchWrites[batchWriteIndex].set(setRef, match)
    }

    // perform updates
    for await (const [index, batch] of batchWrites.entries()) {
      batch
        .commit()
        .then(() => console.log(`...batchWrite #${index + 1} successful`))
        .catch((e) => {
          console.log(e)
          return
        })
    }


    this.eventUpdateStatus(eventDocument, 13)


  }
  public __getExtendedPlayers(players: IEventPlayerDetails[], matches: IMatchData[], event: IEventDetails): IEventPlayerDetails[] {

    let playersData: IEventPlayerDetails[] = []

    switch (this.getEventTypeText(event.details.structure)) {


      case 'group':
      case 'batch':
      case 'round-robin':
      default:

        playersData = []

        for (const player of players) {

          // get matches as player1
          const stageMatches = matches.filter(i => i.isType !== 'bracket' && i.isReported && !i.deleted)
          const playoffMatches = [
            ...matches.filter(i => i.isType === 'bracket' && i.player1.playerDocId === player.playerDocId).map(i => i.player1),
            ...matches.filter(i => i.isType === 'bracket' && i.player2.playerDocId === player.playerDocId).map(i => i.player2),
          ]
          const matchesAsPlayer1 = stageMatches.filter(i => i.player1.playerDocId === player.playerDocId).map(i => {
            return {
              player: i.player1,
              match: i,
            }
          })
          const matchesAsPlayer2 = stageMatches.filter(i => i.player2.playerDocId === player.playerDocId).map(i => {
            return {
              player: i.player2,
              match: i,
            }
          })
          const playersMatchData = [...matchesAsPlayer1, ...matchesAsPlayer2]

          // create the extended team object
          const playerData = player as IEventPlayerDetails
          playerData.opponentsDocIds = [
            ...stageMatches.filter(i => i.player1.playerDocId === player.playerDocId).map(i => i.player2.playerDocId),
            ...stageMatches.filter(i => i.player2.playerDocId === player.playerDocId).map(i => i.player1.playerDocId)
          ]
          playerData.gamesWon = playersMatchData.map(i => i.player.wins).reduce((sum, val) => sum + val, 0)
          playerData.gamesLost = playersMatchData.map(i => i.player.losses).reduce((sum, val) => sum + val, 0)
          playerData.gamesDrawn = playersMatchData.map(i => i.player.draws).reduce((sum, val) => sum + val, 0)
          playerData.gamesPlayed = (playerData.gamesWon + playerData.gamesLost + playerData.gamesDrawn)
          playerData.gamePoints = (playerData.gamesWon * 3) + (playerData.gamesDrawn * 1)
          playerData.matchesWon = playersMatchData.filter(i => i.player.isWinner && !i.match.isDraw).length
          playerData.matchesLost = playersMatchData.filter(i => !i.player.isWinner && !i.match.isDraw).length
          playerData.matchesDrawn = playersMatchData.filter(i => i.match.isDraw).length
          playerData.matchesPlayed = playersMatchData.length
          playerData.matchPoints = (playerData.matchesWon * 3) + (playerData.matchesDrawn * 1)
          playerData.avoid = playerData.opponentsDocIds
          playerData.selected = false
          playerData.dropped = playersMatchData.filter(i => i.player.drop).length > 0 || player.dropped
          playerData.haveByeMatch = playersMatchData.filter(i => i.match.isByeMatch).length > 0
          playerData.playoffMatchesPlayed = playoffMatches.length
          playerData.playoffMatchesWon = playoffMatches.filter(i => i.isWinner).length
          playerData.playedInGroup = player.playedInGroup !== undefined && player.playedInGroup !== null && player.playedInGroup !== ''
            ? player.playedInGroup
            : ''

          playersData.push(playerData)

        }

        return playersData

    }


  }
  public async pairSwissTeam(eventDocId: string) {

    // create firestore batch writer
    const batchWrites: firebase.default.firestore.WriteBatch[] = []
    let batchWriteIndex = 0
    let writeCount = 0

    // create local method to update counter and index of the batch writer
    const __incrementWriteCounter = () => {
      writeCount++
      if ((writeCount % 500) === 0 && (writeCount / 500) === (batchWriteIndex + 1)) {
        batchWrites.push(this.afs.firestore.batch())
        batchWriteIndex++
      }
    }

    // add first batch
    batchWrites.push(this.afs.firestore.batch())




    // get event document
    const snapEventDoc = await firstValueFrom(this.afs.collection('events').doc<IEventDetails>(eventDocId).get())
    if (!snapEventDoc.exists) {
      console.log(`eventService > pairSwissTeam ---> event document does not exist`)
      return false
    }
    const eventDocument = snapEventDoc.data()

    // get team documents
    const snapTeamDocs = await firstValueFrom(this.afs.collection('events').doc(eventDocId).collection<IEventTeam>('teams').get())
    if (snapTeamDocs.empty) {
      console.log(`eventService > pairSwissTeam ---> no event team documents found`)
      return false
    }
    const teamDocuments = snapTeamDocs.docs.map(i => i.data())

    // get player documents
    const snapPlayerDocs = await firstValueFrom(this.afs.collection('events').doc(eventDocId).collection<IEventPlayerDetails>('players').get())
    if (snapPlayerDocs.empty) {
      console.log(`eventService > pairSwissTeam ---> no event player documents found`)
      return false
    }
    const playerDocuments = snapPlayerDocs.docs.map(i => i.data())

    // get matches
    const snapMatchDocs = await firstValueFrom(this.afs.collection<IMatchData>('matches', ref => ref.where('eventDocId', '==', eventDocId)).get())
    if (snapMatchDocs.empty) {
      console.log(`eventService > pairSwissTeam ---> no match documents found (must be first round)`)
    }
    const matchDocuments = snapMatchDocs.docs.map(i => i.data())

    const extendedTeams = this.__getExtendedTeams(teamDocuments, matchDocuments, eventDocument)

    const pairings = SwissTeam(extendedTeams.filter(i => !i.dropped), eventDocument.activeRound)

    const matches: IMatchData[] = []

    for await (const match of pairings) {

      const seats: ('a' | 'b' | 'c')[] = ['a', 'b', 'c']
      const teamA = extendedTeams.find(t => t.id === match.player1)
      const teamB = extendedTeams.find(t => t.id === match.player2)


      if (teamB !== null && teamB !== undefined) {
        seats.forEach(seat => {
          const player1 = playerDocuments.find(p => p.playerDocId === teamA.player[seat])
          const player2 = playerDocuments.find(p => p.playerDocId === teamB.player[seat])
          const matchData = new EventMatch(
            eventDocId,
            eventDocument.createdByUid,
            eventDocument.activeRound,
            null,
            null,
            'swiss-team',
            player1,
            player2,
            null,
          )
          matchData.document.teamSeat = seat
          matches.push(matchData.document)
        })

        if (teamA.matchesWon !== teamB.matchesWon) {
          __incrementWriteCounter()
          const updateRef1 = this.afs.collection('events').doc(eventDocId).collection('teams').doc(teamA.id).ref
          batchWrites[batchWriteIndex].update(updateRef1, {
            pairedUpDown: true
          })
          __incrementWriteCounter()
          const updateRef2 = this.afs.collection('events').doc(eventDocId).collection('teams').doc(teamB.id).ref
          batchWrites[batchWriteIndex].update(updateRef2, {
            pairedUpDown: true
          })
        }
      }
      else {
        seats.forEach(seat => {
          const player1 = playerDocuments.find(p => p.playerDocId === teamA.player[seat])
          const player2 = null // no player as this will be a bye match
          const matchData = new EventMatch(
            eventDocId,
            eventDocument.createdByUid,
            eventDocument.activeRound,
            null,
            null,
            'swiss-team',
            player1,
            player2,
            'Bye',
          )
          matchData.document.teamSeat = seat
          matches.push(matchData.document)
        })

        __incrementWriteCounter()
        const updateRef = this.afs.collection('events').doc(eventDocId).collection('teams').doc(teamA.id).ref
        batchWrites[batchWriteIndex].update(updateRef, {
          haveByeMatch: true,
          receivedBye: true
        })

      }

    }

    const timestampCreated = firestore.Timestamp.now().seconds
    let tableNumber = eventDocument.details.startingTable
    for await (const match of matches.sort((a, b) => {
      if (a.playerDocIds.includes('*** BYE ***') || a.playerDocIds.includes('*** LOSS ***')) {
        return 1
      }
      else if (b.playerDocIds.includes('*** BYE ***') || b.playerDocIds.includes('*** LOSS ***')) {
        return -1
      }
      else {
        return Math.min(a.player1.seed, a.player2.seed) - Math.min(b.player1.seed, b.player2.seed)
      }
    })) {
      match.timestampCreated = timestampCreated
      match.tableNumber = tableNumber
      tableNumber++
    }

    console.log({
      matches,
      extendedTeams,
    })



    for await (const match of matches) {
      __incrementWriteCounter()
      const updateRef = this.afs.collection('matches').doc(match.docId).ref
      batchWrites[batchWriteIndex].set(updateRef, match)
    }


    // perform updates
    for await (const [index, batch] of batchWrites.entries()) {
      batch
        .commit()
        .then(() => console.log(`...batchWrite #${index + 1} successful`))
        .catch((e) => {
          console.log(e)
          return
        })
    }

    this.eventUpdateStatus(eventDocument, 13)

  }
  public __getExtendedTeams(teams: IEventTeam[], matches: IMatchData[], event: IEventDetails): IEventTeam[] {

    const rounds = Array.from({ length: event.details.structure.swiss.roundsToPlay }, (_, i) => i + 1)
    console.log(rounds)

    const teamsData: IEventTeam[] = []

    for (const team of teams) {

      // create the extended team object
      const teamData = team as IEventTeam
      teamData.avoid = []
      teamData.teamsPlayed = []
      teamData.gamePoints = 0
      teamData.gamesWon = 0
      teamData.gamesLost = 0
      teamData.gamesDrawn = 0
      teamData.gamesPlayed = 0
      teamData.matchPoints = 0
      teamData.matchesWon = 0
      teamData.matchesLost = 0
      teamData.matchesDrawn = 0
      teamData.matchesPlayed = 0
      teamData.selected = false


      for (const round of rounds) {

        // filter out all the matches for the round and the team
        const roundMatches = matches.filter(i => i.teamIds.includes(team.id) && i.roundNumber === round && i.isType !== 'bracket')

        // if no matches, just skip iteration
        if (roundMatches.length === 0) { continue }

        // add the opponent team
        teamData.teamsPlayed.push(roundMatches[0].teamIds.filter(i => i !== team.id)[0])
        teamData.avoid.push(roundMatches[0].teamIds.filter(i => i !== team.id)[0])

        // get the number of wins
        const wins = roundMatches.filter(i => i.player1.teamId === team.id && i.player1.isWinner && !i.isDraw ||
          i.player2.teamId === team.id && i.player2.isWinner && !i.isDraw).length > 2
          ? 2
          : roundMatches.filter(i => i.player1.teamId === team.id && i.player1.isWinner && !i.isDraw ||
            i.player2.teamId === team.id && i.player2.isWinner && !i.isDraw).length

        // get the number of losses
        const losses = roundMatches.filter(i => i.player1.teamId === team.id && !i.player1.isWinner && !i.isDraw ||
          i.player2.teamId === team.id && !i.player2.isWinner && !i.isDraw).length > 2
          ? 2
          : roundMatches.filter(i => i.player1.teamId === team.id && !i.player1.isWinner && !i.isDraw ||
            i.player2.teamId === team.id && !i.player2.isWinner && !i.isDraw).length

        // get the number of draws
        let draws = roundMatches.filter(i => i.isDraw).length

        // update the match stats
        teamData.gamesWon += wins
        teamData.gamesLost += losses
        teamData.gamesDrawn += draws
        teamData.gamesPlayed += (wins + losses + draws)

        // update the game stats
        if (wins > losses) {
          teamData.matchesWon++
        }
        else if (losses > wins) {
          teamData.matchesLost++
        }
        else if (draws > 0 && draws >= wins && draws >= losses) {
          teamData.matchesDrawn++
        }
        else if (draws === wins && draws === losses) {
          teamData.matchesDrawn++
        }

        team.matchesPlayed++

      }

      // update points
      teamData.matchPoints += (teamData.matchesWon * 3) + teamData.matchesDrawn
      teamData.gamePoints += (teamData.gamePoints * 3) + teamData.gamesDrawn


      teamsData.push(teamData)

    }

    return teamsData

  }

  private async __pairBatch(event: IEventDetails, batch: IBatchConfig) {
    // CONFIG
    const maxAtempts = 5;

    // store data locally
    const byStandings = batch.numberOfStandingsMatches;
    const byRandom = batch.numberOfRandomMatches;
    let matches: Array<IMatchData> = [];

    // get all players attending event
    console.log('Getting player list from firestore');
    const playerSnap = await firstValueFrom(
      this.afs
        .collection('events')
        .doc(event.docId)
        .collection('players')
        .get()
    )

    if (playerSnap.empty) {
      // handle empty players list
    }

    const playerList = playerSnap.docs.map(i => i.data())
    console.log(`... all players added to the player list (${playerList.length})`);

    const activePlayers = JSON.parse(JSON.stringify(playerList.filter(i => !i.dropped)))
    console.log(`... all active players added to the active player list (${activePlayers.length})`);
    console.log(activePlayers)

    // pair matches by standings
    console.log('RUN BY STANDINGS PAIRING MODULE FOR ' + byStandings + ' SEGMENTS');
    // fetch the new matches
    const standingsMatches = await this.__pairBatchByStandings(event, batch.roundNumber, byStandings, activePlayers, matches, maxAtempts);
    console.log(':: matches by standings', standingsMatches)
    console.log('==========================================');
    // store the new matches in the matches array
    matches = [...matches, ...standingsMatches]

    // pair random matches
    console.log('RUN BY RANDOM PAIRING MODULE FOR ' + byRandom + ' SEGMENTS');
    // fetch the new matches
    const randomMatches = await this.__pairBatchByRandom(event, batch.roundNumber, byRandom, activePlayers, matches, maxAtempts);
    console.log(':: matches by random', randomMatches)
    console.log('==========================================');
    // store the new matches in the matches array
    matches = [...matches, ...randomMatches]

    return matches


  }
  private async __pairBatchByRandom(
    event: IEventDetails,
    roundNumber: number,
    numSegments: number,
    activePlayers: Array<IEventPlayerDetails>,
    matches: Array<IMatchData>,
    maxAttempts: number
  ) {
    // create array to hold new matches
    let newMatches: Array<IMatchData> = [];

    let attempt = 1;
    let pairingSuccess = false;
    let pairingsFailed = false;
    let ignoreFailures = false;
    while (!pairingSuccess) {
      // start attempts loop
      while (attempt <= maxAttempts) {
        // reset pairing status
        console.log(':: starting pairing attempt ' + attempt + ' of ' + maxAttempts);
        // clear new matches arrau
        newMatches = [];
        // start pairing loop
        for (let segment = 1; segment <= numSegments; segment++) {
          console.log(':: pairing match ' + segment + ' of ' + numSegments);
          // fill player list
          const players: Array<IEventPlayerDetails> = await JSON.parse(JSON.stringify(activePlayers));
          // check if bye is needed
          if (players.length & 1) {
            console.log('Player count is odd. Setting up bye match.');
            // loop from bottom until player without bye receives bye
            for (let i = players.length - 1; i > -1; i--) {
              console.log(':: Checking if ' + players[i].name + ' has bye match in any of the previous batches...');
              if (players[i].haveByeMatch === true) {
                console.log(players[i].name + ' already have bye match...');
                continue;
              }

              console.log(':: Checking if ' + players[i].name + ' has bye match in current batch...');
              if (matches.findIndex(match => match.playerDocIds.includes(players[i].playerDocId) && match.playerDocIds.includes('*** BYE ***')) > -1) {
                console.log(players[i].name + ' already have bye match...');
                continue;
              }

              console.log(':: Checking if ' + players[i].name + ' has bye match in current match generation...');
              if (newMatches.findIndex(match => match.playerDocIds.includes(players[i].playerDocId) && match.playerDocIds.includes('*** BYE ***')) > -1) {
                console.log(players[i].name + ' already have bye match...');
                continue;
              }

              // create round data object for event
              // create round data object for event
              const roundMatchData = new EventMatch(
                event.docId,
                event.createdByUid,
                roundNumber,
                segment,
                0,
                'batch',
                players[i],
                null,
                'Bye'
              )
              // add match to events round data
              console.log('--> BYE Match crated.');
              newMatches.push(roundMatchData.document);
              // remove player from array
              players.splice(i, 1);
              // break loop
              break;
            }
          }
          // pair players
          while (players.length > 0) {
            console.log('==> Players left to pair: ' + players.length);
            console.log(':: Trying to create match for player: ' + players[0].name + ':' + players[0].playerDocId);

            const playerToPair = players[0];

            // get all the players the player have not played already or shall not play in other segments
            console.log(':: Filtering opponents...');
            let opponents = players.filter(p => {
              // self is not an opponent
              if (p.playerDocId === players[0].playerDocId) {
                console.log(':: > removing self');
                return false;
              }
              // remove any players already faced in the event
              else if (playerToPair.opponentsDocIds.indexOf(p.playerDocId) > -1) {
                console.log(':: > removing opponent from earlier batch: ', p.name);
                return false
              }
              // remove any players already paired against in the current batch
              else if (matches.findIndex(match => match.playerDocIds.includes(playerToPair.playerDocId) && match.playerDocIds.includes(p.playerDocId)) > -1) {
                console.log(':: > removing opponent in this batch: ', p.name);
                return false;
              }
              // remove any players already paired against in the current segment generation
              else if (newMatches.findIndex(match => match.playerDocIds.includes(playerToPair.playerDocId) && match.playerDocIds.includes(p.playerDocId)) > -1) {
                console.log(':: > removing opponent from match within this pairings generation : ', p.name);
                return false;
              }
              else {
                return true;
              }
            });

            // shuffle the opponent list
            console.log(':: Found ' + opponents.length + ' suitable for pairings');
            opponents = await this.__shufflePlayers(opponents);
            let opponentIndex = 1;

            // check of their are any opponents
            if (opponents.length === 0) {
              if (!ignoreFailures) {
                console.log('!! ERROR: Can´t find any new opponents for player, failing this attempt');
                pairingsFailed = true;
                break;
              }
              console.log('!! ERROR: Can´t find any new opponents for player, this is last attempt, pair against opponent again');
            }
            else {
              // fetch the opponent index in the players array
              opponentIndex = players.findIndex(p => p.playerDocId === opponents[0].playerDocId);
              console.log(':: SUCCESS: Pairing against ' + players[opponentIndex].name);
            }

            // create round data object for event
            const roundMatchData = new EventMatch(
              event.docId,
              event.createdByUid,
              roundNumber,
              segment,
              0,
              'batch',
              players[0],
              players[opponentIndex],
              null,
            )

            // add match to events round data
            newMatches.push(roundMatchData.document);

            // remove players when paired
            players.splice(opponentIndex, 1);
            players.shift();

          }
          // check for failure
          if (pairingsFailed && !ignoreFailures) {
            break;
          }
        }
        // check if pairings failed
        if (pairingsFailed) {
          // increment attempt
          attempt++;

          if (attempt === maxAttempts) {
            console.log('Last pairing attempt, run without any breaks');
            ignoreFailures = true;
          }
          continue;
        }
        pairingSuccess = true;
        break;
      }
      pairingSuccess = true;
    }

    console.log(':: All pairings done, returning...');
    return newMatches;
  }
  private async __pairBatchByStandings(
    event: IEventDetails,
    roundNumber: number,
    numSegments: number,
    activePlayers: Array<IEventPlayerDetails>,
    matches: Array<IMatchData>,
    maxAttempts: number
  ) {
    // create array to hold new matches
    let newMatches: Array<IMatchData> = [];

    let attempt = 1;
    let pairingSuccess = false;
    let pairingsFailed = false;
    let ignoreFailures = false;
    while (!pairingSuccess) {
      // start attempts loop
      while (attempt <= maxAttempts) {
        // reset pairing status
        console.log(':: starting pairing attempt ' + attempt + ' of ' + maxAttempts);
        // clear new matches arrau
        newMatches = [];
        // start pairing loop
        for (let segment = 1; segment <= numSegments; segment++) {
          console.log(':: pairing match ' + segment + ' of ' + numSegments);
          // fill player list
          const players: Array<IEventPlayerDetails> = await JSON.parse(JSON.stringify(activePlayers));
          // order players by winnings
          players.sort((a: IEventPlayerDetails, b: IEventPlayerDetails) => (a.matchPoints > b.matchPoints) ? -1 : ((b.matchPoints > a.matchPoints) ? 1 : 0));
          // check if bye is needed
          if (players.length & 1) {
            console.log('Player count is odd. Setting up bye match.');
            // loop from bottom until player without bye receives bye
            for (let i = players.length - 1; i > -1; i--) {
              console.log(':: Checking if ' + players[i].name + ' has bye match in any of the previous batches...');
              if (players[i].haveByeMatch === true) {
                console.log(players[i].name + ' already have bye match...');
                continue;
              }

              console.log(':: Checking if ' + players[i].name + ' has bye match in current batch...');
              if (matches.findIndex(match => match.playerDocIds.includes(players[i].playerDocId) && match.playerDocIds.includes('*** BYE ***')) > -1) {
                console.log(players[i].name + ' already have bye match...');
                continue;
              }

              console.log(':: Checking if ' + players[i].name + ' has bye match in current match generation...');
              if (newMatches.findIndex(match => match.playerDocIds.includes(players[i].playerDocId) && match.playerDocIds.includes('*** BYE ***')) > -1) {
                console.log(players[i].name + ' already have bye match...');
                continue;
              }

              // create round data object for event
              // create round data object for event
              const roundMatchData = new EventMatch(
                event.docId,
                event.createdByUid,
                roundNumber,
                segment,
                0,
                'batch',
                players[i],
                null,
                'Bye'
              )

              // add match to events round data
              console.log('--> BYE Match crated.');
              newMatches.push(roundMatchData.document);
              // remove player from array
              players.splice(i, 1);
              // break loop
              break;
            }
          }
          // pair players
          while (players.length > 0) {
            console.log('==> Players left to pair: ' + players.length);
            console.log(':: Trying to create match for player: ' + players[0].name + ':' + players[0].playerDocId);

            const playerToPair = players[0];

            // get all the players the player have not played already or shall not play in other segments
            console.log(':: Filtering opponents...');
            const opponents = players.filter(p => {
              // self is not an opponent
              if (p.playerDocId === players[0].playerDocId) {
                console.log(':: > removing self');
                return false;
              }
              // remove any players already faced in the event
              else if (playerToPair.opponentsDocIds.indexOf(p.playerDocId) > -1) {
                console.log(':: > removing opponent from earlier batch: ', p.name);
                return false
              }
              // remove any players already paired against in the current batch
              else if (matches.findIndex(match => match.playerDocIds.includes(playerToPair.playerDocId) && match.playerDocIds.includes(p.playerDocId)) > -1) {
                console.log(':: > removing opponent in this batch: ', p.name);
                return false;
              }
              // remove any players already paired against in the current segment generation
              else if (newMatches.findIndex(match => match.playerDocIds.includes(playerToPair.playerDocId) && match.playerDocIds.includes(p.playerDocId)) > -1) {
                console.log(':: > removing opponent from match within this pairings generation : ', p.name);
                return false;
              }
              else {
                return true;
              }
            });

            // sort the opponent list
            console.log(':: Found ' + opponents.length + ' suitable for pairings');
            opponents.sort((a: IEventPlayerDetails, b: IEventPlayerDetails) => (a.matchPoints > b.matchPoints) ? -1 : ((b.matchPoints > a.matchPoints) ? 1 : 0));
            let opponentIndex = 1;

            // check of their are any opponents
            if (opponents.length === 0) {
              if (!ignoreFailures) {
                console.log('!! ERROR: Can´t find any new opponents for player, failing this attempt');
                pairingsFailed = true;
                break;
              }
              console.log('!! ERROR: Can´t find any new opponents for player, this is last attempt, pair against opponent again');
            }
            else {
              // fetch the opponent index in the players array
              opponentIndex = players.findIndex(p => p.playerDocId === opponents[0].playerDocId);
              console.log(':: SUCCESS: Pairing against ' + players[opponentIndex].name);
            }

            // create round data object for event
            const roundMatchData = new EventMatch(
              event.docId,
              event.createdByUid,
              roundNumber,
              segment,
              0,
              'batch',
              players[0],
              players[opponentIndex],
              null,
            )

            // add match to events round data
            newMatches.push(roundMatchData.document);

            // remove players when paired
            players.splice(opponentIndex, 1);
            players.shift();

          }
          // check for failure
          if (pairingsFailed && !ignoreFailures) {
            break;
          }
        }
        // check if pairings failed
        if (pairingsFailed && !ignoreFailures) {
          // increment attempt
          attempt++;

          if (attempt === maxAttempts) {
            console.log('Last pairing attempt, run without any breaks');
            ignoreFailures = true;
          }
          continue;
        }
        pairingSuccess = true;
        break;
      }
      pairingSuccess = true;
    }

    console.log(':: All pairings done, returning...');
    return newMatches;
  }
  private async __shufflePlayers(arr: Array<IEventPlayerDetails>) {
    let i,
      j,
      temp;

    for (i = arr.length - 1; i > 0; i--) {
      j = Math.floor(Math.random() * (i + 1));
      temp = arr[i];
      arr[i] = arr[j];
      arr[j] = temp;
    }

    return arr;
  }


}
function arrayUnion(playerDocId: string): any {
  throw new Error('Function not implemented.');
}

