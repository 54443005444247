import { CommonModule } from '@angular/common';
import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { PlayerDisplayNameComponent } from 'src/app/components';
import { PlayerAvatarComponent } from 'src/app/components/players/player-avatar/player-avatar.component';
import { IEventPlayerDetails, IEventTeam } from 'tolaria-cloud-functions/src/_interfaces';
import { TournamentManagementPlayersService } from 'src/app/private/play/tournament/services/tournament-management-players.service';
import { IEventTeamMeta, TournamentDataHelperService } from 'src/app/private/play/tournament/services/tournament-data-helper.service';
import { BehaviorSubject, Subject, takeUntil } from 'rxjs';
import { FormsModule } from '@angular/forms';

@Component({
  selector: 'app-tournament-management-player-team-config',
  templateUrl: './tournament-management-player-team-config.component.html',
  styleUrls: ['./tournament-management-player-team-config.component.css'],
  standalone: true,
  imports: [
    CommonModule,
    FormsModule,
    PlayerDisplayNameComponent,
    PlayerAvatarComponent,
  ]
})
export class TournamentManagementPlayerTeamConfigComponent implements OnInit, OnDestroy {
  @Input() player!: IEventPlayerDetails

  private destroyed$ = new Subject<boolean>()

  public selectedTeam: IEventTeam = null

  public selectedTeam$ = new BehaviorSubject<IEventTeamMeta>(null)
  public playerNames = {
    a: '',
    b: '',
    c: '',
  }

  constructor(
    private readonly modal: NgbActiveModal,
    private readonly eventPlayer: TournamentManagementPlayersService,
    private readonly helper: TournamentDataHelperService,
  ) { }

  ngOnInit() {
    this.selectedTeam$.pipe(takeUntil(this.destroyed$)).subscribe(team => {
      if (team !== null) {
        this.updatePlayerNames(team)
      }
    })
  }

  ngOnDestroy(): void {
    this.destroyed$.next(true)
  }

  private updatePlayerNames(team: IEventTeam): void {
    const players = this.helper.players$.getValue()
    if (team.player.a !== null && team.player.a !== '') {
      this.playerNames.a = players.find(i => i.eventPlayer.playerDocId === team.player.a).eventPlayer.name
    }
    if (team.player.b !== null && team.player.b !== '') {
      this.playerNames.b = players.find(i => i.eventPlayer.playerDocId === team.player.b).eventPlayer.name
    }
    if (team.player.c !== null && team.player.c !== '') {
      this.playerNames.c = players.find(i => i.eventPlayer.playerDocId === team.player.c).eventPlayer.name
    }
  }

  public onTeamSelectionChange(team: IEventTeam): void {
    // update the local selected team with raw copy
    this.selectedTeam$.next(JSON.parse(JSON.stringify(team)))
  }

  public onClose(): void {
    // get the selected team
    const team = this.selectedTeam$.getValue()

    // make sure a team is selected
    if (team !== null) {

      // make sure the player is seated
      let seated = false
      for (const [seat, id] of Object.entries(team.player)) {
        if (team.player[seat] === this.player.playerDocId) {
          seated = true
        }
      }

      if (seated) {

        // add the player to the team array of player document ids
        team.playerDocIds.push(this.player.playerDocId)

        // close the dialog
        this.modal.close({ team: team, eventDocId: this.helper.docId })

      }

    }

  }

  public onDismiss(): void {
    this.modal.dismiss()
  }

  public onNewTeamPressed(): void {
    this.eventPlayer.createNewTeam()
  }

  public onTeamSelected(team: IEventTeamMeta): void {
    const teams = this.helper.teams$.getValue()
    teams.forEach(i => i.selected = i.id === team.id)
    this.helper.teams$.next(teams)
    // update the local selected team with raw copy
    this.selectedTeam$.next(JSON.parse(JSON.stringify(team)))
  }

  public onSeatSelected(seat: 'a' | 'b' | 'c'): void {
    const team = this.selectedTeam$.getValue()
    if (team.player[seat] !== null && team.player[seat] !== this.player.playerDocId) {
      return
    }
    for (const [seat, id] of Object.entries(team.player)) {
      if (team.player[seat] === this.player.playerDocId) {
        team.player[seat] = null
      }
    }
    team.player[seat] = this.player.playerDocId
    this.updatePlayerNames(team)
  }

  public get teams$(): BehaviorSubject<IEventTeamMeta[]> {
    return this.helper.teams$
  }

  public get disabled(): boolean {
    if (this.selectedTeam$.getValue() !== null) {
      const team = this.selectedTeam$.getValue()
      let notSeated = true
      for (const [seat, id] of Object.entries(team.player)) {
        if (team.player[seat] === this.player.playerDocId) {
          notSeated = false
        }
      }
      return notSeated
    }
    return true
  }

  

}
