import { Injectable } from '@angular/core';
import { UserService } from '../private/_shared/services/user.service';
import { PlayerNameService } from './players/player-name.service';
import { BehaviorSubject, combineLatest } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class ContentReadyService {

  public userDocumentReady$ = new BehaviorSubject(false)
  public playerDocumentReady$ = new BehaviorSubject(false)
  public playerMinisReady$ = new BehaviorSubject(false)
  public contentReady$ = new BehaviorSubject(false)
  public eventFilterReady$ = new BehaviorSubject(false)

  constructor(
    private readonly user: UserService,
    private readonly players: PlayerNameService,
  ) {

    this.user.userDocument$.subscribe(i => {
      // console.log('[ContentReadyService] --> user document emitted a new value', i)
      if (this.userDocumentReady$.getValue() === false) {
        this.userDocumentReady$.next(i !== null)
      }
    })

    this.user.playerDocument$.subscribe(i => {
      // console.log('[ContentReadyService] --> player document emitted a new value', i)
      if (this.playerDocumentReady$.getValue() === false) {
        this.playerDocumentReady$.next(i !== null)
      }
    })

    this.players.serviceReady$.subscribe(i => {
      // console.log('[ContentReadyService] --> player name service ready state emitted a new value', i)
      if (this.playerMinisReady$.getValue() === false) {
        this.playerMinisReady$.next(i)
      }
    })

    this.user.eventFilter$.subscribe(i => {
      // console.log('[ContentReadyService] --> player name service ready state emitted a new value', i)
      if (this.eventFilterReady$.getValue() === false) {
        this.eventFilterReady$.next(i !== null)
      }
    })

    combineLatest([
      this.userDocumentReady$,
      this.playerDocumentReady$,
      this.playerMinisReady$,
      this.eventFilterReady$,
    ]).subscribe(([user, player, minis, eventFilters]) => {
      console.log('[ContentReadyService] --> state emitted new value', {
        'userDocumentReady$': user,
        'playerDocumentReady$': player,
        'playerMinisReady$': minis,
        'eventFilters$': eventFilters,
      })
      this.contentReady$.next(user && player && minis)
    })

  }

  public getReadyState(): boolean {
    return this.contentReady$.getValue()
  }

}
